import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Snackbar, Slide, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import ActionsCreator from '~redux/actions';
import { store, useDispatch } from '~redux/store';
import useStyles from './styles';
import { TransitionProps } from '@mui/material/transitions';
import { FormData, Options, TravelFormData } from '~/utility/models';
import TransportSelector from '../TransportSelector';
import FormContainer from '~/containers/FormContainer';
import { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { RootState } from '~/redux/reducers';
import { initialFormData } from './constants';
import {
  handleMouseEnter,
  handleMouseLeave,
  updateTravelPointsArray,
} from './helpers';
import {
  deleteFileByUrlFromStorage,
  isFileGreaterThan100MB,
  uploadFileToStorage,
  validateFileMimetype,
} from '~/utility/utils';
import { validImageTypes } from '~/containers/ImageContainer/constants';

/**
 * Represents the props for the TravelForm component.
 * @interface TravelFormProps
 * @property {string} alertText - The text for the alert message.
 * @property {string} formName - The name of the form.
 * @property {React.MouseEventHandler<HTMLImageElement>} handleClose - Function to handle closing the form.
 * @property {boolean} state - State indicating whether the form is open or closed.
 */

/**
 * Component for adding a new travel.
 * @param {TravelFormProps} props - The props object.
 * @returns {JSX.Element} The TravelForm component.
 */
const TravelForm = (props: {
  alertText: string;
  formName: string;
  handleClose: React.MouseEventHandler<HTMLImageElement> | undefined;
  state: boolean;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 600;

  /**-------------------STATES--------------------*/

  const [travelPoints, setTravelPoints] = useState<TravelFormData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isCarSelected, setIsCarSelected] = useState(false);
  const [isPlaneSelected, setIsPlaneSelected] = useState(false);
  const [isTransitSelected, setIsTransitSelected] = useState(false);
  const [isWalkSelected, setIsWalkSelected] = useState(false);
  const [isFerrySelected, setIsFerrySelected] = useState(false);
  const [isCarHovered, setIsCarHovered] = useState(false);
  const [isPlaneHovered, setIsPlaneHovered] = useState(false);
  const [isTransitHovered, setIsTransitHovered] = useState(false);
  const [isWalkHovered, setIsWalkHovered] = useState(false);
  const [isFerryHovered, setIsFerryHovered] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedTransport, setSelectedTransport] = useState<TravelMode | null>(
    null,
  );
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs>(); //THIS STATE IS USED IN DISABLED TRAVEL FORM.

  const [departureFormData, setDepartureFormData] =
    useState<FormData>(initialFormData);
  const [arrivalFormData, setArrivalFormData] =
    useState<FormData>(initialFormData);

  const [selectedTransportImages, setSelectedTransportImages] = useState<
    string[]
  >([]); // holds the images uploaded for the selected transport mode

  const [transportFileuploadLoader, setTransportFileuploadLoader] =
    useState<boolean>(false); // holds the loading state for the file being upload in the image container

  /**-------------------REDUX STATES--------------------*/

  /**
   * Represents the state of the snackbar being open.
   * @type {boolean}
   */
  const snackbarOpen = useSelector(
    (state: RootState) => state.MapReducers.snackbarOpen,
  );

  /**
   * Represents the success message displayed in the snackbar.
   * @type {string}
   */
  const successMessage = useSelector(
    (state: RootState) => state.MapReducers.successMessage,
  );

  /**
   * Represents the state of the success snackbar being open.
   * @type {boolean}
   */
  const successSnackbarOpen: boolean = useSelector(
    (state: RootState) => state.MapReducers.successSnackbarOpen,
  );

  /**
   * Represents the state of loading.
   * @type {boolean}
   */
  const loading: boolean = useSelector(
    (state: RootState) => state.MapReducers.loading,
  );

  /**
   * Represents the array of travel form data points.
   * @type {TravelFormData[]}
   */
  const state: TravelFormData[] = useSelector(
    (state: RootState) => state.MapReducers.pointsArray,
  );

  /**This basically stores the values of the form coming from the redux on the rerender.*/
  const travelObj = useRef<TravelFormData[] | null>(state);

  /**-----------------------------USE EFFECTS------------------------------.*/

  /**
   * Effect hook that resets form data and transport mode states when the `state` variable (points array) changes.
   * @param {Array<TravelFormData>} state - The array of travel points.
   */
  useEffect(() => {
    if (state.length === 0) {
      // Reset form data and transport mode states
      setDepartureFormData(initialFormData);
      setArrivalFormData(initialFormData);
      setSelectedTransport(null);
      setTravelModeSelected(null);
      setIsFerrySelected(false);
      setSelectedTransportImages([]);
      setTransportFileuploadLoader(false);
    }
  }, [state]);

  /**
   * Effect hook that updates form data and disables form submission when the `isDisabled` state changes.
   * @param {boolean} isDisabled - The state indicating whether form submission is disabled.
   */
  useEffect(() => {
    if ((travelObj.current as TravelFormData[]).length > 0) {
      // Update form data and disable form submission
      setTravelPoints(state);
      const lastPointIndex = state.length - 1;
      setDepartureFormData(state[lastPointIndex].arrival);
      setArrivalFormData(initialFormData);
      setIsDisabled(true);
    }
  }, [isDisabled]);

  /**-----------------------------FUNCTIONS------------------------------.*/

  /**
   * @function handleTransportFileChange
   * An asynchronous function that handles changes in the transport file form fields.
   * Filters and uploads selected images to a storage bucket.
   * @param {keyof FormData} field - The field in the form data to update.
   * @param {string | null | number | Date | Options | Dayjs | File[] | string[] } value - The new value for the field.
   * @returns {void}
   */
  const handleTransportFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Convert selected files into an array
    const files = Array.from(event.target.files || []);

    // Proceed if there are selected files
    if (files.length > 0) {
      // Filter files by allowed image types
      const filteredFiles = files.filter((file) => {
        // check for invalid file
        if (!validateFileMimetype({ file, validFileTypes: validImageTypes })) {
          setErrorMessage(
            'Invalid file type, allowed file types are jpeg and png.',
          );
          // Dispatch action to show the Snackbar
          dispatch(ActionsCreator.setSnackbarOpen(true));
          return false;
        }

        // check for file size (max is 100MB)
        if (isFileGreaterThan100MB(file)) {
          setErrorMessage('File size is too large, the max is 100MB');
          // Dispatch action to show the Snackbar
          dispatch(ActionsCreator.setSnackbarOpen(true));
          return false;
        }

        return true;
      });

      // Proceed if there are valid files to upload
      if (filteredFiles.length > 0) {
        try {
          // Calculate maximum number of files to add based on existing selection
          const maxFilesToAdd = 4 - selectedTransportImages.length;

          // Limit files to be added to maximum allowed
          const filesToAdd = files.slice(0, maxFilesToAdd);

          // Prepare promises to upload each selected file
          const uploadPromises = filesToAdd.map((file) =>
            uploadFileToStorage({
              bucketName: 'travel-images',
              userId: store.getState().MapReducers.userID, // Retrieve user ID from Redux store
              file: file,
            }),
          );

          // Show loader indicating file uploads are in progress
          setTransportFileuploadLoader(true);

          // Wait for all uploads to complete
          const uploadResults = await Promise.all(uploadPromises);

          // Process each upload result
          uploadResults.forEach((result) => {
            if (result.success && result.fileUrl) {
              // Update selected images with uploaded image URLs
              setSelectedTransportImages((prevData) =>
                [...prevData, result.fileUrl.data.publicUrl].slice(0, 4),
              );
            } else {
              console.error('Failed to upload file:', result.error);
              setErrorMessage(
                'File upload to storage failed, please try again.',
              );
              // Dispatch action to show the Snackbar
              dispatch(ActionsCreator.setSnackbarOpen(true));
            }
          });
        } catch (error) {
          console.error('Error uploading files:', error);
          setErrorMessage(
            'An error occured while uploading your file, please try again.',
          );
          // Dispatch action to show the Snackbar
          dispatch(ActionsCreator.setSnackbarOpen(true));
        } finally {
          // Hide loader after upload completes or errors out
          setTransportFileuploadLoader(false);
        }
      }
    }
  };

  /**
   * Handles the removal of a selected transport image by its index.
   * Removes the image from the selectedTransportImages state.
   * Remove from supabase storage
   * @param {number} index - The index of the image to be removed.
   */
  const handleTransportFileRemoval = async (index: number) => {
    // Filter out the image at the specified index
    const imagesLeft = selectedTransportImages.filter((_, i) => i !== index);

    // Update the state with the remaining images
    setSelectedTransportImages(imagesLeft);

    // Remove from storage
    // This is not awaited
    deleteFileByUrlFromStorage({
      bucketName: 'travel-images',
      userId: store.getState().MapReducers.userID, // Retrieve user ID from Redux store
      fileUrl: selectedTransportImages[index],
    });
  };

  function setTravelModeSelected(selectedTransport: TravelMode | null) {
    switch (selectedTransport) {
      case TravelMode.Plane:
        setIsPlaneSelected(true);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        setIsTransitSelected(false);
        setIsCarSelected(false);
        break;

      case TravelMode.Car:
        setIsCarSelected(true);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        setIsTransitSelected(false);
        setIsPlaneSelected(false);
        break;

      case TravelMode.Transit:
        setIsTransitSelected(true);
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        setIsPlaneSelected(false);
        setIsCarSelected(false);
        break;

      case TravelMode.Ferry:
        setIsFerrySelected(true);
        setIsWalkSelected(false);
        setIsTransitSelected(false);
        setIsPlaneSelected(false);
        setIsCarSelected(false);
        break;

      case TravelMode.Walk:
        setIsWalkSelected(true);
        setIsFerrySelected(false);
        setIsTransitSelected(false);
        setIsPlaneSelected(false);
        setIsCarSelected(false);
        break;

      default:
        setIsFerrySelected(false);
        setIsWalkSelected(false);
        setIsTransitSelected(false);
        setIsPlaneSelected(false);
        setIsCarSelected(false);
        break;
    }
  }

  /**
   * @function handleArrivalFormChange
   * Handles changes in the arrival form fields.
   * @param {keyof FormData} field - The field in the form data to update.
   * @param {string | null | number | Date | Options | Dayjs | File[ ] } value - The new value for the field.
   * @returns {void}
   */
  const handleArrivalFormChange = (
    field: keyof FormData,
    value:
      | string
      | null
      | number
      | Date
      | Options
      | Dayjs
      | boolean
      | File[]
      | string[],
  ) => {
    setArrivalFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  /**
   * @function handleDepartureFormChange
   * Handles changes in the departure form fields.
   * @param {keyof FormData} field - The field in the form data to update.
   * @param {string | null | number | Date | Options | Dayjs | File[]  | string[]} value - The new value for the field.
   * @returns {void}
   */
  const handleDepartureFormChange = (
    field: keyof FormData,
    value:
      | string
      | null
      | number
      | Date
      | Options
      | Dayjs
      | boolean
      | File[]
      | string[],
  ) => {
    setDepartureFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  /**
   * @function handleTransportChange
   * Handles changes in the selected transport type.
   * @param {TravelMode} transportType - The selected transport mode.
   * @returns {void}
   */
  const handleTransportChange = (transportType: TravelMode) => {
    setIsPlaneSelected(transportType === TravelMode.Plane);
    setIsCarSelected(transportType === TravelMode.Car);
    setIsTransitSelected(transportType === TravelMode.Transit);
    setIsWalkSelected(transportType === TravelMode.Walk);
    setIsFerrySelected(transportType === TravelMode.Ferry);
    setSelectedTransport(transportType);
  };

  /**
   * @function handleDoneButtonClick
   * Handles the action when the "Done" button is clicked. It updates the travel points array based on the form data
   * and selected transport, and then dispatches an action to save or add the updated travel points to the Redux store.
   * @returns {Promise<void>} A promise that resolves after updating the travel points array.
   */
  const handleDoneButtonClick = async (): Promise<void> => {
    console.log({
      departureFormData,
      arrivalFormData,
      selectedTransport,
      travelPoints,
      travelObj,
    });
    // Call the helper function to update the travel points array
    await updateTravelPointsArray(
      departureFormData, // The form data for departure
      arrivalFormData, // The form data for arrival
      selectedTransport, // The selected transport mode
      travelPoints, // The current travel points array
      setTravelPoints, // The setter function to update the travel points array
      travelObj as React.MutableRefObject<TravelFormData[]>, // A reference to the mutable travel points array
      dispatch, // The Redux dispatch function
      true, // Indicates whether to save the updated travel points
      setErrorMessage, // The setter function to update error message state
      selectedTransportImages, // The images uploaded for a transport method
    );
  };

  /**
   * @function handleAddAnotherPointClick
   * Handles the click event when the user wants to add another travel point.
   * This function updates the travel points array and resets form states if the update is successful.
   * @returns {Promise<void>} A promise representing the asynchronous operation.
   */
  const handleAddAnotherPointClick = async (): Promise<void> => {
    // Check if the form is valid and update the travel points array
    let formValidState = await updateTravelPointsArray(
      departureFormData, // The form data for departure
      arrivalFormData, // The form data for arrival
      selectedTransport, // The selected transport mode
      travelPoints, // The current travel points array
      setTravelPoints, // The setter function to update the travel points array
      travelObj as React.MutableRefObject<TravelFormData[]>, // A reference to the mutable travel points array
      dispatch, // The Redux dispatch function
      false, // Indicates whether to save the updated travel points
      setErrorMessage, // The setter function to update error message state
      selectedTransportImages, // The images uploaded for a transport method
    );

    // Check if the API call was successful and reset states if needed
    if (formValidState && travelObj.current) {
      const lastPointIndex = travelObj.current.length - 1;
      setTravelModeSelected(null);
      setSelectedTransport(null);
      setArrivalFormData(initialFormData);
      setDepartureFormData(travelObj.current[lastPointIndex].arrival);
      setIsDisabled(true);
      setSelectedDateTime(undefined);
      setSelectedTransportImages([]);
    } else {
      return; // Exit the function if the form is not valid or the API call fails
    }
  };

  /**
   * @function handleOnGoBackMethod
   * Handles the go back action when the user wants to revert to the previous travel point.
   * This function updates form states and travel points array to the previous point's data.
   */
  const handleOnGoBackMethod = (): void => {
    if (travelObj.current) {
      // Get the index of the last travel point
      const lastPointIndex = travelObj.current.length - 1;

      // Update form states with the data of the previous point
      setDepartureFormData(travelObj.current[lastPointIndex].departure);
      setArrivalFormData(travelObj.current[lastPointIndex].arrival);
      setSelectedTransport(travelObj.current[lastPointIndex].selectedTransport);

      // Set the appropriate transport mode flags
      setTravelModeSelected(
        travelObj.current[lastPointIndex].selectedTransport,
      );

      // Remove the last travel point from the travel points array
      const updatedTravelPoints = [...travelPoints.slice(0, -1)];
      setTravelPoints(updatedTravelPoints as TravelFormData[]);
      travelObj.current = updatedTravelPoints;

      // Enable form submission if there are no travel points left
      if (Array.isArray(travelObj.current)) {
        if (travelObj.current.length === 0) {
          setIsDisabled(false);
        }
      }
    }
    setSelectedDateTime(undefined);
  };
  //These constants are important to be defined here because they are dependant on the above functions and cant be taken to the constant.ts
  const buttonConfigurations = [
    {
      label: 'Add another Point',
      class: classes.addPointButton,
      loading: false,
      onClick: handleAddAnotherPointClick,
    },
    {
      label: 'Save',
      loading: loading,
      class: classes.submitButton,
      onClick: handleDoneButtonClick,
    },
  ];

  useEffect(() => {
    return () => {
      travelObj.current = null;
    };
  }, []);

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => dispatch(ActionsCreator.setSnackbarOpen(false))} // Close the Snackbar on action
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar at bottom left
        TransitionComponent={Slide} // Use the Slide component for the transition
        TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => dispatch(ActionsCreator.setSnackbarOpen(false))}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => dispatch(ActionsCreator.setSuccessSnackbarOpen(false))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'right' } as TransitionProps}
      >
        <Alert
          variant="filled"
          severity="success"
          onClose={() => dispatch(ActionsCreator.setSuccessSnackbarOpen(false))}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Typography variant="h5" textAlign={'center'} className={classes.title}>
        Add a New Travel
        <img
          src="/icons/cross.svg"
          alt="cancel"
          className={classes.cancelIcon}
          onClick={props.handleClose}
        />
      </Typography>
      <Grid
        // item
        container
        // spacing={1}
        marginTop={2}
        className={classes.formContainer}
      >
        <Grid item xs={12} md={6}>
          <FormContainer
            type={'departure'}
            formData={departureFormData}
            oppositeformData={arrivalFormData}
            onFormChange={handleDepartureFormChange}
            isDisabled={isDisabled} // Pass the isDisabled prop to indicate it's for departure and should not be disabled
            alertText={props.alertText}
            setSelectedDateTime={setSelectedDateTime}
            selectedDateTime={selectedDateTime}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormContainer
            type={'arrival'}
            formData={arrivalFormData}
            oppositeformData={departureFormData}
            onFormChange={handleArrivalFormChange}
            isDisabled={false}
            alertText={''}
            setSelectedDateTime={setSelectedDateTime}
            selectedDateTime={selectedDateTime}
          />
        </Grid>

        {isMobile ? (
          // Mobile mode of transport selector
          <TransportSelector
            onTransportChange={(transportType) =>
              handleTransportChange(transportType)
            }
            selectedTransport={selectedTransport}
            images={selectedTransportImages}
            isLoading={transportFileuploadLoader}
            onFileChange={handleTransportFileChange}
            handleRemoveImage={handleTransportFileRemoval}
            isPlaneSelected={isPlaneSelected}
            isCarSelected={isCarSelected}
            isTransitSelected={isTransitSelected}
            isWalkSelected={isWalkSelected}
            isFerrySelected={isFerrySelected}
            isPlaneHovered={isPlaneHovered}
            isCarHovered={isCarHovered}
            isTransitHovered={isTransitHovered}
            isWalkHovered={isWalkHovered}
            isFerryHovered={isFerryHovered}
            onMouseEnter={(transportType) =>
              handleMouseEnter(
                transportType,
                setIsPlaneHovered,
                setIsCarHovered,
                setIsTransitHovered,
                setIsWalkHovered,
                setIsFerryHovered,
              )
            }
            onMouseLeave={(transportType) =>
              handleMouseLeave(
                transportType,
                setIsPlaneHovered,
                setIsCarHovered,
                setIsTransitHovered,
                setIsWalkHovered,
                setIsFerryHovered,
              )
            }
          />
        ) : (
          // Desktop mode of transport selector
          <TransportSelector
            onTransportChange={(transportType) =>
              handleTransportChange(transportType)
            }
            selectedTransport={selectedTransport}
            images={selectedTransportImages}
            isLoading={transportFileuploadLoader}
            onFileChange={handleTransportFileChange}
            handleRemoveImage={handleTransportFileRemoval}
            isPlaneSelected={isPlaneSelected}
            isCarSelected={isCarSelected}
            isTransitSelected={isTransitSelected}
            isWalkSelected={isWalkSelected}
            isFerrySelected={isFerrySelected}
            isPlaneHovered={isPlaneHovered}
            isCarHovered={isCarHovered}
            isTransitHovered={isTransitHovered}
            isWalkHovered={isWalkHovered}
            isFerryHovered={isFerryHovered}
            onMouseEnter={(transportType) =>
              handleMouseEnter(
                transportType,
                setIsPlaneHovered,
                setIsCarHovered,
                setIsTransitHovered,
                setIsWalkHovered,
                setIsFerryHovered,
              )
            }
            onMouseLeave={(transportType) =>
              handleMouseLeave(
                transportType,
                setIsPlaneHovered,
                setIsCarHovered,
                setIsTransitHovered,
                setIsWalkHovered,
                setIsFerryHovered,
              )
            }
          />
        )}
      </Grid>

      {/* This grid is for footer buttons */}
      <Grid
        item
        container
        spacing={2}
        textAlign={'center'}
        marginTop={'1px'}
        className={classes.footerButtons}
      >
        <Grid item xs={12} md={4} className={classes.goBackContainer}>
          {isDisabled && (
            <Button
              disabled={false}
              size="large"
              variant="contained"
              className={classes.backButton}
              sx={{
                fontFamily: 'Futura Bold Italic',
              }}
              onClick={handleOnGoBackMethod}
            >
              Go Back
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={8} className={classes.submitContainer}>
          {buttonConfigurations.map((config, index) => (
            <LoadingButton
              key={index}
              loading={config.loading}
              disabled={false}
              size="large"
              variant="contained"
              className={config.class}
              sx={{
                fontFamily: 'Futura Bold Italic',
              }}
              onClick={config.onClick}
            >
              {config.label}
            </LoadingButton>
          ))}
        </Grid>
      </Grid>
      <br></br>
    </div>
  );
};

export default TravelForm;
