import React from 'react';
import Navbar from '../NavbarNew';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import './style.css';
import History from './History';
import { useNavigate } from 'react-router-dom';
import ROUTES from '~/routes';
import { TravelFormHistoryData } from '~/utility/models';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import FeedbackPopup from '../FeedbackPopup';

const Home = () => {
  const userName: string = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const travelHistoryState: TravelFormHistoryData[] = useSelector(
    (state: RootState) => state.MapReducers.travelHistoryState,
  );

  return (
    <div
      style={{
        background: 'white',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flex: 1,
      }}
    >
      <FeedbackPopup />
      <Navbar />
      <div
        style={{
          display: 'flex',
          // justifyContent: 'space-around',
          marginTop: '20px',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span className="home-title">Hi {userName} !</span>
          <div
            className={isMobile ? 'mobile-home-button' : 'home-button'}
            onClick={() => {
              navigate('/p2p-ai');
            }}
          >
            Generate a New Trip
          </div>
          <div className={isMobile ? 'mobile-trip-header' : 'trip-header'}>
            <span className="home-title">My Trips</span>
          </div>
          <History />
        </div>

        {travelHistoryState.length > 0 && (
          <div
            className="home-button home-button-secondary"
            onClick={() => {
              navigate(ROUTES.HOMEPAGE.path);
            }}
          >
            Create Manually
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
