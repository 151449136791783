import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentDayData,
  selectTravelData,
  selectTravelDays,
} from '~/redux/selectors/tripInfoSelectors';
import ActionsCreator from '~/redux/actions';
import DaysPoints from './DaysPoints';
import moment from 'moment-timezone';
import { useState } from 'react';
import { getDurationString, getTransportIcon } from './helper';
import './style.css';

export const ItineraryOverview = () => {
  const travelDaysObj = useSelector(selectTravelDays);
  const travelData = useSelector(selectTravelData);
  const travelDays = Object.values(travelDaysObj);
  const initialDay = moment(String(travelData[0].departure.dateTime)).tz(
    travelData[0].departure.timezone,
  );
  const [currentDay, setCurrentDay] = useState(initialDay);
  const dispatch = useDispatch();

  const selectedDayData = useSelector(
    selectCurrentDayData(currentDay.format('DD-MM-YYYY')),
  );

  return (
    <div className="itinerary-content">
      <div className="itinerary-container">
        <DaysPoints
          travelData={travelData}
          initialDay={initialDay}
          onDayPress={(day) => setCurrentDay(day)}
        />
        <div className="itinerary-current-day">
          <span>{currentDay.format('dddd, MMMM D, YYYY')}</span>
          <span>
            Day {travelDaysObj[currentDay.format('DD-MM-YYYY')]}/
            {travelDays.length}{' '}
          </span>
        </div>
        {selectedDayData &&
          selectedDayData.map((day, index, days) => (
            <div
              key={day.travelIndex}
              className="itinerary-day-wrapper"
              onClick={() =>
                dispatch(ActionsCreator.setCurrentOverviewData(day))
              }
            >
              <div className="itinerary-day-item">
                <div style={{ marginRight: '10px' }}>
                  <img src={day.icon} alt="icon" height={43} />
                </div>
                <div className="itinerary-day-info">
                  <h5 className="itinerary-day-info--name">{day.name}</h5>
                  <div className="itinerary-day-info--time-cost">
                    <div className="itinerary-day-info--time">
                      {day.nextPoint && !day.prevPoint && (
                        <span>
                          {moment(String(day.departureDateTime || ''))
                            .tz(day.departureTimeZone || '')
                            .format('hh:mm A')}
                        </span>
                      )}
                      {day.nextPoint && day.prevPoint && (
                        <div className="dayTime-item">
                          <span>
                            {moment(String(day.arrivalDateTime || ''))
                              .tz(day.arrivalTimeZone || '')
                              .format('hh:mm A')}{' '}
                            -{' '}
                          </span>
                          <span>
                            {moment(String(day.departureDateTime || ''))
                              .tz(day.departureTimeZone || '')
                              .format('hh:mm A')}
                          </span>
                        </div>
                      )}

                      {!day.nextPoint && day.prevPoint && (
                        <span>
                          {moment(String(day.arrivalDateTime || ''))
                            .tz(day.arrivalTimeZone || '')
                            .format('hh:mm A')}{' '}
                        </span>
                      )}
                    </div>
                    {!!day.cost && (
                      <div className="itinerary-day-info--cost">
                        ~US${day.cost}
                      </div>
                    )}
                  </div>
                </div>
                <div className="custom-badge active">View</div>
              </div>

              {/* GO TO */}
              {index !== selectedDayData.length - 1 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div className="trip-duration-badge">
                    <img
                      style={{
                        height: '20px',
                        width: '20px',
                      }}
                      src={getTransportIcon(day.type)}
                      alt="distance"
                    />
                    <p
                      style={{
                        position: 'relative',
                        fontSize: '12px',
                        fontWeight: 600,
                        width: '100%',
                        margin: 0,
                      }}
                    >
                      {getDurationString({
                        departure: day.departureDateTime as string,
                        arrival: days[index + 1].arrivalDateTime as string,
                      })}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
