import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { PublishableTravelData } from '~/utility/models';
import moment from 'moment-timezone';
import { uniqBy } from 'lodash';
import { motion, AnimatePresence, easeInOut } from 'framer-motion';
import { isShareModalVisible } from '../StatsOverlay';
import ModalComponent from '~/components/ModalComponent';
import { useIsMobile } from '../counter/hooks/useMobile';
import { handleShare } from '~/utility/shareLink';
import './style.css';
import {
  selectTravelCities,
  selectTravelRawData,
} from '~/redux/selectors/tripInfoSelectors';
import { LocationCity } from './LocationCity';

const MobileDiscoverModal = ({
  travelPoints,
  hideModal,
}: {
  travelPoints: PublishableTravelData[];
  hideModal: () => void;
}) => {
  const userName = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const userAvatar = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );
  const cities = useSelector(selectTravelCities);
  const [errors, setErrors] = useState<number[]>([]);
  const isMobile = useIsMobile();
  const tripRawData = useSelector(selectTravelRawData);

  const discoverBtnRef = useRef(null);
  const shareBtnRef = useRef(null);

  useClickAway(
    discoverBtnRef,
    (e: Event) =>
      !(e.target as HTMLElement).closest('.mobile-discover-modal-share') &&
      hideModal(),
  );

  useClickAway(
    shareBtnRef,
    (e: Event) =>
      !(e.target as HTMLElement).closest('.mobile-discover-modal-button') &&
      hideModal(),
  );

  const images = travelPoints
    .map((point, index) => {
      if (index === travelPoints.length - 1) {
        return [...point.departure.images, ...point.arrival.images];
      }
      return point.departure.images;
    })
    .flat()
    .slice(0, 5);

  const days = uniqBy(
    travelPoints.map((i) =>
      moment(String(i.departure.dateTime)).tz(i.departure.timezone),
    ),
    (i) => i.format('DD-MM-YYYY'),
  );

  const offsets = [
    { left: '5%', top: '5%' },
    { left: '50%', top: '5%' },
    { left: '5%', top: '40%' },
    { left: '50%', top: '40%' },
    { left: '30%', top: '30%' },
  ];

  const tripTitle =
    tripRawData.title ||
    `${days.length} Day${days.length > 1 ? 's' : ''} in ${
      travelPoints[5]?.arrival.location?.city ||
      travelPoints[0]?.arrival.location?.city ||
      travelPoints[0]?.arrival.location?.country
    }`;

  return (
    <>
      <div className="mobile-discover-modal-user-label-container">
        <img src={userAvatar} alt="avatar" />
        <span>{`Made by ${userName}`}</span>
      </div>
      <ModalComponent>
        <>
          <span className="mobile-discover-modal-header-text">{tripTitle}</span>
          {images.length > 0 && images.length !== errors.length && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 265,
                marginBottom: 20,
              }}
            >
              {images.map((src, idx) => {
                let thumbContent = <></>;
                const ext = src.split('.').pop()?.toLocaleLowerCase();
                if (ext === 'mp4') {
                  thumbContent = (
                    <video
                      style={
                        {
                          borderRadius: 24,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        } as React.CSSProperties
                      }
                    >
                      <source src={src} />
                    </video>
                  );
                } else {
                  thumbContent = (
                    <img
                      src={src}
                      alt={`img ${idx}`}
                      width={140}
                      height={140}
                      style={
                        {
                          borderRadius: 24,
                          width: '140px',
                          height: '140px',
                        } as React.CSSProperties
                      }
                    />
                  );
                }

                if (errors.includes(idx)) return null;
                return (
                  <div
                    key={idx}
                    style={
                      {
                        '--randomRotate': `${
                          (idx % 2 === 0 ? -1 : 1) * Math.random() * 15
                        }deg`,
                        borderRadius: 24,
                        position: 'absolute',
                        left: offsets[idx].left,
                        top: offsets[idx].top,
                        width: '140px',
                        height: '140px',
                      } as React.CSSProperties
                    }
                    onError={() => setErrors((v) => [...v, idx])}
                    className="discover-modal-image"
                  >
                    {thumbContent}
                  </div>
                );
              })}
            </div>
          )}
          <span className="mobile-discover-modal-from-to">
            {/* {`From ${days[0].format('MMMM DD, YYYY')}`} */}

            {`${days[0].format('D MMMM')} - ${days[days.length - 1].format(
              'D MMMM, YYYY',
            )}`}
          </span>
          {/* <span className="mobile-discover-modal-from-to">
            {`to ${days[days.length - 1].format('MMMM DD, YYYY')}`}
          </span> */}
          <div className="city-content">
            {cities.slice(0, 2).map((city) => (
              <LocationCity city={city} key={city} />
            ))}{' '}
            <span className="city-text">...</span>
          </div>
          <motion.div
            ref={discoverBtnRef}
            className="mobile-discover-modal-button"
            onClick={hideModal}
            animate={{
              scale: 1.05,
            }}
            exit={{ scale: 1 }}
            initial={{ scale: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 1,
              ease: 'easeInOut',
            }}
          >
            Discover your trip
          </motion.div>
          <span
            ref={shareBtnRef}
            className="mobile-discover-modal-share"
            onClick={() => {
              // @ts-ignore - mozilla doesn't have this prop
              if (isMobile && navigator.canShare) {
                handleShare();
              } else {
                hideModal();
                isShareModalVisible.value = true;
              }
            }}
          >
            Share
          </span>
        </>
      </ModalComponent>
    </>
  );
};

export default MobileDiscoverModal;
