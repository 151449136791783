import React from 'react';
import { showDrawerMenu } from '../DrawerMenu';
import { useNavigate } from 'react-router-dom';
import { showOverviewMenu } from '../OverviewMenu';
import { shouldShowMakeOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';

const Navbar = ({
  onBackButtonClick,
  style,
  shoudShowMap,
}: {
  onBackButtonClick?: () => void;
  style?: React.CSSProperties;
  shoudShowMap?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 20px',
        height: 80,
        background: '#ffffff',
        maxWidth: 450,
        width: '100%',
        alignSelf: 'center',
        ...style,
      }}
    >
      {onBackButtonClick ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onBackButtonClick?.();
          }}
          style={{
            pointerEvents: 'all',
            width: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <img
              src="./goBack.png"
              alt="Back Button"
              width={49.87}
              height={35}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            showDrawerMenu.value = true;
          }}
          style={{
            pointerEvents: 'all',
            width: '80px',
          }}
        >
          <div
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
              borderRadius: '12px', // Make it more rounded for a smoother edge
              padding: '14px', // Adjust to give some spacing inside the cube
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '72px', // Ensures it's a square
              height: '72px', // Same height as width for a cube-like shape
              backgroundColor: '#fff', // White background to match
              cursor: 'pointer',
            }}
          >
            <img
              src="./icons/burger.png"
              alt="Menu BUtton"
              width={20}
              height={14}
            />
          </div>
        </div>
      )}
      <div
        onClick={() => {
          if (showDrawerMenu.value) showDrawerMenu.value = false;
          navigate('/home');
        }}
        style={{ cursor: 'pointer' }}
      >
        <img
          src="./orangeLogoVizualTravel.svg"
          alt="Logo"
          width={123.56}
          height={26}
        />
      </div>
      {showOverviewMenu.value || shoudShowMap ? (
        <div
          className="custom-badge pointer"
          onClick={() => {
            showOverviewMenu.value = false;
            onBackButtonClick?.();
          }}
        >
          <img
            src="./icons/mapIcon.png"
            width={24}
            height={24}
            style={{ marginRight: '4px' }}
            alt="Map Icon"
          />
          <span>Map</span>
        </div>
      ) : (
        <div style={{ width: '25%' }} />
      )}
    </div>
  );
};

export default Navbar;
