export const getIconByCategory = (category: string) => {
  let markerType = 'landmark'; // Default markerType
  if (category === '✈️ Airport') {
    markerType = 'airport';
  } else if (category === '🏨 Hotel') {
    markerType = 'hotel';
  } else if (category === '🍽️ Restaurant') {
    markerType = 'restaurant';
  } else if (category === '🏠 Home') {
    markerType = 'home';
  } else if (category === '🎡 Point of Attraction') {
    markerType = 'attraction';
  } else if (category === 'Other') {
    markerType = 'attraction';
  }
  return `./icons/${markerType}Marker-new.png`;
};
