import React, { useState } from 'react';
import './style.css';
import { motion } from 'framer-motion';
import { TravelPoint } from '../../StatsOverlay';
import moment, { Moment } from 'moment-timezone';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { uniqBy } from 'lodash';
import { selectedTravelDaySignal } from '../DaysHeader/DaysHeader';
import ActionsCreator from '~/redux/actions';
import { useSelector } from 'react-redux';
import { selectShouldShowFirstDay } from '~/redux/selectors/travelSelectors';

const HeaderDescription = ({ travelData }: { travelData: TravelPoint[] }) => {
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const shouldShowFirstDay = useSelector(selectShouldShowFirstDay);

  useSignalEffect(() => {
    setSelectedDate(selectedTravelDaySignal.value);
  });

  const travelDays = uniqBy(
    travelData.map((i, idx) => ({
      date: moment(String(i.departure.dateTime)).tz(i.departure.timezone),
      index: idx,
      timezone: i.departure.timezone,
    })),
    (u) => u.date.format('DD-MM-YYYY'),
  ).map((i) => ({
    date: i.date,
    index: i.index,
  }));

  const currentDayIndex = travelDays.findIndex(
    (i) => i.date.format('DD-MM-YYYY') === selectedDate?.format('DD-MM-YYYY'),
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ delay: 0.15, duration: 0.25 }}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 18,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <span className="bottom-tabs-play-header">
        {selectedDate
          ? selectedDate?.format('dddd, MMMM D, YYYY')
          : `${travelDays[0].date.format('MMM D')} - ${travelDays[
              travelDays.length - 1
            ].date.format('MMM D, YYYY')}`}
      </span>
      <span
        className="bottom-tabs-play-header"
        style={{ color: '#FE7138' }}
        onClick={() =>
          ActionsCreator.setShouldShowFirstDay(
            !shouldShowFirstDay ? 1 : shouldShowFirstDay + 1,
          )
        }
      >
        {currentDayIndex > -1
          ? `Day ${currentDayIndex + 1}/${travelDays.length}`
          : 'Select a Day'}
      </span>
    </motion.div>
  );
};

export default HeaderDescription;
