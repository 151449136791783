import { useEffect, useState } from 'react';
import { generateUUID } from '~/utility/utils';

export const useSessionId = () => {
  const [sessionId, setSessionId] = useState<string>('');
  useEffect(() => {
    const newSessionId = generateUUID();
    setSessionId(newSessionId);
  }, []);
  return sessionId;
};
