import React, { useRef, useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { pointsSignal } from '../PointsHeader';
import { bottomSheetOverviewData } from '../BottomSheet';
import { Arrival } from '../../StatsOverlay';
import OverviewContainer from './OverviewContainer';

export interface OverviewData {
  name: string;
  images: string[];
  description: string;
  location?: any;
  index: number;
  street?: string;
  id?: string;
  icon?: string;
  duration?: string;
  staypointDuration?: string;
  time?: string | Date;
  price?: string | number;
  point?: Arrival;
  nextPoint?: Arrival;
  prevPoint?: Arrival;
  type?: string;
  travelIndex?: number;
  cost?: number;
  review?: number;
  longDescription?: string;
  arrivalTimeZone?: string;
  departureTimeZone?: string;
  arrivalDateTime?: Date;
  departureDateTime?: Date;
  prevArrivalDateTime?: Date;
  prevArrivalTimeZone?: string;
}

const OverviewDataComponent = () => {
  const [points, setPoints] = useState<OverviewData[]>([]);
  const swiperRef = useRef<SwiperType>();

  useSignalEffect(() => {
    setPoints(pointsSignal.value);
  });

  useSignalEffect(() => {
    bottomSheetOverviewData.value?.index !== undefined &&
      swiperRef.current?.slideTo(bottomSheetOverviewData.value?.index);
  });

  return (
    <>
      <Swiper
        //@ts-ignore
        onSwiper={(ref) => (swiperRef.current = ref)}
        slidesPerView="auto"
        spaceBetween={10}
        slidesPerGroup={1}
        centeredSlides
        longSwipesRatio={1}
        onSlideChangeTransitionEnd={(swiper: SwiperType) => {
          if (bottomSheetOverviewData.value?.index !== swiper.activeIndex) {
            bottomSheetOverviewData.value = points[swiper.activeIndex];
          }
        }}
      >
        {points.map((i, idx) => {
          const extraPoints = {
            nextPoint: points[idx + 1]?.point,
            prevPoint: points[idx - 1]?.point,
          };
          return (
            <SwiperSlide key={i.id + idx.toString()}>
              {(swiperRef.current?.activeIndex || 0) + 3 > idx &&
                (swiperRef.current?.activeIndex || 0) - 3 < idx && (
                  <OverviewContainer data={{ ...i, ...extraPoints }} />
                )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default OverviewDataComponent;
