import React, { Fragment, useEffect } from 'react';
import { Box, Button, Modal, SwipeableDrawer, TextField } from '@mui/material';
import { commonButtonsSx } from './BottomSheetp2p';
import { dayCounterSignal } from '../index';
import { generationStateSignal } from '..';
import { textFieldSx } from '../WhereAndWhen';
import NoResponsePausedStateP2P from './NoResponseOfPausedStateP2P';
import { IProcessedPlace } from '~/types/websocket';

const DayReady = ({
  generateNextDay,
  createVisualization,
  handleCancel,
  messages,
}: {
  generateNextDay: (string?: string) => void;
  createVisualization: () => void;
  handleCancel: () => void;
  messages: IProcessedPlace[];
}) => {
  const [showGenerateNextDayModal, setShowGenerateNextDayModal] =
    React.useState(false);
  const [generateModalState, setGenerateModalState] = React.useState<
    'initial' | 'no'
  >('initial');
  const [othercityinput, setOtherCityInput] = React.useState('');
  const [othercityinputEnabled, setOtherCityInputEnabled] =
    React.useState(false);
  const [showDontLikeItOptions, setShowDontLikeItOptions] =
    React.useState(false);
  const [currentDay, setCurrentDay] = React.useState(dayCounterSignal.peek());

  useEffect(() => {
    const unsub = dayCounterSignal.subscribe((day) => {
      setCurrentDay(day);
    });
    return () => {
      unsub();
    };
  }, []);

  return (
    <div className="text-center">
      <Modal
        open={showGenerateNextDayModal}
        onClose={() => setShowGenerateNextDayModal(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            borderRadius: '30px',
            background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
            boxShadow:
              '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {generateModalState == 'initial' ? (
            <Fragment>
              <h2>
                <b>Keep generating</b>
              </h2>
              <h2>
                <b> for </b>{' '}
                <b style={{ color: '#FE7138' }}>
                  {generationStateSignal.peek()?.startArea}
                </b>{' '}
                <b>on Day {currentDay}</b>
              </h2>
              <Button
                sx={{
                  ...commonButtonsSx,
                  height: '64px',
                  width: '162px',
                  color: 'white',
                }}
                onClick={() => generateNextDay()}
              >
                Yes
              </Button>
              <Button
                sx={{
                  ...commonButtonsSx,
                  background: 'white',
                  color: '#FE7138',
                  fontSize: '12px',
                  fontWeight: 700,
                  textTransform: 'none',
                  height: '64px',
                  width: '162px',
                }}
                onClick={() => {
                  setGenerateModalState('no');
                }}
              >
                No
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <h2>
                <b>What city should</b>
              </h2>
              <h2>
                <b> we generate for ?</b>
              </h2>
              <div
                className="d-flex align-items-center  justify-content-center"
                style={{ width: '100%', marginTop: '16px' }}
              >
                <TextField
                  id="outlined-basic"
                  placeholder={
                    generationStateSignal.peek()?.startArea || 'Rome'
                  }
                  disabled={!othercityinputEnabled}
                  sx={textFieldSx}
                  variant="outlined"
                  style={{ width: '100%' }}
                  value={othercityinput}
                  onChange={(e) => setOtherCityInput(e.target.value)}
                />

                <Button
                  sx={{
                    ...commonButtonsSx,
                    height: '64px',
                    background: 'white',
                    width: '64px',
                    color: '#FE7138',
                    marginLeft: '8px',
                  }}
                  onClick={() => {
                    setOtherCityInputEnabled(!othercityinputEnabled);
                  }}
                >
                  {!othercityinputEnabled ? 'edit' : 'done'}
                </Button>
              </div>
              <Button
                sx={commonButtonsSx}
                onClick={() => {
                  if (othercityinput) {
                    generateNextDay(
                      `Taking into account Day ${currentDay} plan, generate the complete Day ${
                        currentDay + 1
                      } plan starting with a departure from ${
                        messages[messages.length - 1]?.place?.location?.value
                      } at transportation index ${
                        messages[messages.length - 1]?.transportationIndex
                      } to ${othercityinput}`,
                    );
                    setShowGenerateNextDayModal(false);
                  }
                }}
              >
                Generate Day {currentDay + 1}
              </Button>
            </Fragment>
          )}
        </Box>
      </Modal>

      <SwipeableDrawer
        anchor="bottom"
        open={showDontLikeItOptions}
        onClose={() => setShowDontLikeItOptions(false)}
        onOpen={() => setShowDontLikeItOptions(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        onClick={() => setShowDontLikeItOptions(false)}
        sx={{ bgcolor: 'transparent' }}
      >
        <NoResponsePausedStateP2P handleClose={handleCancel} />
      </SwipeableDrawer>

      <Box>
        <h4>
          <b> Day {currentDay}</b> <b style={{ color: '#FE7138' }}>Completed</b>{' '}
          <b>at</b>
          <b style={{ color: '#FE7138' }}>
            {' '}
            {messages?.[messages.length - 1]?.place?.location?.text || ''}{' '}
          </b>
          <b>(Point {messages[messages.length - 1]?.placeIndex})</b>
        </h4>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => generateNextDay()}
            sx={commonButtonsSx}
          >
            Generate Day {currentDay + 1}
          </Button>
          <h4 style={{ marginTop: '8px' }}>
            <b>or</b>
          </h4>
          <Button
            variant="contained"
            color="primary"
            onClick={createVisualization}
            sx={{ ...commonButtonsSx, marginTop: '4px' }}
          >
            Save and Preview Trip
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowDontLikeItOptions(true)}
            sx={{
              ...commonButtonsSx,
              background: 'white',
              color: '#FE7138',
              fontSize: '12px',
              fontWeight: 700,
              textTransform: 'none',
              height: '64px',
              maxWidth: '220px',
            }}
          >
            I don’t like it. Delete it.
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default DayReady;
