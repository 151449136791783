import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { PublishableTravelData } from '~/utility/models';
import { RootState } from '../reducers';
import { getIconByCategory } from '~/components/ViewTravel/MobileFooter/helpers';
import { Story } from 'react-insta-stories/dist/interfaces';
import { durationString } from '~/components/ViewTravel/common';
import { ItineraryMockType } from '~/components/P2PAI/MapPreview/ItineraryOverview/mockData';

export const selectTravelRawData = (state: RootState) =>
  state.TripInfoReducers.travelRawData;

export const selectTravelData = (state: RootState) =>
  state.TripInfoReducers.travelData;

export const selectCurrentOverviewData = (state: RootState) =>
  state.TripInfoReducers.currentOverviewData;

export const selectCurrentTravelDay = (state: RootState) =>
  state.TripInfoReducers.currentTravelDay;

export const selectIsStoriesOpened = (state: RootState) =>
  state.TripInfoReducers.isStoriesOpened;

export const selectTravelDays = createSelector(
  selectTravelData,
  (travelData: PublishableTravelData[]) => {
    const travelDays: Record<string, number> = {};
    let count = 1;
    travelData.forEach((point) => {
      const day = moment(String(point.departure.dateTime))
        .tz(point.departure.timezone)
        .format('DD-MM-YYYY');
      if (!travelDays[day]) {
        travelDays[day] = count;
        count++;
      }
    });
    return travelDays;
  },
);

export const selectTravelCities = createSelector(
  selectTravelData,
  (travelData) => {
    const travelCities: string[] = [];
    travelData.forEach((point) => {
      const city = point.departure?.location?.city;
      if (city && !travelCities.includes(city)) {
        travelCities.push(city);
      }
    });
    return travelCities;
  },
);

export const selectTravelCostSum = createSelector(
  selectTravelData,
  (travelData) => {
    const totalCosts = travelData.reduce(
      (sum, point) => point.arrival.cost || 0 + sum,
      0,
    );
    return totalCosts;
  },
);

export const getStoryType = (filename: string) => {
  const ext = filename.split('.').pop()?.toLocaleLowerCase();
  return ext === 'mp4' ? 'video' : 'image';
};

export const selectStoryImages = createSelector(
  selectTravelData,
  (travelData) => {
    const storyImages: Story[] = [];

    travelData.forEach((point, index) => {
      point.departure.images.forEach((image) => {
        const category = point.departure.category || '';
        const storyType = getStoryType(image);
        const newStory: Story = {
          url: image,
          type: storyType,
          header: {
            heading: point.departure.location?.label || '',
            subheading: '',
            profileImage: getIconByCategory(category),
          },
        };
        storyImages.push(newStory);
      });

      if (index === travelData.length - 1) {
        point.arrival.images.forEach((image) => {
          const storyType = getStoryType(image);
          const category = point.arrival.category || '';
          const newStory: Story = {
            url: image,
            type: storyType,
            header: {
              heading: point.arrival.location?.label || '',
              subheading: '',
              profileImage: getIconByCategory(category),
            },
          };
          storyImages.push(newStory);
        });
      }
    });

    return storyImages;
  },
);

export const selectCurrentDayData = (selectedTravelDay: string) =>
  createSelector(selectTravelData, (travelData) => {
    const filteredArray = travelData
      .map((i, idx) => ({ ...i, travelIndex: idx }))
      .filter((i) => {
        return (
          moment(String(i.departure.dateTime))
            .tz(i.departure.timezone)
            .format('DD-MM-YYYY') === selectedTravelDay
        );
      });

    const firstPointArr = [
      ...(filteredArray.length > 0
        ? [
            {
              travelIndex: filteredArray[0].travelIndex,
              index: 0,
              location: filteredArray[0]?.departure.location
                ?.coordinates as any,
              name: filteredArray[0]?.departure.location?.label || '',
              street: filteredArray[0]?.departure?.location?.street || '',
              id: filteredArray[0]?.departure?.location?.placeId || '',
              icon: getIconByCategory(filteredArray[0]?.departure.category),
              // @ts-ignore
              description: filteredArray[0]?.departure?.description || '',
              images: filteredArray[0]?.departure.images || [],
              price: 10,
              point: filteredArray[0]?.departure,
              type: filteredArray[0]?.selectedTransport,
              staypointDuration: 'START',
              departureDateTime: filteredArray[0]?.departure.dateTime,
              arrivalDateTime: filteredArray[0]?.arrival.dateTime,
              departureTimeZone: filteredArray[0]?.departure.timezone,
              arrivalTimeZone: filteredArray[0]?.arrival.timezone,
              nextPoint: filteredArray[0]?.arrival,
              prevPoint: undefined,
              cost: filteredArray[0]?.departure.cost,
              review: filteredArray[0]?.departure.review,
              longDescription: filteredArray[0]?.departure.longDescription,
            },
          ]
        : []),
    ];

    const modifiedDayData = filteredArray.map((i, index, arr) => ({
      travelIndex: i.travelIndex + 1,
      index: index + 1,
      location: i.arrival.location?.coordinates as any,
      name: i.arrival.location?.label || '',
      street: i.arrival.location?.street,
      id: i.arrival.location?.placeId,
      icon: getIconByCategory(i.arrival.category),
      // @ts-ignore
      description: i.arrival.description,
      images: i.arrival.images || [],
      duration:
        index > 0
          ? durationString(
              // @ts-ignore
              moment(arr[index - 1].arrival.dateTime),
              // @ts-ignore
              moment(arr[index - 1].departure.dateTime),
              true,
            )
          : undefined,
      staypointDuration:
        index === 0
          ? 'START'
          : index > 0 && index < arr.length
          ? durationString(
              // @ts-ignore
              moment(i.departure.dateTime),
              // @ts-ignore
              moment(arr[index - 1].arrival.dateTime),
              true,
            )
          : undefined,
      price: 10,
      point: i.departure,
      nextPoint:
        index === arr.length - 1 ? undefined : arr[index + 1]?.departure,
      prevPoint: true,
      type: i.selectedTransport,
      departureDateTime: arr[index + 1]?.departure.dateTime,
      departureTimeZone: arr[index + 1]?.departure.timezone,
      arrivalDateTime: i.arrival.dateTime,
      arrivalTimeZone: i.arrival.timezone,
      cost: i.arrival?.cost ?? 0,
      review: i.arrival.review,
      longDescription: i.arrival.longDescription,
      prevArrivalDateTime: i.arrival.dateTime,
      prevArrivalTimeZone: i.arrival.timezone,
    }));

    return [...firstPointArr, ...modifiedDayData];
  });

export const selectTotalCosts = createSelector(
  selectTravelData,
  (travelData) => {
    let costsSum = 0;
    travelData.forEach((point, index) => {
      costsSum += point.arrival.cost || 0;
      costsSum += point.transportationCost || 0;
    });
    return costsSum;
  },
);

const getCostCategories = (category: any) => {
  if (category === '🏨 Hotel' || category === '🏠 Home') {
    return 'accomodation';
  } else if (category === '🍽️ Restaurant') {
    return 'food';
  } else if (category === '🎡 Point of Attraction') {
    return 'activity';
  } else if (category === '✈️ Airport') {
    return 'flight';
  } else {
    return 'other';
  }

  // Other = 'Other';

  // Flight = 'Flight';

  // Transport = 'Walk' + 'Drive' + 'Transit' + 'Ferry';
};

export const selectCostsCategories = createSelector(
  selectTravelData,
  (travelData) => {
    const costs: Record<string, number> = {};
    travelData.forEach((data) => {
      const costCategory = getCostCategories(data.arrival.category);
      if (!costs[costCategory]) costs[costCategory] = 0;
      costs[costCategory] += data.arrival.cost || 0;
      if (!costs['transportation']) costs['transportation'] = 0;
      costs['transportation'] += data.transportationCost || 0;
    });
    return costs;
  },
);

export const selectAllDaysData = createSelector(
  selectTravelData,
  (travelData) => {
    const changedDataForBottomSheet: any[] = [];
    travelData.forEach((point, index) => {
      if (index === travelData.length - 1) {
      }
      const newDataItem: Record<any, any> = {};
      newDataItem.description =
        point[
          index === travelData.length - 1 ? 'arrival' : 'departure'
          // @ts-ignore
        ].description;
      newDataItem.index = index;
      newDataItem.images =
        point[index === travelData.length - 1 ? 'arrival' : 'departure'].images;
      newDataItem.name =
        point[
          index === travelData.length - 1 ? 'arrival' : 'departure'
        ].location?.label;
      newDataItem.location =
        point[
          index === travelData.length - 1 ? 'arrival' : 'departure'
        ].location?.coordinates;
      newDataItem.point =
        point[index === travelData.length - 1 ? 'arrival' : 'departure'];
      newDataItem.street =
        point[
          index === travelData.length - 1 ? 'arrival' : 'departure'
        ].location?.street;
      newDataItem.id =
        point[
          index === travelData.length - 1 ? 'arrival' : 'departure'
        ].location?.placeId;

      changedDataForBottomSheet.push(newDataItem);
    });
    return changedDataForBottomSheet;
  },
);

export const selectOneDayForBottomSheet = (specificDay?: string) =>
  createSelector(selectTravelData, (travelData) => {
    if (!specificDay) {
      return [];
    }

    const filteredArray = travelData
      .map((i, idx) => ({ ...i, travelIndex: idx }))
      .filter((i) => {
        return (
          moment(String(i.departure.dateTime))
            .tz(i.departure.timezone)
            .format('DD-MM-YYYY') === specificDay
        );
      });

    const changedDataForBottomSheet: any[] = [];
    filteredArray.forEach((point, index) => {
      if (index === filteredArray.length - 1) {
      }
      const newDataItem: Record<any, any> = {};
      newDataItem.description =
        point[
          index === filteredArray.length - 1 ? 'arrival' : 'departure'
          // @ts-ignore
        ].description;
      newDataItem.index = index;
      newDataItem.images =
        point[
          index === filteredArray.length - 1 ? 'arrival' : 'departure'
        ].images;
      newDataItem.name =
        point[
          index === filteredArray.length - 1 ? 'arrival' : 'departure'
        ].location?.label;
      newDataItem.location =
        point[
          index === filteredArray.length - 1 ? 'arrival' : 'departure'
        ].location?.coordinates;
      newDataItem.point =
        point[index === filteredArray.length - 1 ? 'arrival' : 'departure'];
      newDataItem.street =
        point[
          index === filteredArray.length - 1 ? 'arrival' : 'departure'
        ].location?.street;
      newDataItem.id =
        point[
          index === filteredArray.length - 1 ? 'arrival' : 'departure'
        ].location?.placeId;

      changedDataForBottomSheet.push(newDataItem);
    });
    return changedDataForBottomSheet;
  });

// P2P SELECTORS

export const selectIsP2POverviewVisible = (state: RootState) =>
  state.TripInfoReducers.isP2POverviewVisible;

export const selectP2PItineraryData = (state: RootState) =>
  state.TripInfoReducers.itineraryP2PData;

export const selectIsP2POverviewBtnVisible = (state: RootState) =>
  state.TripInfoReducers.isP2POverviewButtonVisible;

export const selectP2PTravelDays = createSelector(
  selectP2PItineraryData,
  (travelData: any[]) => {
    const travelDays: Record<string, number> = {};
    let count = 1;
    travelData.forEach((point) => {
      const day = moment(String(point.departures[0].dateTime))
        .tz(point.departures[0].timezone)
        .format('DD-MM-YYYY');
      if (!travelDays[day]) {
        travelDays[day] = count;
        count++;
      }
    });
    return travelDays;
  },
);

export const selectCurrentP2PDayData = (selectedTravelDay: string) =>
  createSelector(selectP2PItineraryData, (travelData) => {
    const filteredArray = travelData
      // @ts-ignore
      .map((i, idx) => ({ ...i, travelIndex: idx }))
      // @ts-ignore
      .filter((i) => {
        return (
          moment(String(i.departures[0].dateTime))
            .tz(i.departures[0].timezone)
            .format('DD-MM-YYYY') === selectedTravelDay
        );
      });

    const firstPointArr = [
      ...(filteredArray.length > 0
        ? [
            {
              // travelIndex: filteredArray[0].travelIndex,
              index: 0,
              icon: getIconByCategory(filteredArray[0]?.departures[0].category),
              cost: filteredArray[0]?.departures[0].cost,
              longDescription: filteredArray[0]?.departures[0].longDescription,
              review: filteredArray[0]?.departures[0].review,
              description: filteredArray[0]?.departures[0]?.description || '',
              name: filteredArray[0]?.departures[0].location?.label || '',
              street: filteredArray[0]?.departures[0]?.location?.street || '',
              id: filteredArray[0]?.departures[0]?.location?.placeId || '',
              images: filteredArray[0]?.departures[0].images || [],
              type: filteredArray[0]?.selectedTransports[0],
              transportationCosts:
                filteredArray[0]?.transportationCosts[0] || 0,
              dayIndex: filteredArray[0]?.dayIndexes[0],

              location: filteredArray[0]?.departures[0].location
                ?.coordinates as any,
              // @ts-ignore
              // price: 10,
              point: filteredArray[0]?.departures[0],
              staypointDuration: 'START',
              departureDateTime: filteredArray[0]?.departures[0].dateTime,
              arrivalDateTime: filteredArray[0]?.arrivals[0].dateTime,
              departureTimeZone: filteredArray[0]?.departures[0].timezone,
              arrivalTimeZone: filteredArray[0]?.arrivals[0].timezone,
              nextPoint: filteredArray[0]?.arrivals[0],
              prevPoint: undefined,
            },
          ]
        : []),
    ];

    // @ts-ignore
    const modifiedDayData = filteredArray.map((i, index, arr) => ({
      // travelIndex: i.arrivals[0].travelIndex + 1,
      index: index + 1,
      icon: getIconByCategory(i.arrivals[0].category),
      name: i.arrivals[0].location?.label || '',
      street: i.arrivals[0].location?.street,
      id: i.arrivals[0].location?.placeId,
      description: i.arrivals[0].description,
      images: i.arrivals[0].images || [],

      location: i.arrivals[0].location?.coordinates as any,
      // @ts-ignore
      // duration:
      //   index > 0
      //     ? durationString(
      //         // @ts-ignore
      //         moment(arr[index - 1].arrival.dateTime),
      //         // @ts-ignore
      //         moment(arr[index - 1].departure.dateTime),
      //         true,
      //       )
      //     : undefined,
      // staypointDuration:
      //   index === 0
      //     ? 'START'
      //     : index > 0 && index < arr.length
      //     ? durationString(
      //         // @ts-ignore
      //         moment(i.departure.dateTime),
      //         // @ts-ignore
      //         moment(arr[index - 1].arrival.dateTime),
      //         true,
      //       )
      //     : undefined,
      // price: 10,
      point: i.arrivals[0],
      nextPoint:
        index === arr.length - 1 ? undefined : arr[index + 1]?.departures[0],
      prevPoint: true,
      type: i.selectedTransports[0],
      departureDateTime: arr[index + 1]?.departures[0].dateTime,
      departureTimeZone: arr[index + 1]?.departures[0].timezone,
      arrivalDateTime: i.arrivals[0].dateTime,
      arrivalTimeZone: i.arrivals[0].timezone,
      cost: i.arrivals[0]?.cost ?? 0,
      review: i.arrivals[0].review,
      longDescription: i.arrivals[0].longDescription,
      prevArrivalDateTime: i.arrivals[0].dateTime,
      prevArrivalTimeZone: i.arrivals[0].timezone,
    }));

    return [...firstPointArr, ...modifiedDayData];
  });
