import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import moment, { Moment } from 'moment';
import 'swiper/css';
import { signal } from '@preact/signals-core';
import { Arrival, TravelPoint } from '../StatsOverlay';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { Swiper as SwiperType } from 'swiper/types';
import { selectedTravelDaySignal } from './DaysHeader/DaysHeader';
import { bottomSheetOverviewData } from './BottomSheet';
import { OverviewData } from './OverviewDataComponent';
import { durationString } from '../common';
import { getIconByCategory } from './helpers';

export const pointsSignal = signal<OverviewData[]>([]);

const PointsHerader = ({ travelData }: { travelData: TravelPoint[] }) => {
  const [selectedPoint, setSelectedPoint] = useState<OverviewData | null>(null);
  const [dayTravel, setDayTravel] = useState<
    (TravelPoint & { travelIndex?: number })[]
  >([]);
  const swiperRef = useRef<SwiperType>();

  const pointsOverview = useMemo(
    () => [
      ...(dayTravel.length > 0
        ? [
            {
              travelIndex: dayTravel[0].travelIndex,
              index: 0,
              location: dayTravel[0]?.departure.location?.coordinates as any,
              name: dayTravel[0]?.departure.location?.label || '',
              street: dayTravel[0]?.departure.location.street,
              id: dayTravel[0]?.departure.location.placeId,
              icon: getIconByCategory(dayTravel[0]?.departure.category),
              description: dayTravel[0]?.departure.description || '',
              images: dayTravel[0]?.departure.images || [],
              price: 10,
              point: dayTravel[0]?.departure,
              type: dayTravel[0]?.selectedTransport,
              staypointDuration: 'END',
              departureDateTime: dayTravel[0]?.departure.dateTime,
              arrivalDateTime: dayTravel[0]?.arrival.dateTime,
              departureTimeZone: dayTravel[0]?.departure.timezone,
              arrivalTimeZone: dayTravel[0]?.arrival.timezone,
              nextPoint: dayTravel[0]?.arrival,
              prevPoint: undefined,
              cost: dayTravel[0]?.departure.cost,
              review: dayTravel[0]?.departure.review,
              longDescription: dayTravel[0]?.departure.longDescription,
            },
          ]
        : []),
      ...dayTravel.map((i, index, arr) => ({
        travelIndex: i.travelIndex,
        index: index + 1,
        location: i.arrival.location?.coordinates as any,
        name: i.arrival.location?.label || '',
        street: i.arrival.location.street,
        id: i.arrival.location.placeId,
        icon: getIconByCategory(i.arrival.category),
        description: i.arrival.description || '',
        images: i.arrival.images || [],
        duration:
          index > 0
            ? durationString(
                moment(arr[index - 1].arrival.dateTime),
                moment(arr[index - 1].departure.dateTime),
                true,
              )
            : undefined,
        staypointDuration:
          index === 0
            ? 'START'
            : index > 0 && index < arr.length
            ? durationString(
                moment(i.departure.dateTime),
                moment(arr[index - 1].arrival.dateTime),
                true,
              )
            : undefined,
        price: 10,
        point: i.departure,
        type: i.selectedTransport,
        departureDateTime: arr[index + 1]?.departure.dateTime,
        departureTimeZone: arr[index + 1]?.departure.timezone,
        arrivalDateTime: i.arrival.dateTime,
        arrivalTimeZone: i.arrival.timezone,
        cost: i.arrival?.cost ?? 0,
        review: i.arrival.review,
        longDescription: i.arrival.longDescription,
        prevArrivalDateTime: i.arrival.dateTime,
        prevArrivalTimeZone: i.arrival.timezone,
      })),
    ],
    [dayTravel],
  );

  useEffect(() => {
    pointsSignal.value = pointsOverview;
  }, [pointsOverview]);

  useSignalEffect(() => {
    const filteredArray = travelData
      .map((i, idx) => ({ ...i, travelIndex: idx }))
      .filter((i) => {
        return (
          moment(String(i.departure.dateTime))
            .tz(i.departure.timezone)
            .format('DD-MM-YYYY') ===
          selectedTravelDaySignal.value?.format('DD-MM-YYYY')
        );
      });
    setDayTravel(
      selectedTravelDaySignal.value
        ? filteredArray
        : travelData.map((i, idx) => ({ ...i, travelIndex: idx })),
    );
  });

  useSignalEffect(() => {
    setSelectedPoint(bottomSheetOverviewData.value);
    if (bottomSheetOverviewData.value) {
      swiperRef.current?.slideTo(bottomSheetOverviewData.value!.index);
    }
  });

  return (
    <Swiper
      //@ts-ignore
      onSwiper={(ref) => (swiperRef.current = ref)}
      slidesPerView="auto"
      spaceBetween={10}
      slidesPerGroup={1}
      style={{ paddingTop: 5, paddingBottom: 10 }}
      centeredSlides
      longSwipesRatio={1}
    >
      {pointsOverview.map((i, idx) => {
        const selected = selectedPoint?.index === i.index;
        return (
          <SwiperSlide style={{ width: 51 }} key={idx.toString()}>
            <div
              onClick={() => {
                bottomSheetOverviewData.value = i;
                swiperRef.current?.slideTo(idx);
              }}
              style={{
                backgroundColor: selected ? '#FE7138' : '#FF91471A',

                height: 60,
                width: 51,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                borderRadius: 12,
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {'Point'}
              </div>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: selected ? 'white' : '#FE7138',
                }}
              >
                {idx + 1}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default PointsHerader;
