import { useDispatch } from 'react-redux';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  createTheme,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material';
import useStyles from './styles';
import { DateFormatType, formatDate } from '../ViewTravel/StatsOverlay';

import { signal } from '@preact/signals-core';
import { IStreamAction, useWebSocket } from '~/hooks/use_websocket';
import { getOrdinal } from '~/utility/utils';
import { addPauseSignal } from '../signals/itinerary/pauseSignal';
import { IProcessedPlace, WebSocketMessage } from '~/types/websocket';
import ActionsCreator from '~/redux/actions';
import { useSessionId } from '~/hooks/use_session_id';
import WhereAndWhen from './WhereAndWhen';
import MapPreview, {
  fadeAllpreviousLines,
  linesSignal,
  previewMapSignal,
} from './MapPreview';
import { dataReadySignal } from '../signals/itinerary/dataReadySignal';
import {
  bottomSheetp2pStateSignal,
  getMapMarker,
  markersSignal,
} from './MapPreview/BottomSheetp2p';
import { LngLatLike } from 'maplibre-gl';
import { ItineraryWrapper } from './MapPreview/ItineraryOverview/ItineraryWrapper';

interface State {
  startArea: string;
  startPoint: string;
  startDate: string;
  currentStep: number;
  errorMessage?: string; // Added errorMessage to handle errors
}

const initialState: State = {
  startArea: '',
  startPoint: '',
  startDate: '',
  currentStep: 1,
};

export const generationStateSignal =
  signal<Omit<State, 'currentStep'>>(initialState);
export const dayCounterSignal = signal<number>(0);
export const lastGeneratePlaceSignal = signal<IProcessedPlace | null>(null);
const websocketUrlPrefix =
  process.env.NODE_ENV === 'production' ? 'wss://' : 'ws://';

const Itinerary: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sessionId = useSessionId();

  const {
    messages,
    pauseStream,
    resumeStream,
    sendMessage,
    ws,
    isCompleted,
    requestNextData,
    regenerateStream,
    isDataUnavailable,
    wsLoading,
    cancelStream,
    cleanUpForNextPlace,
    error: wsError,
    extra,
    getTitleAndDescription,
  } = useWebSocket(
    `${websocketUrlPrefix}${process.env.REACT_APP_SMARTAI_TRIP_SERVER_BASE_URL?.split('//')[1]
    }`,
    sessionId,
  );

  const [state, setState] = useState<State>(initialState);

  const handleInputChange =
    (key: keyof State) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));

      if (key === 'startDate') {
        nextStep();
      }
    };

  const nextStep = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      currentStep: prevState.currentStep + 1,
    }));
  }, []);

  const restartForm = useCallback(() => {
    setState(initialState);
  }, []);

  const moveStepToPoint = (step: number) => {
    if (step === 0) return; // Ensure step cannot be set to 0
    setState((prevState) => ({
      ...prevState,
      currentStep: step,
    }));
  };

  const handleEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && state.startArea) {
      nextStep();
    }
  };

  const cleanUp = () => {
    addPauseSignal.value = false;
    dataReadySignal.value = false;

    bottomSheetp2pStateSignal.value = 'loading';

    setState((prevState) => ({
      ...prevState,
      errorMessage: '',
    }));
  };

  const handleCancel = () => {
    dayCounterSignal.value =
      dayCounterSignal.peek() > 0 ? dayCounterSignal.peek() - 1 : 0;
    cancelStream();
    cleanUp();
    markersSignal.value = [];
    linesSignal.value = [];
    setState((prevState) => ({
      ...prevState,
      currentStep: 1,
    }));
    // resumeStream();
  };

  const updateState = (
    errorMessage = '',
    showPause = false,
    showCancel = false,
  ) => {
    setState((prevState) => ({
      ...prevState,
      errorMessage,
      showPause,
      showCancel,
    }));
  };

  const sendWebSocketPayload = (
    prompt: string,
    action = IStreamAction.start,
  ) => {
    if (ws) {
      const payload: WebSocketMessage = {
        action,
        payload: {
          prompt,
          id: sessionId,
        },
      };

      dispatch(ActionsCreator.setLoading(true));
      updateState('', true, true);
      sendMessage(payload);
    } else {
      updateState('WebSocket connection failed. Please refresh the browser.');
      dispatch(ActionsCreator.setSnackbarOpen(true));
    }
  };

  const generateItineraryPrompt = () => {
    return `Build the perfect full ${getOrdinal(
      dayCounterSignal.value,
    )} Day itinerary for ${generationStateSignal.value.startArea
      } on ${formatDate(
        new Date(generationStateSignal.value.startDate),
        DateFormatType.MonthDateCommaYear,
      )} starting from ${generationStateSignal.value.startPoint
      }. Make sure it is a full day itinerary that starts in morning around 8:00 AM and ends at night. No backtracking. Least amount of transportation possible.
      Guidelines:
      1. **Initial Travel**: If starting from an airport, go to a nearby hotel as the first point.
      2. **Itinerary Structure**: The day should include (unless user specifies otherwise):
        - 2 points of attraction in the morning.
        - 1 lunch restaurant spot near the second attraction.
        - 2 more point of attraction in the afternoon.
        - 1 dinner restaurant spot near the third attraction.
        - maybe 1 point of attraction in the evening.
        - End the day at a hotel as the final stop.
      You can use an itinerary that was already done and reviewed by other people to build the day itinerary.
      3. **Proximity Check**:
        - **Distance Requirement**: All points should be close to each other with **a maximum of 2 km walking distance** or **15 minutes by car/transit** between any two points.
        - **Geocode Verification**: Ensure that the distance between points is calculated using geocode to confirm they are within the set distance limits.
      4. **Prioritization**: Heavily prioritize short travel distances (less than 20-minute walk or less than 15-minute drive/transit) between points to avoid moving across different areas of the city.
      5. **Transport Constraints**: Do **not** use walking as a transport method if the distance between departure and arrival points exceeds 2 km.
      6. **Preferences**:
        - Budget: Affordable options.
        - Must-see attractions.
        - **Avoid cemeteries**.

      Ensure the last arrival point of the day is a **hotel**; no other type is acceptable. Focus on keeping all locations within one primary area to minimize travel time and maintain a cohesive experience.
      `;
  };

  const generateNextDayPrompt = (lastPlaceGenerated: IProcessedPlace) => {
    return `
      Last point you generated for me was ${lastPlaceGenerated.generatedPlace?.arrivalPointFullName
      }  on transportation index (${lastPlaceGenerated.transportationIndex
      }), I want you to keep into account the days before and the overall structure to generate my next Day in a new area and new places I haven't visited yet in the previous days, Day ${dayCounterSignal.value
      } starting from this transportation index (${+lastPlaceGenerated.transportationIndex + 1
      }). Make sure it is a full day itinerary, the day ${dayCounterSignal.value
      } should most probably end at the same accommodation as the previous day. No backtracking. Least amount of transportation possible.
      Guidelines:
      1. **Initial Travel**: If starting from an airport, go to a nearby hotel as the first point.
      2. **Itinerary Structure**: The day should include (unless user specifies otherwise):
        - 2 points of attraction in the morning.
        - 1 lunch restaurant spot near the second attraction.
        - 2 more point of attraction in the afternoon.
        - 1 dinner restaurant spot near the third attraction.
        - maybe 1 point of attraction in the evening.
        - End the day at a hotel as the final stop.
      You can use an itinerary that was already done and reviewed by other people to build the day itinerary.
      3. **Proximity Check**:
        - **Distance Requirement**: All points should be close to each other with **a maximum of 2 km walking distance** or **15 minutes by car/transit** between any two points.
        - **Geocode Verification**: Ensure that the distance between points is calculated using geocode to confirm they are within the set distance limits.
      4. **Prioritization**: Heavily prioritize short travel distances (less than 20-minute walk or less than 15-minute drive or transit) between points to avoid moving across different areas of the city.
      5. **Transport Constraints**: Do **not** use walking as a transport method if the distance between departure and arrival points exceeds 2 km.
      6. **Preferences**:
        - Budget: Affordable options.
        - Must-see attractions.
        - **Avoid cemeteries**.
        - Spend the least amount of time in transport.

      Don't redo activities or restaurants that we did in the previous days.
      Try to explore an area you have not explored in previous days.
      Ensure the last arrival point of the day is a **hotel**; no other type is acceptable. Focus on keeping all locations within one primary area to minimize travel time and maintain a cohesive experience.
    `;
  };

  // Main Handlers
  const handleGeneration = (state: {
    startArea: string;
    startDate: string;
    startPoint: string;
  }) => {
    cleanUp();

    if (!state.startArea || !state.startPoint || !state.startDate) {
      console.log('Please complete all fields before proceeding.');
      updateState('Please complete all fields before proceeding.');
      return;
    }

    generationStateSignal.value = {
      startArea: state.startArea,
      startDate: state.startDate,
      startPoint: state.startPoint,
    };

    dayCounterSignal.value = dayCounterSignal.peek() + 1;
    const prompt = generateItineraryPrompt();
    console.log({ prompt });

    updateState();
    sendWebSocketPayload(prompt);
  };

  const generateNextDay = (optionalPrompt: string | null = null) => {
    dayCounterSignal.value = dayCounterSignal.peek() + 1;

    const lastPlaceGenerated = messages[messages.length - 1];
    if (lastPlaceGenerated) {
      const prompt =
        optionalPrompt || generateNextDayPrompt(lastPlaceGenerated);
      lastGeneratePlaceSignal.value = lastPlaceGenerated;
      cleanUp();

      markersSignal.peek().forEach((marker) => {
        if (marker.id === lastPlaceGenerated.place.location?.value) {
          marker.marker.remove();
          const newmarker = getMapMarker(
            0,
            lastPlaceGenerated.place.location?.value,
            lastPlaceGenerated.place.category,
            true,
            false,
          );
          newmarker.setLngLat(
            lastPlaceGenerated.place.location?.coordinates as LngLatLike,
          );
          previewMapSignal.peek() &&
            newmarker.addTo(previewMapSignal.peek() as any);

          return;
        }
        marker.marker.setOpacity('0.2');
      });
      fadeAllpreviousLines();

      addPauseSignal.value = false;

      cleanUpForNextPlace();
      console.log({ prompt });
      bottomSheetp2pStateSignal.value = 'provoke-loading';

      updateState('', true);
      sendWebSocketPayload(prompt, IStreamAction.nextDay);
    }
  };

  const restartFromScratch = () => {
    const prompt = generateItineraryPrompt();
    console.log({ prompt });

    updateState();
    sendWebSocketPayload(prompt);
    return;
  };

  const startFromPreviousPoint = () => {
    const lastPlaceGenerated = messages[messages.length - 1];
    if (lastPlaceGenerated && !!lastPlaceGenerated?.place) {
      regenerateStream({
        placeIndex: lastPlaceGenerated.placeIndex,
        transportationIndex: lastPlaceGenerated.transportationIndex,
        reason: '',
      });
    } else {
      regenerateStream({
        placeIndex: 1,
        transportationIndex: 1,
        reason: '',
      });
    }

    return;
  };

  return (
    <>
      <ItineraryWrapper />

      <>
        {state.currentStep === 1 && (
          <WhereAndWhen
            setDateAndCity={({
              date,
              city,
              point,
            }: {
              date: string;
              city: string;
              point: string;
            }) => {
              setState((prevState) => ({
                ...prevState,
                startArea: city,
                startDate: date,
                startPoint: point,
                currentStep: 3,
              }));
              handleGeneration({
                startArea: city,
                startDate: date,
                startPoint: point,
              });
            }}
          />

          // <Box>
          //   <TextField
          //     label="Start Area"
          //     value={state.startArea}
          //     onChange={handleInputChange('startArea')}
          //     onKeyUp={handleEnterPress}
          //     fullWidth
          //   />
          // </Box>
        )}

        {state.currentStep === 3 && (
          <MapPreview
            moveStepToPoint={moveStepToPoint}
            restartForm={restartForm}
            pauseStream={pauseStream}
            resumeStream={resumeStream}
            handleCancel={handleCancel}
            messages={messages}
            requestNextData={requestNextData}
            regenerateStream={regenerateStream}
            loading={wsLoading}
            generateNextDay={generateNextDay}
            wsError={wsError}
            restartFromScratch={restartFromScratch}
            startFromPreviousPoint={startFromPreviousPoint}
            extra={extra}
            getTitleAndDescription={getTitleAndDescription}
          />
        )}

        {/* // <Box>
            //   <TextField
            //     label="Start Date"
            //     type="date"
            //     value={state.startDate}
            //     onChange={handleInputChange('startDate')}
            //     fullWidth
            //     InputLabelProps={{ shrink: true }}
            //     inputProps={{
            //       min: new Date().toISOString().split('T')[0], // Set the minimum date to today's date
            //     }}
            //   />
            // </Box> */}

        {/* {state.currentStep === 3 && (
            <Box>
              <Box sx={{ marginBottom: '1rem' }}>
                Begin your trip in{' '}
                <Typography
                  component="span"
                  sx={{ color: '#FF8447', fontWeight: '600' }}
                >
                  {state.startArea}
                </Typography>{' '}
                on{' '}
                <Typography
                  component="span"
                  sx={{ color: '#FF8447', fontWeight: '600' }}
                >
                  {formatDate(
                    new Date(state.startDate),
                    DateFormatType.MonthDateCommaYear,
                  )}
                </Typography>
              </Box>

              <TextField
                label="Start Point"
                value={state.startPoint}
                onChange={handleInputChange('startPoint')}
                fullWidth
              />
              <Button onClick={handleGeneration} disabled={!state.startPoint}>
                Yes
              </Button>
              <Button onClick={() => moveStepToPoint(1)}>No</Button>
            </Box>
          )} */}

        {state.errorMessage && (
          <Typography color="error">{state.errorMessage}</Typography>
        )}
      </>
    </>
  );
};

export default Itinerary;
