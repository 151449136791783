import { canMultiTransportDeclareArrival, currentTravelTypeSignal, handleAnimationState } from "~/components/ViewTravel/common";
import { LandTransportController } from './LandTransportController';

/**
 * This class controls the animation of a car along a travel path on a map.
 * It inherits from the `LandTransportController` class and provides car-specific functionalities.
 */
class CarAnimationController extends LandTransportController {
  /**
   * Starts the car animation along the travel path.
   *
   * @param animationConfig
   * Configuration object for the car animation
   * (specific to CarAnimationController)
   *
   * @remarks
   * This function overrides the base class's `startAnimation` method.
   * It performs car-specific setup tasks in addition to the common
   * animation setup.
   */
  startAnimation() {

    if (this.travelSegment.selectedTransport == 'Car') {
      currentTravelTypeSignal.value = "car";
      canMultiTransportDeclareArrival.value = true;
    }
    this.initializeModelAnimation();
  }

  update(delta: number) {
    super.update(delta);
  }

  onAnimationEnded() {
    this.setAnimationExpired();
  }

  setAnimationExpired = () => {
    this.isAnimationExpired = true;
    this.onAnimationCompleteCallback();
  };
}

export { CarAnimationController };
