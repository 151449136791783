import { signal } from '@preact/signals-core';
import { useState } from 'react';
import { useSignalEffect } from '@preact/signals-react/runtime';
import { AnimatePresence, motion } from 'framer-motion';
import { isDiscoverModalVisible } from '~/components/ViewTravel/StatsOverlay';
import useIsAuth from '~/components/ViewTravel/useIsAuth';
import { showPlayPauseButton } from '~/components/ViewTravel/common';

import './style.css';

let timeoutId: ReturnType<typeof setTimeout> | undefined;
let intervalId: ReturnType<typeof setInterval> | undefined;

export const shouldShowMakeOwnPopup = signal<boolean>(true);
export const shouldShowMakeOwnPopupForSession = signal<boolean>(true);

export const MakeYourOwnPopup = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [width, setWidth] = useState(0);

  const isAuthenticated = useIsAuth();

  const resetAndRunAnimation = () => {
    setIsVisible(false);
    setWidth(1);
    clearInterval(intervalId);
    clearTimeout(timeoutId);
    if (shouldShowMakeOwnPopupForSession.peek() && !isAuthenticated) {
      runNewAnimation();
    }
  };

  function showPopupAndProgressBar() {
    setIsVisible(true);
    intervalId = setInterval(() => {
      setWidth((prev) => {
        if (prev >= 102) {
          resetAndRunAnimation();
          return 0;
        }
        return prev + 0.5;
      });
    }, 50);
  }

  function runNewAnimation() {
    timeoutId = setTimeout(() => {
      showPopupAndProgressBar();
    }, 120000);
  }

  function closePopup() {
    shouldShowMakeOwnPopupForSession.value = false;
    resetAndRunAnimation();
  }

  const handlePressYes = () => window.location.assign('/p2p-ai');

  useSignalEffect(() => {
    if (
      !isDiscoverModalVisible.value &&
      shouldShowMakeOwnPopupForSession.value
    ) {
      resetAndRunAnimation();
    }
  });

  const shouldRender =
    !isAuthenticated &&
    shouldShowMakeOwnPopupForSession.peek() &&
    !isDiscoverModalVisible.peek() &&
    !showPlayPauseButton.peek() &&
    isVisible;

  return (
    <>
      <AnimatePresence>
        {shouldRender && (
          <motion.div
            initial={{ y: 300 }}
            animate={{ y: 0 }}
            exit={{ y: 300 }}
            transition={{ duration: 0.25, type: 'spring', bounce: 0.15 }}
            className="make-your-own-popup"
          >
            <div className="meter orange">
              <span style={{ width: `${width}%` }}></span>
            </div>
            <div className="make-your-own-popup-content">
              <h4 className="make-itinerary-text">Make your Own Itinerary?</h4>
              <div className="make-own-buttons">
                <div className="custom-badge popup-no" onClick={closePopup}>
                  Not right now
                </div>
                <div className="make-own-yes" onClick={handlePressYes}>
                  <span>
                    <svg
                      width="25"
                      height="22"
                      viewBox="0 0 25 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.125 0.5C16.8227 0.5 15.6287 0.909035 14.5762 1.71577C13.5673 2.48919 12.8955 3.47429 12.5 4.19061C12.1045 3.47424 11.4327 2.48919 10.4238 1.71577C9.37133 0.909035 8.17733 0.5 6.875 0.5C3.24069 0.5 0.5 3.44637 0.5 7.35353C0.5 11.5746 3.9192 14.4626 9.09542 18.8347C9.97442 19.5772 10.9707 20.4187 12.0063 21.3163C12.1428 21.4348 12.3181 21.5 12.5 21.5C12.6819 21.5 12.8572 21.4348 12.9937 21.3163C14.0293 20.4186 15.0256 19.5771 15.9051 18.8342C21.0808 14.4626 24.5 11.5746 24.5 7.35353C24.5 3.44637 21.7593 0.5 18.125 0.5Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Yes
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
