import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './style.css'; // Make sure to create a CSS file for additional styling
import { useDispatch } from '~/redux/store';
import ActionsCreator from '~/redux/actions';
import { useAnimate } from 'react-simple-animate';
import { DateFormatType, formatDate } from '../../StatsOverlay';
import { useNavigate } from 'react-router-dom';
import { getStartedBlinkSignal } from '~/components/signals/blinkSignals';
import { pulsateAnimation } from '~/utility/utils';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { RestartAnimationModal } from '../../RestartAnimationModal';
import { isMapLoadedSignal } from '~/map/helpers';
import {
  VizualizeButtonData,
  vizualizeButtonSignal,
} from '../DaysHeader/DaysHeader';
import {
  animationIsPlaying,
  resetAllSignals,
  showPlayPauseButton,
} from '../../common';

const TravelCard = ({
  setStep,
  startDate,
  endDate,
  reserve = false,
  to,
  restart,
}: {
  setStep: any;
  startDate: Date;
  endDate: Date;
  reserve?: boolean;
  to: string;
  restart?: (value: number) => void;
}) => {
  const navigate = useNavigate();

  useSignals();

  const [open, setOpen] = useState(reserve || false);
  const [selectedPlayData, setSelectedPlayData] =
    useState<VizualizeButtonData>();

  const { play, style, isPlaying } = useAnimate({
    duration: 0.35,
    easeType: 'ease-in-out',

    start: { transform: 'scale(1)', opacity: 1, filter: 'blur(0px)' },
    end: {
      transform: 'scale(1.3)',
      opacity: 0.5,
      filter: 'blur(15px) brightness(110%)',
    },
  });

  const fadingUp = useAnimate({
    duration: 0.45,
    easeType: 'ease-in-out',
    start: { opacity: 1, transform: 'translateY(0px)' },
    end: { opacity: 0, transform: 'translateY(-100px)' },
  });

  const dispatch = useDispatch();

  const getStarted = () => {
    if (!isMapLoadedSignal.value) return;
    // added for blinking state
    getStartedBlinkSignal.value = 2;
    showPlayPauseButton.value = true;
    play(!isPlaying);
    fadingUp.play(!fadingUp.isPlaying);
    animationIsPlaying.value = true;
    // dispatch(ActionsCreator.setPlayPauseState(false));

    setTimeout(() => {
      setStep('load');
      if (selectedPlayData?.startIndex) {
        dispatch(ActionsCreator.setPlayPauseState(false));
        setTimeout(() => restart?.(selectedPlayData?.startIndex), 0);
      } else {
        dispatch(ActionsCreator.setPlayPauseState(false));
      }
    }, 300);
  };

  const handleReserve = () => {
    resetAllSignals();
    // navigate('/homepage');
    window.location.href = '/home';
    setStep('idle');
  };

  const handleClose = () => {
    setOpen(false);
    restart?.(0);
    showPlayPauseButton.value = true;
  };

  useSignalEffect(() => {
    setSelectedPlayData(vizualizeButtonSignal.value);
  });

  return (
    <>
      <div style={{ ...fadingUp?.style }}>
        <div className="date-mobile">
          From {formatDate(startDate, DateFormatType.MonthDateOnly)} to{' '}
          {formatDate(endDate, DateFormatType.MonthDateCommaYear)}
        </div>
        {/* <div className="rate-mobile">4200 $</div> */}
        <Button
          style={style}
          variant="contained"
          className="get-started-button-mobile"
          sx={{
            ...(getStartedBlinkSignal.value && {
              '&:before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                animation: `${pulsateAnimation} 2s infinite`,
                borderRadius: '8px',
                border: '5px solid rgba(255, 255, 255, 0.75)',
                top: 0,
                left: 0,
                background: 'rgba(198, 16, 0, 0)',
                boxSizing: 'border-box',
              },
            }),
          }}
          onClick={reserve ? handleReserve : getStarted}
        >
          <b className="GetStarted-mobile">
            {reserve
              ? 'Make Your Own'
              : selectedPlayData === undefined
              ? 'Vizualize Whole Trip'
              : `Vizualize Day ${selectedPlayData.day}`}
          </b>
        </Button>
      </div>
      {selectedPlayData === undefined && (
        <RestartAnimationModal open={open} onClose={handleClose} />
      )}
    </>
  );
};

export default TravelCard;
