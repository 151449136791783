import { useSignalEffect } from '@preact/signals-react/runtime';
import { Map } from 'maplibre-gl';
import { useEffect, useRef, useState } from 'react';
import { isDiscoveryOverlayVisible, mapSignal } from '~/map/ViewTravel';

import { isOverlayVisible } from './StatsOverlay';
import {
  animationIsPlaying,
  PauseAnimation,
  showMainHeader,
  showPlayPauseButton,
} from './common';
import { bottomSheetOverviewData } from './MobileFooter/BottomSheet';
import { useIsMobile } from './counter/hooks/useMobile';

const useHandleMapInteractions = () => {
  const isSingleTouch = useRef(false);
  const [map, setMap] = useState<Map>();
  const isTapOnMarker = useRef(false);
  const isMobile = useIsMobile();

  useSignalEffect(() => {
    mapSignal.value && setMap(mapSignal.value);
  });

  useSignalEffect(() => {
    if (bottomSheetOverviewData.value) {
      isTapOnMarker.current = true;
    }
  });

  // const onTouchStartListener = (e: any) => {
  //   if (isOverlayVisible.peek() && !showPlayPauseButton.peek()) {
  //     setTimeout(() => {
  //       if (!isSingleTouch.current) isOverlayVisible.value = false;
  //     }, 250);
  //   }
  //   if (!isSingleTouch.current) {
  //     if (e.originalEvent.touches.length === 1) {
  //       isSingleTouch.current = true;
  //     }
  //   } else {
  //     isSingleTouch.current = false;
  //   }
  // };

  const onTouchMoveListener = (e: any) => {
    if (isSingleTouch.current && e.originalEvent.touches.length === 1) {
      isSingleTouch.current = false;
    }
  };

  let timer: ReturnType<typeof setTimeout> | number = 0;

  function showBottomSheet() {
    isOverlayVisible.value = true;
  }

  function showBottomSheetInSomeTime(time: number) {
    clearTimeout(timer);
    timer = setTimeout(showBottomSheet, time);
  }

  const onTouchEndListener = () => {
    if (isSingleTouch.current) {
      setTimeout(() => {
        if (isSingleTouch.current) {
          if (isTapOnMarker.current) {
            isTapOnMarker.current = false;
            isOverlayVisible.value = true;
          } else {
            if (showPlayPauseButton.peek()) {
              if (!isOverlayVisible.peek()) isOverlayVisible.value = true;
            } else {
              isOverlayVisible.value = !isOverlayVisible.peek();
              if (!isOverlayVisible.value && !animationIsPlaying.peek()) {
                showBottomSheetInSomeTime(1000);
              }
            }
          }
          showPlayPauseButton.peek() &&
            animationIsPlaying.peek() &&
            PauseAnimation();
        }
        isSingleTouch.current = false;
      }, 200);
    }
  };

  const onClick = (e: any) => {
    // if (isMobile) return;
    if (!isDiscoveryOverlayVisible.peek()) {
      if (isTapOnMarker.current) {
        isTapOnMarker.current = false;
        isOverlayVisible.value = true;
      } else {
        if (showPlayPauseButton.peek()) {
          if (!isOverlayVisible.peek()) isOverlayVisible.value = true;
        } else {
          isOverlayVisible.value = !isOverlayVisible.peek();
          if (!isOverlayVisible.value && !animationIsPlaying.peek()) {
            showBottomSheetInSomeTime(1000);
          }
        }
      }

      if (showPlayPauseButton.peek() && animationIsPlaying.peek()) {
        PauseAnimation();
        showMainHeader.value = true;
      }
    }
  };

  const onDragStart = (e: any) => {
    isOverlayVisible.value = false;
  };

  const onDrag = (e: any) => {
    if (!isOverlayVisible.value && !animationIsPlaying.peek()) {
      showBottomSheetInSomeTime(1000);
    }
  };

  const onWheel = (e: any) => {
    isOverlayVisible.value = false;
    if (!isOverlayVisible.value && !animationIsPlaying.peek()) {
      showBottomSheetInSomeTime(1000);
    }
  };

  useEffect(() => {
    // if (isPlaying) {
    if (map) {
      // map?.on('touchstart', onTouchStartListener);
      map?.on('touchend', onTouchEndListener);
      map?.on('touchmove', onTouchMoveListener);
      map?.on('touchcancel', onTouchEndListener);
      map?.on('click', onClick);
      map?.on('dragstart', onDragStart);
      map?.on('drag', onDrag);
      map?.on('wheel', onWheel);
    }
    // }
    return () => {
      // map?.off('touchstart', onTouchStartListener);
      map?.off('touchend', onTouchEndListener);
      map?.off('touchmove', onTouchMoveListener);
      map?.off('touchcancel', onTouchEndListener);
      map?.on('click', onClick);
      map?.on('dragstart', onDragStart);
      map?.on('drag', onDrag);
      map?.on('wheel', onWheel);
    };
  }, [map]);

  return;
};

export default useHandleMapInteractions;
