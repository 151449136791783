// PrivateRoutes.tsx
import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ROUTES from '../index';
import HistoryPageContainer from '~/containers/HistoryContainer';
import MainPageUi from '~/components/MainPageUi';
import SettingsPageContainer from '~/containers/SettingsPageContainer';
import { AuthProvider } from '~/managers/AuthContext';
import Login from '~/components/Login/Login';
import AuthRoute from '../AuthRoute';
import Signup from '~/components/Signup/Signup';
import Verify from '~/components/EmailConfirmation';
import PasswordReset from '~/components/PasswordReset';
import NewPassword from '~/components/PasswordReset/NewPassword';
import ViewTravel from '~/map/ViewTravel';
import LandingPage from '~/components/LandingPage/LandingPage';
import { PageSplashscreen } from '~/map/ViewTravel/SplashScreen';
import Home from '~/components/Home';
import DrawerMenu from '~/components/DrawerMenu';
import Serve from '~/components/Serve';
import OverviewMenu from '~/components/OverviewMenu';
import PageWrapper from '~/components/PageWrapper';
import { MakeYourOwnPopup } from '~/map/ViewTravel/MakeYourOwnPopup';
import { useLocation } from 'react-router-dom';
import { OverviewContainerWrapper } from '~/components/P2PAI/MapPreview/OverviewContainerWrapper/OverviewContainerWrapper';

// Lazy load Smart component
const TravelAssistant = lazy(() => import('~/components/TravelAssistant'));
const AIBuilder = lazy(() => import('~/components/AIBuilder'));
const Itinerary = lazy(() => import('~/components/Itinerary'));
const P2PAI = lazy(() => import('~/components/P2PAI'));

export function AppRoutes() {
  const location = useLocation();

  return (
    <AuthProvider>
      <DrawerMenu />
      <OverviewMenu />
      <OverviewContainerWrapper />
      {location.pathname === ROUTES.VIEWTRAVEL.path && <MakeYourOwnPopup />}
      <Routes>
        <Route
          path={ROUTES.LOGIN.path}
          element={
            <PageWrapper>
              <Login />
            </PageWrapper>
          }
        />
        <Route
          path={ROUTES.SIGNUP.path}
          element={
            <PageWrapper>
              <Signup />
            </PageWrapper>
          }
        />
        <Route
          path={ROUTES.LANDINGPAGE.path}
          element={
            <PageWrapper>
              <LandingPage />
            </PageWrapper>
          }
        />
        <Route
          path={ROUTES.VERIFICATION.path}
          element={
            <PageWrapper>
              <Verify />
            </PageWrapper>
          }
        />
        <Route
          path={ROUTES.PASSWORDRESET.path}
          element={
            <PageWrapper>
              <PasswordReset />
            </PageWrapper>
          }
        />
        <Route
          path={ROUTES.NEWPASSWORD.path}
          element={
            <PageWrapper>
              <NewPassword />
            </PageWrapper>
          }
        />
        <Route
          path={`${ROUTES.VIEWTRAVEL.path}`}
          element={
            <PageWrapper>
              <ViewTravel />
            </PageWrapper>
          }
        />

        <Route
          path={ROUTES.AIBUILDER.path}
          element={
            <Suspense fallback={<PageSplashscreen />}>
              <PageWrapper>
                <AIBuilder />
              </PageWrapper>
            </Suspense>
          }
        />
        <Route
          path={ROUTES.ITINERARY.path}
          element={
            <Suspense fallback={<PageSplashscreen />}>
              <PageWrapper>
                <Itinerary />
              </PageWrapper>
            </Suspense>
          }
        />
        <Route
          path={ROUTES.P2PAI.path}
          element={
            <Suspense fallback={<PageSplashscreen />}>
              <PageWrapper>
                <P2PAI />
              </PageWrapper>
            </Suspense>
          }
        />
        <Route
          path={ROUTES.SERVE.path}
          element={
            <PageWrapper>
              <Serve />
            </PageWrapper>
          }
        />

        <Route element={<AuthRoute />}>
          <Route
            path={ROUTES.HOMEPAGE.path}
            element={
              <PageWrapper>
                <MainPageUi />
              </PageWrapper>
            }
          />
          <Route
            path={ROUTES.HOME.path}
            element={
              <PageWrapper>
                <Home />
              </PageWrapper>
            }
          />
          <Route
            path={ROUTES.HISTORY.path}
            element={
              <PageWrapper>
                <HistoryPageContainer />
              </PageWrapper>
            }
          />
          <Route
            path={ROUTES.SETTINGS.path}
            element={
              <PageWrapper>
                <SettingsPageContainer />
              </PageWrapper>
            }
          />

          <Route
            path={ROUTES.TRAVEL_ASSISTANT.path}
            element={
              <Suspense>
                <PageWrapper>
                  <TravelAssistant />
                </PageWrapper>
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
