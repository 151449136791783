import { AnimationState, Model } from '~/utility/models';
import { AppearAnimation } from './state/AppearAnimation';
import { IdleAnimation } from './state/IdleAnimation';
import { DisappearAnimation } from './state/DisappearAnimation';
import { WalkAnimation } from './state/WalkAnimation';
import { AnimationStates } from '../../utility/enums/AnimationStates';
import { WalkAnimationController } from '../WalkAnimationController';
import { AnimationClip, AnimationMixer, Group } from 'three';

export class CharacterStateMachine {
  private currentState: AnimationState | null = null;
  private states: { [key: string]: AnimationState } = {};
  private mixer: AnimationMixer;
  private modelAnimationTime: number = 0;

  controller: WalkAnimationController;

  constructor(gltf: Model, controller: WalkAnimationController) {
    this.controller = controller;

    this.mixer = new AnimationMixer(gltf.scene as Group);
    const appearClip = AnimationClip.findByName(
      gltf.animations as AnimationClip[],
      'character appear',
    );
    const idleClip = AnimationClip.findByName(
      gltf.animations,
      'character idle',
    );
    const disappearClip = AnimationClip.findByName(
      gltf.animations,
      'character disappear',
    );
    const walkClip = AnimationClip.findByName(
      gltf.animations,
      'character walk',
    );

    this.modelAnimationTime =
      (appearClip.duration + disappearClip.duration) * 1000;

    this.states[AnimationStates.Appear] = new AppearAnimation(
      this.mixer,
      appearClip,
      this,
    );

    this.states[AnimationStates.Idle] = new IdleAnimation(
      this.mixer,
      idleClip,
      this,
    );

    this.states[AnimationStates.Disappear] = new DisappearAnimation(
      this.mixer,
      disappearClip,
      this,
    );

    this.states[AnimationStates.Walk] = new WalkAnimation(this.mixer, walkClip);
  }

  setState(newStateName: string): void {
    if (this.currentState) {
      this.currentState.onExit();
    }
    this.currentState = this.states[newStateName];
    this.currentState.onEnter();
  }

  getModelAnimationTime(): number {
    return this.modelAnimationTime;
  }

  update(delta: number): void {
    this.currentState?.onUpdate(delta);
  }
}
