export const initialFormData = {
  location: null,
  dateTime: null,
  category: '',
  timezone: 'Asia/Karachi',
  images: [],
};

export const initialFormDataArray = [
  {
    location: null,
    dateTime: null,
    category: '',
    timezone: 'Asia/Karachi',
    images: [],
  },
];
