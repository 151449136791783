import { PublishableTravelData } from '~/utility/models';
import * as Types from '../actionTypes';

export type TripInfoProps = {
  travelData: PublishableTravelData[];
  travelRawData: any;
  isStoriesOpened: boolean;
  currentTravelDay?: number;
  currentOverviewData?: any;
  isP2POverviewVisible?: boolean;
  itineraryP2PData: any;
  isP2POverviewButtonVisible: boolean;
};

type ActionProps = TripInfoProps & {
  type: string;
};

const initialState: TripInfoProps = {
  currentTravelDay: undefined,
  travelRawData: {},
  travelData: [],
  currentOverviewData: null,
  isStoriesOpened: false,
  isP2POverviewVisible: false,
  itineraryP2PData: null,
  isP2POverviewButtonVisible: false,
};

const reducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case Types.SET_CURRENT_TRAVEL_DAY:
      return {
        ...state,
        currentTravelDay: action.currentTravelDay,
      };
    case Types.SET_TRAVEL_DATA:
      return {
        ...state,
        travelData: action.travelData,
      };
    case Types.SET_TRAVEL_RAW_DATA:
      return {
        ...state,
        travelRawData: action.travelRawData,
      };
    case Types.SET_CURRENT_OVERVIEW_DATA:
      return {
        ...state,
        currentOverviewData: action.currentOverviewData,
      };
    case Types.SET_IS_STORIES_OPENED:
      return {
        ...state,
        isStoriesOpened: action.isStoriesOpened,
      };
    case Types.SET_P2P_ITINERARY_DATA:
      return {
        ...state,
        itineraryP2PData: action.itineraryP2PData,
      };
    case Types.SET_IS_OVERVIEW_VISIBLE_IN_P2P:
      return {
        ...state,
        isP2POverviewVisible: action.isP2POverviewVisible,
      };
    case Types.SET_IS_OVERVIEW_BUTTON_VISIBLE_IN_P2P:
      return {
        ...state,
        isP2POverviewButtonVisible: action.isP2POverviewButtonVisible,
      };
    default:
      return state;
  }
};

export default reducer;
