import React, { Suspense, useState } from 'react';
import { Header } from 'react-insta-stories/dist/interfaces';
import CloseIcon from '@mui/icons-material/Close';

import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectStoryImages } from '~/redux/selectors/tripInfoSelectors';
const StoriesLazy = React.lazy(() => import('react-insta-stories'));

interface TripStoriesProps {
  isOpened: boolean;
  hanldeClose: () => void;
}

let timeoutId: ReturnType<typeof setTimeout> | undefined;

const TripStories = ({ isOpened, hanldeClose }: TripStoriesProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const stories = useSelector(selectStoryImages);

  const handleNextClick = () => {
    setIsLoading(true);
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <>
      {isOpened && (
        <CloseIcon
          style={{
            position: 'fixed',
            right: '10px',
            top: '20px',
            color: '#fff',
            zIndex: 9999999,
            cursor: 'pointer',
          }}
          fontSize="large"
          onClick={hanldeClose}
        />
      )}

      {isLoading && isOpened && (
        <div
          style={{
            position: 'fixed',
            top: 22,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 999999,
            height: '100%',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000',
            color: '#fff',
          }}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#fff"
            style={{
              position: 'absolute',
              top: '48%',
              transform: 'translateY(-50%)',
            }}
          >
            <g fill="none" fill-rule="evenodd" strokeWidth="2">
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="0s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="0s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="22" cy="22" r="1">
                <animate
                  attributeName="r"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="stroke-opacity"
                  begin="-0.9s"
                  dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </svg>
        </div>
      )}

      {isOpened ? (
        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 99999 }}>
          <Suspense>
            <StoriesLazy
              header={(e: Header) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 8,
                      marginBottom: 24,
                      paddingLeft: 32,
                      paddingRight: 32,
                      left: -12,
                      justifyContent: 'space-between',
                      width: '100vw',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={e.profileImage}
                      height={48}
                      style={{ marginRight: 10 }}
                      alt="story"
                    />
                    <div style={{ display: 'flex', flexGrow: 1 }}>
                      <Typography
                        fontSize={24}
                        fontFamily={'Poppins'}
                        fontWeight={'700'}
                        style={{
                          color: '#fff',
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                      >
                        {e.heading}
                      </Typography>
                    </div>
                  </div>
                );
              }}
              stories={stories}
              defaultInterval={3000}
              width={window.innerWidth}
              height={window.innerHeight}
              storyStyles={{
                width: undefined,
                height: undefined,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              onAllStoriesEnd={hanldeClose}
              onNext={handleNextClick}
            />
          </Suspense>
        </div>
      ) : null}
    </>
  );
};

export default TripStories;
