import { Loader } from '@googlemaps/js-api-loader';
import {
  LineString,
  Position,
  bezierSpline,
  distance,
  length,
  lineSliceAlong,
  lineString,
  point,
  simplify,
} from '@turf/turf';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';

import {
  Direction,
  DirectionsInput,
  EncodedPathDataObject,
  ManeuverDataProps,
} from '~/utility/models';
import { HQ_DISTANCE } from '~/utility/utils';

/**
 * Interface defining the structure of a directions result object.
 * Keys are place ID pairs in a string format, and values are corresponding direction strings.
 */
interface DirectionsResult {
  [placeIdPair: string]: EncodedPathDataObject;
}

/**
 * Interface defining the structure of a cache for storing directional lookups based on place ID pairs.
 * Keys are place ID pairs in a string format, and values are of string format ( an object containing directional data).
 */
interface LngLatCache {
  [placeIdPair: string]: string;
}

interface Res {
  sections: google.maps.DirectionsStep[];
  maneuver: string;
  instructions: string;
}

/**
 * This class is responsible for generating routes between two locations, including
 * high-resolution paths suitable for animation purposes. It utilizes the Google Maps
 * JavaScript API's Directions Service and various geometry processing techniques
 * to achieve this functionality.
 *
 * The class employs a singleton pattern to ensure only one instance exists.
 * It also caches directions results and high-resolution path data to optimize
 * performance for subsequent route requests between the same locations.
 */
export default class RouteGenerator {
  /**
   * A cache for directions results to avoid redundant API requests.
   * Keyed by the origin and destination strings.
   */
  private directionsCache: DirectionsResult = {};

  /**
   * A cache for LngLat coordinates as strings to avoid redundant geocoding requests.
   * Stored as an array to preserve order for potential route waypoints.
   */
  private lnglatCache: string[] = [];

  /**
   * A private variable holding the LngLat cache object.
   */
  private lnglat: LngLatCache = {};

  /**
   * A Google Maps Directions Service loader instance configured with the provided API key,
   * version, and referrer policy.
   */
  private loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    version: 'weekly',
    authReferrerPolicy: 'origin',
    language: 'en',
  });

  /**
   * A private static property to hold the single instance of the RouteGenerator class,
   * implementing the Singleton design pattern.
   */
  private static instance: RouteGenerator;

  /**
   * The private constructor of the RouteGenerator class. This constructor is marked as private
   * to prevent external instantiation and enforce the use of the `getInstance` method
   * to retrieve the singleton instance.
   */
  private constructor() {}

  /**
   * This function implements the Singleton design pattern to create and return a single instance of the RouteGenerator class.
   *
   * The first time this function is called, it creates a new RouteGenerator instance and stores it in a static property called `instance`.
   * On subsequent calls, the existing instance is returned without creating a new one.
   *
   * This ensures that only one RouteGenerator object exists throughout the application.
   *
   * @returns The singleton instance of the RouteGenerator class
   */
  public static getInstance(): RouteGenerator {
    if (!RouteGenerator.instance) {
      RouteGenerator.instance = new RouteGenerator();
    }
    return RouteGenerator.instance;
  }

  /**
   * This function takes a sequence of low-resolution points and generates a high-resolution version
   * suitable for animation along a path.
   *
   * @param points An array of `google.maps.LatLng` objects representing the low-resolution path
   * @param lowResPoints The original low-resolution points (optional, used for logging)
   * @returns An object containing a new high-resolution path as an array of `google.maps.LatLng` objects.
   */
  getHighResPoints(points: google.maps.LatLng[]): {
    path: google.maps.LatLng[];
  } {
    // Convert the low-resolution points to a Turf linestring
    const linestr = points.map((point: google.maps.LatLng) => [
      point.lng(),
      point.lat(),
    ]);

    // Handle cases with a single point
    if (linestr.length === 1) {
      linestr.push(linestr[0]);
    }

    const line = lineString(linestr);

    // Calculate the total length of the line
    const len = length(line);

    // If the line is very short, return the original points
    if (len <= HQ_DISTANCE * 2) {
      return {
        path: points,
      };
    }

    // Extract high-resolution start and end points with a specified distance (HQ_DISTANCE) from the line
    const highResStartPoint = lineSliceAlong(line, 0, HQ_DISTANCE);
    const highResEndPoint = lineSliceAlong(line, len - HQ_DISTANCE, len);

    // Convert the start and end point coordinates to Google Maps LatLng format
    const start = highResStartPoint.geometry.coordinates;
    const end = highResEndPoint.geometry.coordinates;

    const startCoords = start.map(
      (coord) => new google.maps.LatLng(coord[1], coord[0]),
    );
    const endCoords = end.map(
      (coord) => new google.maps.LatLng(coord[1], coord[0]),
    );

    // Extract a low-resolution path segment from the middle of the line
    const lowResPathPt = lineSliceAlong(line, HQ_DISTANCE, len - HQ_DISTANCE);

    // Simplify and potentially add curvature to the low-resolution path segment
    const { curved } = this.simplifyLowResPath(
      lowResPathPt.geometry.coordinates,
      len,
    );

    // Convert the simplified/curved path coordinates to Google Maps LatLng format
    const midCoords = curved.geometry.coordinates.map(
      (coord) => new google.maps.LatLng(coord[1], coord[0]),
    );

    // Combine start, middle, and end coordinates to create a high-resolution path
    const newArr = [...startCoords, ...midCoords, ...endCoords];

    return {
      path: newArr,
    };
  }

  /**
   * Calculates a threshold value based on the number of points and the distance of a travel segment.
   * This threshold is likely used for interpolation or simplification purposes during travel animation.
   *
   * @param numPoints The number of points representing the geometry of the travel segment
   * @param distance The distance of the travel segment in kilometers
   * @returns The interpolated threshold value between a minimum and maximum range
   */
  interpolateThreshold(numPoints: number, distance: number): number {
    /**
     * The minimum allowed number of points for the travel segment geometry
     */
    const minPoints: number = 200;

    /**
     * The maximum allowed number of points for the travel segment geometry
     */
    const maxPoints: number = 250000;

    /**
     * The minimum threshold value
     */
    const minValue: number = 0.0002;

    /**
     * The maximum threshold value
     */
    const maxValue: number = 0.8;

    /**
     * The minimum allowed distance of the travel segment in kilometers
     */
    const minDistance: number = 1;

    /**
     * The maximum allowed distance of the travel segment in kilometers
     */
    const maxDistance: number = 15000;

    // Ensure numPoints is within the range [minPoints, maxPoints]
    numPoints = Math.max(minPoints, Math.min(maxPoints, numPoints));

    // Ensure distance is within the range [minDistance, maxDistance]
    distance = Math.max(minDistance, Math.min(maxDistance, distance));

    // Directly scale distance to the threshold range
    let scaleDistance = (distance - minDistance) / (maxDistance - minDistance);

    // Apply a modifier based on points
    const pointsModifier = Math.log(numPoints) / Math.log(maxPoints); // This could be adjusted for better fitting

    // Calculate the threshold, considering both direct impact of distance and modifier based on points
    let threshold =
      minValue + (maxValue - minValue) * scaleDistance * (1 + pointsModifier);

    // Ensure the threshold is within bounds
    threshold = Math.max(minValue, Math.min(maxValue, threshold));

    return threshold;
  }

  /**
   * Simplifies and smooths a low-resolution path based on the original path length and a provided distance threshold.
   *
   * This function takes an array of points representing a path and a distance threshold as input.
   * It performs the following steps:
   * 1. Converts the points to a `LineString` geometry using the `lineString` function (assumed to be imported from a geospatial library).
   * 2. Calculates an interpolation threshold based on the number of points and the distance threshold using the `interpolateThreshold` function (assumed to be implemented elsewhere).
   * 3. Simplifies the `LineString` using the `simplify` function (likely from a geospatial library) with the calculated tolerance and a flag for high-quality simplification.
   * 4. Creates a bezier spline curve from the simplified path using the `bezierSpline` function (likely from a geospatial library), specifying a resolution and sharpness for smoothness.
   * 5. Returns an object containing the smoothed curved path and the calculated tolerance.
   *
   * @param points An array of points representing the original path
   * @param dist The distance threshold used for simplification
   * @returns An object with the following properties:
   *   - `curved`: The smoothed bezier spline curve as a geometry object
   *   - `tolerance`: The calculated tolerance used for simplification
   */
  simplifyLowResPath(points: Position[], dist: number) {
    var line = lineString(points);

    const lengthOfPoints = points.length;
    const interpolatedThreshold = this.interpolateThreshold(
      lengthOfPoints,
      dist,
    );

    const lowResPath = simplify(line, {
      tolerance: interpolatedThreshold,
      highQuality: true,
    });

    var curved = bezierSpline(lowResPath, {
      resolution: 20000,
      sharpness: 0.75,
    });

    return { curved, tolerance: interpolatedThreshold };
  }

  splitStepsByFerryPaths(
    steps: any[],
    ferryPaths: number[],
    travelMode: TravelMode,
  ): {
    sections: any[];
    maneuver: string;
    instructions: string;
  }[] {
    const result: {
      sections: any[];
      maneuver: string;
      instructions: string;
    }[] = [];
    let startIndex = 0;

    const beforeFerryManeuver =
      travelMode === TravelMode.Ferry ? 'straightline' : travelMode;
    const beforeFerryInstructions =
      travelMode === TravelMode.Ferry ? 'StraightLine' : travelMode;
    const ferryManeuver =
      travelMode === TravelMode.Ferry ? travelMode : 'straightline';
    const ferryInstructions =
      travelMode === TravelMode.Ferry ? travelMode : 'StraightLine';

    for (let i = 0; i < ferryPaths.length; i++) {
      const ferryIndex = ferryPaths[i];

      // Add section before the ferry path
      if (startIndex < ferryIndex) {
        result.push({
          sections: steps.slice(startIndex, ferryIndex),
          maneuver: beforeFerryManeuver,
          instructions: beforeFerryInstructions,
        });
      }

      // Add the ferry step as a separate section
      result.push({
        sections: [steps[ferryIndex]],
        maneuver: ferryManeuver,
        instructions: ferryInstructions,
      });

      // Update the startIndex for the next slice
      startIndex = ferryIndex + 1;
    }

    // Add the remaining steps after the last ferry path
    if (startIndex < steps.length) {
      result.push({
        sections: steps.slice(startIndex),
        maneuver: beforeFerryManeuver,
        instructions: beforeFerryInstructions,
      });
    }

    return result;
  }

  accumulatePointsFromSectionforLand(
    section: google.maps.DirectionsStep[],
  ): any[] {
    const accumulatedPoints: any[] = [];

    for (const step of section) {
      if (step.maneuver === 'ferry') {
        if (step.path && step.path.length > 0) {
          let firstFerryStart = step.path[0];
          let firstFerryEnd = step.path[step.path.length - 1];

          const origin: Direction = {
            coordinates: [firstFerryStart.lng(), firstFerryStart.lat()],
            placeId: '',
          };
          const destination: Direction = {
            coordinates: [firstFerryEnd.lng(), firstFerryEnd.lat()],
            placeId: '',
          };

          // Generate Straight Line between two Ferry Paths
          let straightLine = this.getStraightLinePathBetweentwoPoints(
            origin,
            destination,
          );

          accumulatedPoints.push(...straightLine);
        }
      } else if (step.path) {
        accumulatedPoints.push(...step.path);
      }
    }

    return accumulatedPoints;
  }

  accumulatePointsFromSection(result: Res) {
    const { maneuver, instructions, sections } = result;
    const encodePath = (path: any) =>
      google.maps.geometry.encoding.encodePath(path);

    if (maneuver === 'straightline') {
      const [start] = sections[0].path;
      const end = sections[sections.length - 1].path.slice(-1)[0];

      const origin: Direction = {
        coordinates: [start.lng(), start.lat()],
        placeId: '',
      };
      const destination: Direction = {
        coordinates: [end.lng(), end.lat()],
        placeId: '',
      };

      const straightLinePath = this.getStraightLinePathBetweentwoPoints(
        origin,
        destination,
      );
      return {
        path: encodePath(straightLinePath),
        maneuver,
        instructions,
        transportName: instructions,
      } as ManeuverDataProps;
    } else if (maneuver === 'Ferry') {
      return {
        path: encodePath(sections[0].path),
        maneuver,
        instructions,
        transportName: instructions,
      } as ManeuverDataProps;
    }
  }

  generateRouteForLand(
    steps: google.maps.DirectionsStep[],
    travelMode: TravelMode,
  ) {
    let data: ManeuverDataProps[] = [];
    let allPoints: google.maps.LatLng[] = [];

    // Filter Ferry Paths from Route Array
    const ferryPaths = steps
      .map((step, index) => (step.maneuver === 'ferry' ? index : -1))
      .filter((index) => index !== -1);

    // Create Maneuver Data objects for Ferry + Straight Line between two ferries
    if (!ferryPaths.length) {
      for (let i = 0; i < steps.length; i++) {
        let path = steps[i].path;
        allPoints.push(...path);
      }

      const { path } = this.getHighResPoints(allPoints);

      const encodePath = google.maps.geometry.encoding.encodePath(path);

      const res: EncodedPathDataObject = {
        path: encodePath,
        data: [],
      };

      return res;
    } else if (ferryPaths.length > 0) {
      const result = this.splitStepsByFerryPaths(steps, ferryPaths, travelMode);
      for (let i = 0; i < result.length; i++) {
        let points = this.accumulatePointsFromSectionforLand(
          result[i].sections,
        );
        const { path } = this.getHighResPoints(points);
        const encodePath = google.maps.geometry.encoding.encodePath(path);

        let obj = {
          path: encodePath,
          maneuver: result[i].maneuver,
          instructions: result[i].instructions,
          transportName: result[i].instructions,
        };

        localStorage.setItem('tripObjWithInstruction', JSON.stringify(obj));

        data.push(obj);
      }

      const res: EncodedPathDataObject = {
        path: '',
        data,
      };

      return res;
    }
  }

  generateRouteForWater(
    steps: google.maps.DirectionsStep[],
    origin: Direction,
    destination: Direction,
    travelMode: TravelMode,
  ) {
    let data: ManeuverDataProps[] = [];
    const ferryPaths = steps
      .map((step, index) => (step.maneuver === 'ferry' ? index : -1))
      .filter((index) => index !== -1);

    if (!ferryPaths.length) {
      console.log('Ferry not exist');
      const res = this.setupEncodedPathWhenNoRouteReturned(origin, destination);
      return res;
    } else if (ferryPaths.length > 0) {
      const result = this.splitStepsByFerryPaths(steps, ferryPaths, travelMode);

      for (let i = 0; i < result.length; i++) {
        let obj = this.accumulatePointsFromSection(result[i]);
        data.push(obj as ManeuverDataProps);
      }

      const res: EncodedPathDataObject = {
        path: '',
        data,
      };

      return res;
    }
  }

  generateRouteForTransit(steps: google.maps.DirectionsStep[]) {
    let data: ManeuverDataProps[] = [];

    for (let i = 0; i < steps.length; i++) {
      let path = steps[i].path;
      let encodedLatLng = steps[i].encoded_lat_lngs;

      if (path.length <= 5) {
        const linestr = path.map((point) => [point.lng(), point.lat()]);

        const origin: Direction = {
          coordinates: linestr[0],
          placeId: '',
        };
        const destination: Direction = {
          coordinates: linestr[linestr.length - 1],
          placeId: '',
        };

        path = this.getStraightLinePathBetweentwoPoints(origin, destination);
      } else path = this.getHighResPoints(steps[i].path).path;

      if (i < steps.length - 1) {
        const currentLastPosition = [
          path[path.length - 1].lng(),
          path[path.length - 1].lat(),
        ];
        const nextFirstPosition = [
          steps[i + 1].path[0].lng(),
          steps[i + 1].path[0].lat(),
        ];
        let currentLastPoint = point(currentLastPosition);
        let nextFirstPoint = point(nextFirstPosition);

        let distanceBetweenPoints = distance(currentLastPoint, nextFirstPoint);

        if (distanceBetweenPoints > 0) {
          let currentCoordinates = currentLastPoint.geometry.coordinates;

          let currentFirstLatLng = new google.maps.LatLng(
            currentCoordinates[1],
            currentCoordinates[0],
          );

          steps[i + 1].path.unshift(currentFirstLatLng);
        }
      }

      encodedLatLng = google.maps.geometry.encoding.encodePath(path);

      let travel_mode = steps[i].travel_mode.toLowerCase();
      let last = travel_mode.slice(1);
      let updated = travel_mode.charAt(0).toUpperCase() + last;

      let transportName = this.getTransportName(steps, i);

      let obj = {
        path: encodedLatLng,
        maneuver: steps[i].maneuver,
        instructions: steps[i].instructions,
        travelMode: updated,
        transportName: transportName,
      };
      data.push(obj);
    }

    const res: EncodedPathDataObject = {
      path: '',
      data,
    };

    return res;
  }

  createRoute(
    results: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus,
    travelMode: TravelMode,
    origin: Direction,
    destination: Direction,
  ): EncodedPathDataObject {
    let res;

    if (status !== 'OK') {
      if (travelMode === TravelMode.Transit) {
        const coordinates = this.getStraightLinePathBetweentwoPoints(
          origin,
          destination,
        );

        const encodePath =
          google.maps.geometry.encoding.encodePath(coordinates);

        let obj = {
          path: encodePath,
          maneuver: '',
          instructions: '',
          travelMode: 'Transit',
          transportName: 'NoTransitRoute',
        };

        const res: EncodedPathDataObject = {
          path: '',
          data: [obj],
        };

        return res;
      }
      res = this.setupEncodedPathWhenNoRouteReturned(origin, destination);
      return res;
    }

    let steps = (results as google.maps.DirectionsResult).routes[0].legs[0]
      .steps;

    switch (travelMode) {
      case TravelMode.Car:
      case TravelMode.Walk:
        res = this.generateRouteForLand(steps, travelMode);
        break;

      case TravelMode.Transit:
        res = this.generateRouteForTransit(steps);
        break;

      case TravelMode.Ferry:
        res = this.generateRouteForWater(
          steps,
          origin,
          destination,
          travelMode,
        );
        break;
    }

    return res as EncodedPathDataObject;
  }

  /**
   * Fetches driving directions from Google Maps Platform Directions API.
   * This function caches the results to improve performance for subsequent requests
   * with the same origin and destination.
   *
   * @param directionsInput An object containing origin and destination place IDs and the travel mode.
   * @returns A promise that resolves to an object containing the encoded path
   *          and an array of direction steps (maneuvers and instructions) if successful,
   *          or an error object if the request fails.
   */
  public async getDirections({
    origin,
    destination,
    travelMode,
  }: DirectionsInput): Promise<EncodedPathDataObject> {
    const placeIdPair = this.getPlaceIdPair(
      origin.placeId,
      destination.placeId,
      travelMode,
    );

    await this.loader.importLibrary('places');

    console.log(placeIdPair, 'placeIdPair');
    if (this.directionsCache[placeIdPair]) {
      this.lnglatCache.push(this.lnglat[placeIdPair]);

      return this.directionsCache[placeIdPair];
    } else {
      console.log('does not exists');
    }

    let selectedTravelMode: google.maps.TravelMode;

    switch (travelMode) {
      case TravelMode.Car:
        selectedTravelMode = google.maps.TravelMode.DRIVING;
        break;

      case TravelMode.Transit:
        selectedTravelMode = google.maps.TravelMode.TRANSIT;
        break;

      case TravelMode.Walk:
        selectedTravelMode = google.maps.TravelMode.WALKING;
        break;

      case TravelMode.Ferry:
        selectedTravelMode = google.maps.TravelMode.WALKING;
        break;
    }

    return new Promise<EncodedPathDataObject>((resolve, reject) => {
      const directionsService = new google.maps.DirectionsService();
      const request = {
        origin: { placeId: origin.placeId },
        destination: { placeId: destination.placeId },
        travelMode: selectedTravelMode,
        provideRouteAlternatives: true,
      };

      directionsService.route(request, (result, status) => {
        console.log('result', result);
        const res = this.createRoute(
          result,
          status,
          travelMode,
          origin,
          destination,
        );

        this.directionsCache[placeIdPair] = res;

        resolve(res);
      });
    });
  }

  getTransportName(steps: google.maps.DirectionsStep[], index: number): string {
    const step = steps[index];

    if (!step?.transit) {
      return 'Walk';
    }

    const vehicleName = step.transit.line.vehicle.name;

    if (
      vehicleName === 'Train' ||
      vehicleName.toLowerCase().includes('train')
    ) {
      return 'Train';
    } else if (
      vehicleName === 'Bus' ||
      vehicleName.toLowerCase().includes('bus')
    ) {
      return 'Bus';
    } else if (
      vehicleName === 'Tram' ||
      vehicleName.toLowerCase().includes('tram') ||
      vehicleName.toLowerCase().includes('rail')
    ) {
      return 'Tram';
    } else if (
      vehicleName === 'Subway' ||
      vehicleName === 'Underground' ||
      vehicleName.toLowerCase().includes('subway')
    ) {
      return 'Subway';
    } else if (
      vehicleName === 'Metro' ||
      vehicleName.toLowerCase().includes('metro')
    ) {
      return 'Metro';
    } else if (
      vehicleName === 'Ferry' ||
      vehicleName.toLowerCase().includes('ferry')
    ) {
      return 'Ferry';
    } else if (
      vehicleName === 'Car' ||
      vehicleName.toLowerCase().includes('car')
    ) {
      return 'Car';
    }

    return vehicleName;
  }

  /**
   * This function generates a straight line encoded path between two points
   * in the scenario where no route is returned from a routing service.
   *
   * @param origin An object representing the origin location (e.g., latitude, longitude)
   * @param destination An object representing the destination location (e.g., latitude, longitude)
   * @returns An object containing the encoded path and an empty data array
   */
  setupEncodedPathWhenNoRouteReturned(
    origin: Direction,
    destination: Direction,
  ) {
    const coordinates = this.getStraightLinePathBetweentwoPoints(
      origin,
      destination,
    );

    const encodePath = google.maps.geometry.encoding.encodePath(coordinates);

    const res = {
      path: encodePath,
      data: [],
    };

    return res;
  }

  /**
   * Calculates a straight line path between two geographic points.
   *
   * This function takes two objects with `coordinates` properties representing
   * latitude and longitude in an array format (e.g., `[longitude, latitude]`).
   * It then calculates a series of `n` coordinates along a straight line
   * connecting the origin and destination points.
   *
   * The function leverages the `@turf/turf` library's `point` and `distance` functions
   * for geospatial calculations. Finally, it uses the Google Maps JavaScript API's
   * `google.maps.geometry.encoding.encodePath` function (if available) to encode
   * the path for potential use with Google Maps.
   *
   * **Important Note:** This function requires including the `@turf/turf`
   * and Google Maps JavaScript API libraries in your project.
   *
   * @param origin An object with a `coordinates` property representing the origin point (longitude, latitude)
   * @param destination An object with a `coordinates` property representing the destination point (longitude, latitude)
   * @returns A string representing the encoded straight line path (if Google Maps API available),
   *          otherwise an array of latitude-longitude coordinate pairs.
   */
  getStraightLinePathBetweentwoPoints(
    origin: Direction,
    destination: Direction,
  ): google.maps.LatLng[] {
    let from = point(origin.coordinates);
    let to = point(destination.coordinates);

    let dist = distance(from, to);

    let start = origin.coordinates;
    let end = destination.coordinates;
    let n =
      dist < 1
        ? Math.ceil(dist * 1000)
        : dist < 10
        ? Math.ceil(dist * 100)
        : dist < 100
        ? Math.ceil(dist * 10)
        : dist;

    let coordinates = [];

    coordinates.push(start);
    for (let i = n - 1; i > 0; i--) {
      coordinates.push([
        (start[0] * i) / n + (end[0] * (n - i)) / n,
        (start[1] * i) / n + (end[1] * (n - i)) / n,
      ]);
    }

    coordinates.push(end);

    let midCoord = coordinates.map(
      (coord) => new google.maps.LatLng(coord[1], coord[0]),
    );

    return midCoord;
  }

  /**
   * Decodes a path string into a GeoJSON LineString object.
   *
   * This function decodes a path string that might be encoded using a specific
   * algorithm (e.g., Polyline encoding). It utilizes the `geometry` library
   * imported asynchronously to perform the decoding.
   *
   * @param value The path string to be decoded.
   * @returns A promise that resolves to a GeoJSON LineString object representing the decoded path.
   */
  public async decodePath(value: string): Promise<LineString> {
    const geometry = await this.loader.importLibrary('geometry');

    const path = geometry.encoding.decodePath(value);

    const lineString: LineString = {
      type: 'LineString',
      coordinates: path.map((point: google.maps.LatLng) => [
        point.lng(),
        point.lat(),
      ]),
    };

    return lineString;
  }

  /**
   * Encodes a list of positions (coordinates) into a path string.
   *
   * This function encodes a list of positions (longitude, latitude pairs)
   * into a path string that might be suitable for storage or transmission.
   * It utilizes the `geometry` library imported asynchronously to perform the encoding.
   *
   * @param array An array of positions (longitude, latitude pairs) to be encoded.
   * @returns A promise that resolves to a string representing the encoded path.
   */
  public async encodePath(array: Position[]): Promise<string> {
    const geometry = await this.loader.importLibrary('geometry');

    const path = array.map(
      (coordinate: Position) =>
        new google.maps.LatLng(coordinate[1], coordinate[0]),
    );

    const encodedPath = geometry.encoding.encodePath(path);

    return encodedPath;
  }

  /**
   * Generates a unique key by combining two place IDs.
   *
   * This private helper function creates a unique key by concatenating two
   * place IDs with an underscore separator. This key could be useful for caching
   * or storing data associated with a specific route between two places.
   *
   * @param startPlaceId The ID of the starting place.
   * @param endPlaceId The ID of the ending place.
   * @returns A string representing the combined key formed from the place IDs.
   */
  private getPlaceIdPair(
    startPlaceId: string,
    endPlaceId: string,
    travelMode: TravelMode,
  ): string {
    return `${startPlaceId}_${endPlaceId}_${travelMode}`;
  }
}
