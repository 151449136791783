import { ETabIndexes } from '.';
import { ItineraryOverview } from './ItineraryOverview';
import { TripOverview } from './TripOverview';

const OverviewMenuContent = ({ tabIndex }: { tabIndex: ETabIndexes }) => {
  if (tabIndex === ETabIndexes.TRIP) {
    return <TripOverview />;
  } else if (tabIndex === ETabIndexes.ITINERARY) {
    return <ItineraryOverview />;
  } else return <></>;
};

export default OverviewMenuContent;
