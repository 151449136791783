import React from 'react';
import ModalComponent from '../ModalComponent';
import { TravelFormHistoryData } from '~/utility/models';

import { useNavigate } from 'react-router-dom';
import { encodeTourID } from '~/utility/utils';

const ViewOrEditModal = ({
  item,
  onClose,
  travelId,
  editTravel,
}: {
  item: TravelFormHistoryData;
  index: number;
  onClose: () => void;
  travelId: string;
  editTravel: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <ModalComponent onBackdropClick={onClose}>
      <div className="view-or-edit-modal-title" style={{ color: '#FF8447' }}>
        {
          item.travelPoints[item.travelPoints.length - 1]?.arrival.location
            ?.city
        }
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <div
          onClick={() => {
            navigate(`/viewtravel?tourID=${encodeTourID(Number(travelId))}`);
          }}
          className="view-or-edit-modal-manual-button view-or-edit-modal-vizualise-button"
        >
          Vizualize
        </div>
        <div
          className="view-or-edit-modal-manual-button"
          style={{ marginTop: 20 }}
          onClick={() => editTravel()}
        >
          Manual Edit
        </div>
      </div>
    </ModalComponent>
  );
};

export default ViewOrEditModal;
