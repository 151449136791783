import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import GetStartedDesktop from './GetStartedDesktop';
import MusicButtonDesktop from './MusicButtonDesktop';
import TripTo from './TripTo';
import './style.css';
import CalendarLine, {
  calendarTransitionResolverCurrentDate,
} from './CalendarLine';
import DepartAtLine from './DepartAtLine';
import { motion, AnimatePresence } from 'framer-motion';
import FlyForMessageLine from './FlyForMessageLine';
import ArrivalAtMessageLine from './ArrivalAtMessageLine';
import { DateFormatType, formatDate } from '../StatsOverlay';
import { ActiveMusicButtonDesktop } from '../ActiveMusicButton';
import {
  animationIsPlaying,
  animationStateSignal,
  copyTextToClipboard,
  destinationStateSignal,
  emptyStateSignal,
  endStateSignal,
  loadStateProvokeSignal,
  pauseAnimationSignal,
  setImagesBySignal,
  showPlayPauseButton,
  TravelDataSignal,
  useStateWithChangeCheck,
} from '../common';
import { PauseAnimation, PlayAnimation } from '../common';
import ActionsCreator from '~/redux/actions';
import SharePopup from '~/components/SharePopup';
import { useDispatch, useSelector } from 'react-redux';
import StayPointMessage from './StayPointMessage';
import VideoControls from '~/components/VideoControls';
import PlayPauseButton from '~/components/PlayPauseButton';

const DELAY_TIME = 300;

let firstTime = false;
let firstTimeDestination = false;
export default function DesktopFooter(props: any) {
  const [step, setStep] = useStateWithChangeCheck('idle');
  const [timeOut, setTimeOut] = useState(null) as any;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false); // Track if audio was playing
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const playPauseState = useSelector(
    (state: any) => state.AnimationReducers.playPauseState,
  );

  const togglePlayPause = useCallback(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // Toggle play/pause for the same audio
      audioElement.paused ? audioElement.play() : audioElement.pause();
      setAudioPlaying(!audioElement.paused);
      setWasPlaying(!audioElement.paused);
    }
  }, []);

  const handleAudioEnded = useCallback(() => {
    setAudioPlaying(false);
    setWasPlaying(false);
  }, []);

  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (componentRef.current) {
      firstTime = false;
      firstTimeDestination = false;
      const { width, height } = componentRef.current.getBoundingClientRect();

      componentRef.current.style.width = `${width}px`;
      componentRef.current.style.height = `${height}px`;
    }

    calendarTransitionResolverCurrentDate.value = props?.currentDate;
    const unsub = loadStateProvokeSignal.subscribe((e) => {
      if (firstTime === false) {
        firstTime = true;
        return;
      }

      setStep('load');
    });
    const endStateunsub = endStateSignal.subscribe((e) => {
      if (e) {
        setStep('end');
        setImagesBySignal([]);
      }
    });
    const emptyStateunsub = emptyStateSignal.subscribe((e) => {
      if (e) {
        setStep('');
      }
    });
    const destinationStateunsub = destinationStateSignal.subscribe((e: any) => {
      if (firstTimeDestination === false) {
        firstTimeDestination = true;
        return;
      }

      if (e?.label) {
        setStep('staypoint');
      } else {
        setStep('depart');
      }
    });
    return () => {
      unsub();
      endStateunsub();
      emptyStateunsub();
      destinationStateunsub();
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (props?.step?.state === 'empty') {
        setStep('');
        setTimeOut(
          setTimeout(() => {
            setStep('');
          }, DELAY_TIME),
        );
        return;
      }

      if (
        props?.step?.state === 'depart' &&
        (step === 'load' || step === '') &&
        props?.selectedTransports?.[props?.step?.calendarStep || 0]?.length > 0
      ) {
        setStep('staypoint');

        return;
      }

      if (props?.step?.state === 'flyfor' && step === 'depart') {
        setStep('flyfor');

        return;
      }

      if (props?.step?.state === 'arrival' && step === 'flyfor') {
        setStep('arrival');
        const currentIndex = animationStateSignal.peek()?.calendarStep;
        const travelDate = TravelDataSignal.peek();
        const images = travelDate?.[currentIndex]?.arrival?.images as string[];
        setImagesBySignal(
          images,
          travelDate?.[currentIndex]?.arrival?.location?.label,
          travelDate?.[currentIndex]?.arrival?.category,
        );

        return;
      }
    })();
  }, [props?.step]);

  const animator = useMemo(() => {
    return (
      <Animator step={step} style={{ justifyContent: 'flex-end' }}>
        <React.Fragment>
          <AnimatorChild state="idle" currentState={step}>
            <GetStartedDesktop
              setStep={setStep}
              startDate={props?.startDate}
              endDate={props?.endDate}
              to={props?.to}
            />
          </AnimatorChild>
          <AnimatorChild state="load" currentState={step}>
            <CalendarLine
              startDate={props?.startDate}
              endDate={props?.endDate}
              currentDate={props?.currentDate}
            />
          </AnimatorChild>
          <AnimatorChild state="depart" currentState={step}>
            <DepartAtLine
              time={props?.fromTime?.[props?.step?.calendarStep || 0]}
              from={props?.from?.[props?.step?.calendarStep || 0] || ''}
            />
          </AnimatorChild>
          <AnimatorChild state="flyfor" currentState={step}>
            <FlyForMessageLine
              type={props?.selectedTransports?.[props?.step?.calendarStep]}
              time={props?.duration?.[props?.step?.calendarStep || 0]}
            />
          </AnimatorChild>
          <AnimatorChild state="arrival" currentState={step}>
            <ArrivalAtMessageLine
              time={props?.destinationTime?.[props?.step?.calendarStep || 0]}
              to={props?.destination?.[props?.step?.calendarStep || 0] || ''}
            />
          </AnimatorChild>
          <AnimatorChild state="staypoint" currentState={step}>
            <StayPointMessage />
          </AnimatorChild>
          <AnimatorChild state="end" currentState={step}>
            <GetStartedDesktop
              reserve
              setStep={setStep}
              startDate={formatDate(
                props?.startDate,
                DateFormatType.MonthDateCommaYear,
              )}
              endDate={formatDate(
                props?.endDate,
                DateFormatType.MonthDateCommaYear,
              )}
              to={props?.to}
              restart={props?.handleCardSelect}
            />
          </AnimatorChild>
        </React.Fragment>
      </Animator>
    );
  }, [step]);

  const handleVisibilityChange = useCallback(() => {
    const audioElement = audioRef.current;
    if (document.hidden) {
      // Set the 'wasPlaying' state based on current playback status
      setWasPlaying(audioPlaying);

      // Start a timeout to pause audio after 10 seconds
      timeoutRef.current = setTimeout(() => {
        if (audioElement) {
          audioElement.pause();
        }
        setAudioPlaying(false);
      }, 10000); // 10 seconds
    } else {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      // Resume playback if it was playing before
      if (wasPlaying) {
        // Start a timeout to play audio after 10 seconds
        timeoutRef.current = setTimeout(() => {
          if (!document.hidden && audioElement) {
            audioElement.play();
          }
          setAudioPlaying(true);
        }, 10000); // 10 seconds
      }
    }
  }, [audioPlaying, wasPlaying]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [wasPlaying]);

  return (
    <Fragment>
      {showPlayPauseButton.value && (
        <PlayPauseButton
          playPauseState={playPauseState}
          bottom={(componentRef?.current?.clientHeight || 0) + 30}
          left={'50%'}
          type={'desktop'}
        />
      )}
      <div
        ref={componentRef}
        className={`desktop-footer ${props?.lightBG ? 'lightBG' : ''}`}
        style={{
          resize: 'none',
          overflow: !['idle', 'load', 'end'].includes(step)
            ? 'hidden'
            : 'visible',
        }}
      >
        <div className="wrapper">
          <div
            className={`${
              props?.lightBG ? 'gradient-blur-light' : 'gradient-blur'
            }`}
          />

          <div className="d-flex flex-1 flex-col start overflow-hidden">
            <TripTo to={props?.to} lightBG={props?.lightBG} />
          </div>
          <div className="flex-3">{animator}</div>
          <div className="end flex-1">
            <div
              style={{
                display: 'flex',
                gap: '.6rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props?.audio && (
                <>
                  {audioPlaying ? (
                    <div
                      onClick={togglePlayPause}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <ActiveMusicButtonDesktop />
                    </div>
                  ) : (
                    <div
                      onClick={togglePlayPause}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <MusicButtonDesktop />
                    </div>
                  )}

                  {props.audio && (
                    <audio
                      ref={audioRef}
                      src={props.audio}
                      onEnded={handleAudioEnded}
                      autoPlay={audioPlaying}
                      loop={true}
                      style={{ display: 'none' }}
                    />
                  )}
                </>
              )}

              <ShareButton step={step} />
            </div>
          </div>
        </div>
      </div>
      {/* {step !== 'idle' && <VideoControls />} */}
    </Fragment>
  );
}

export const AnimatorChild = ({
  state,
  children,
  currentState,
  isMobile,
}: {
  state: any;
  children: any;
  currentState?: any;
  isMobile?: boolean;
}) => {
  const variants = {
    initial: { opacity: 0, y: isMobile ? 10 : 30 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: isMobile ? -10 : -30 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        layout
        key={state}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: isMobile ? 0.1 : 0.15 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export const Animator = ({
  step,
  children,
  opened,
  style,
}: {
  step: any;
  children: any;
  opened?: boolean;
  style?: any;
}) => {
  return (
    <motion.div
      initial={{ opacity: 1, scaleY: 1 }}
      animate={
        !opened
          ? {
              opacity: 1,
              scaleY: 1,
            }
          : { opacity: 0, scaleY: 0 }
      }
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        transformOrigin: 'bottom',
        // height: step == 'idle' || step == 'end' ? '25vh' : '15vh',
        // maxHeight: getFooterAnimatorMaxHeight(),
        ...style,
      }}
    >
      {React.Children.only(children).props.children.map((child: any) => {
        if (child.props.state !== step) return null;
        return React.cloneElement(child, {
          currentState: step,
        });
      })}
    </motion.div>
  );
};

const ShareButton = ({ step }: { step: any }) => {
  // Local States
  const [link, _] = useState(window.location.href);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const dispatch = useDispatch();

  /**
   * This function copies the url and opens the modal for further actions
   */

  const onClick = () => {
    copyTextToClipboard(link);
    if (step !== 'end' && step !== 'arrival') {
      PauseAnimation();
    }
    dispatch(ActionsCreator.showSharePopup(true));
  };
  return (
    <>
      <SharePopup
        link={link}
        onClose={
          step?.state !== 'end' && step?.state !== 'arrival'
            ? PlayAnimation
            : () => {}
        }
        setSnackbarOpen={setSnackbarOpen}
        snackbarOpen={snackbarOpen}
      />

      <div
        className="share-button-desktop"
        style={{
          background: 'rgba(235, 235, 235, 0.8)',
          cursor: 'pointer',
          padding: '1rem',
        }}
        onClick={onClick}
      >
        <svg
          width="27"
          height="23"
          viewBox="0 0 27 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.349 6.09327V1.30072C14.349 0.220707 15.5415 -0.359799 16.3515 0.243207L16.491 0.364708L26.6094 10.3098C27.0819 10.7756 27.1246 11.5226 26.7376 12.0378L26.6094 12.1841L16.491 22.1359C15.7305 22.8829 14.4953 22.4037 14.3603 21.3844L14.349 21.1999V16.4839L13.7685 16.5356C9.71848 16.9699 5.83494 18.8396 2.09765 22.1764C1.22014 22.9594 -0.134369 22.2214 0.00963217 21.0379C1.13014 11.8353 5.82594 6.76152 13.8428 6.12702L14.349 6.09327Z"
            fill="black"
          />
        </svg>
      </div>
    </>
  );
};

const getFooterAnimatorMaxHeight = () => {
  const windowWidth = window.innerWidth;
  switch (true) {
    case windowWidth < 320:
      return '50px';
    case windowWidth < 400:
      return '75px';
    case windowWidth < 500:
      return '90px';
    default:
      return '100px';
  }
};
