import { ArrowBack } from '@mui/icons-material';
import { Button, Icon, TextField } from '@mui/material';
import { Fragment, useState } from 'react';
import { bottomSheetp2pStateSignal, commonButtonsSx } from './BottomSheetp2p';
import { dayCounterSignal } from '..';
import { textFieldSx } from '../WhereAndWhen';
import { previewMapSignal } from '.';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import './GlobalCssDrawer.css'

const NoResponsePausedStateP2P = ({
  handleClose,
}: {
  handleClose: (reason: string) => void;
}) => {
  const [state, setState] = useState<'initial' | 'dayx' | 'whole'>('initial');
  const [customReason, setCustomReason] = useState(''); // State for custom reason
  const isMobile = useIsMobile();

  return (
    <div
      onClick={(e)=> {e.stopPropagation()}}
      style={{
        background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        borderRadius: '18px 18px 0px 0px',

        boxShadow:
          '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
        width: isMobile ? '100%' : '40%',
        margin: '0 auto',
      }}
    >
      {state == 'initial' ? (
        <Fragment>
          <h3 className="mx-2 my-3">
            <b>Delete Whole Trip </b>
          </h3>
          {/* <h3 className="mb-4"> <b>
                    or only {
                        dayCounterSignal.peek() == 0 ? 'Day 1' : `Day ${dayCounterSignal.peek() + 1}`
                    } ?</b>
                </h3> */}

          <div className="d-flex flex-column">
            {/* <Button sx={commonButtonsSx} style={{ width: '162px' }} onClick={() => {
                        setState('dayx');
                    }}>Day {dayCounterSignal.peek() + 1}</Button> */}

            <Button
              sx={commonButtonsSx}
              style={{ width: '162px', marginBottom: '30px' }}
              onClick={() => {
                setState('whole');
              }}
            >
              Yes
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="d-flex justify-content-between align-items-center w-100 pe-5">
            <div onClick={() => setState('initial')} style={{ width: '20px' }}>
              <ArrowBack />
            </div>
            <div className="flex-1"></div>
            <div
              className="d-flex flex-column mx-2 my-3"
              style={{ whiteSpace: 'nowrap', fontWeight: 700 }}
            >
              <h3 style={{ fontSize: 'min(20px,5.5vw)' }}>
                <b>Help us make it better.</b>
              </h3>
              <h3 style={{ fontSize: 'min(20px,5.5vw)' }}>
                <b>Tell us what is wrong</b>
              </h3>
              <h3 style={{ fontSize: 'min(20px,5.5vw)' }}>
                <b>
                  with{' '}
                  {state == 'whole'
                    ? 'Whole Trip'
                    : dayCounterSignal.peek() + 1}
                </b>
              </h3>
            </div>
            <div className="flex-1"></div>
          </div>
          <TextField
            // 5 lines
            multiline
            rows={5}
            fullWidth
            variant="outlined"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="e.g. The budget is too high"
            sx={textFieldSx}
          />

          <Button
            sx={{ ...commonButtonsSx, width: '162px', marginBottom: '30px' }}
            onClick={() => {
              bottomSheetp2pStateSignal.value = 'provoke-loading';
              dayCounterSignal.value = 0;
              previewMapSignal.value = null;
              handleClose(customReason);
              window.location.reload();
            }}
          >
            Done
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default NoResponsePausedStateP2P;
