import React from 'react';
import MapView from '~/map/MapView';
import FloatingActionButtons from '~components/FloatingActionButtons';
import ControlsSpace from '~components/ControlsSpace';
import Navbar from '../NavbarNew';
import FeedbackPopup from '../FeedbackPopup';
import { useNavigate } from 'react-router-dom';

/**
 * MainPageUi component - renders the main UI of the application including
 * Navbar, FloatingActionButtons, ControlsSpace, AnimationView, and FeedbackPopup.
 *
 * @component
 * @returns {React.ReactElement} - Rendered component.
 */
export default function MainPageUi() {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          background: '#ffffff',
        }}
      >
        <Navbar
          onBackButtonClick={() => {
            navigate('/home');
          }}
        />
      </div>

      <FeedbackPopup />
      <FloatingActionButtons />
      <ControlsSpace />
      <MapView isVideoPopupMap={true} />
    </>
  );
}
