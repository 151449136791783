import React, { Fragment } from 'react';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import { commonButtonsSx } from './BottomSheetp2p';
import { dayCounterSignal } from '../index';
import NoResponsePausedStateP2P from './NoResponseOfPausedStateP2P';
import { lastGeneratePlaceSignal } from '..';

const DayPaused = ({ handleResume, handleMarkAsCompleted, handleCancel, messages }: { handleResume: () => void, handleMarkAsCompleted: () => void, handleCancel: () => void, messages: any }) => {

    const [showDontLikeItOptions, setShowDontLikeItOptions] = React.useState(false);

    return <div className="text-center">


        <SwipeableDrawer

            anchor="bottom"
            open={showDontLikeItOptions}
            onClose={() => setShowDontLikeItOptions(false)}
            onOpen={() => setShowDontLikeItOptions(true)}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
            sx={{ bgcolor: 'transparent' }}
            onClick={() => setShowDontLikeItOptions(false)}
        >

            <NoResponsePausedStateP2P handleClose={handleCancel} />

        </SwipeableDrawer>

        <Box>
            <h4>
                <b> Day {dayCounterSignal.peek()}</b> <b style={{ color: "#FE7138" }}>Completed</b>  <b>at</b>
                <b style={{ color: "#FE7138" }}> {messages?.[messages.length - 1]?.place?.location?.text || ''}{' '}</b>
                <b>(Point {messages.length + (lastGeneratePlaceSignal.peek() ? 1 : 0)})</b>
            </h4>

            <div className="d-flex flex-column justify-content-center align-items-center">

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMarkAsCompleted}
                    sx={commonButtonsSx}


                >
                    Mark Day {dayCounterSignal.peek()} as Completed

                </Button>
                <h4 style={{ marginTop: '8px' }}>
                    <b>
                        or
                    </b>
                </h4>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResume}
                    sx={{ ...commonButtonsSx, marginTop: '4px' }}

                >
                    Continue from last point
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowDontLikeItOptions(true)}
                    sx={{ ...commonButtonsSx, background: 'white', color: '#FE7138', fontSize: '12px', fontWeight: 700, textTransform: 'none', height: '64px', maxWidth: '220px' }}

                >
                    I don’t like it. Delete it.
                </Button>
            </div>
        </Box>
    </div>



}

export default DayPaused;