import {
  AnimationProps,
  ConfigurationProps,
  DecodedManeuverDataProps,
  DecodedPathDataObject,
  DirectionsInput,
  ManeuverDataProps,
  ModelKeys,
  PublishableTravelData,
  PublishableTravelDataWithDecodedPath,
  PublishableTravelObject,
  TravelFormData,
} from '~models';
import tzlookup from 'tz-lookup';
import ActionsCreator from '~/redux/actions';
import {
  carZoomConfiguration,
  planeZoomConfiguration,
} from '~/map/utility/animation.config';
import { Position } from '@turf/turf';
import RouteGenerator from '~/managers/RouteGenerator';
import {
  DataTexture,
  EquirectangularReflectionMapping,
  LinearFilter,
} from 'three';
import { AxiosProgressEvent } from 'axios';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { supabase } from '~/supabase/supabaseClient';
import { TravelMode } from '~/animationEngine/utility/enums/TravelMode';
import { AppDispatch, store } from '~/redux/store';
import { WalkModels } from '~/animationEngine/utility/enums/WalkModels';
import { keyframes } from '@mui/material';
import { categories } from '~/containers/FormContainer/constants';
import dayjs from 'dayjs';
import { addTravelToHistoryAndDispatch } from '~/redux/actions/MapActions';
import { LngLatLike } from 'maplibre-gl';
import { Currencies } from '~/animationEngine/utility/enums/Currencies';
import { ICompletedPlace, IFormData, ITripDetails } from '~/types/websocket';

export interface IPlaceDetailsForStorage {
  placeId: string;
  coordinates: LngLatLike | Position;
  timezone: string;
  city: string;
  photos?: {
    url: string;
    html_attribution: string;
  }[];
  createdBy: string;
}

type HandleProgressParams = {
  progressEvent: AxiosProgressEvent;
  setProgress: (progress: number) => void;
  clearProgressInterval: (intervalId: NodeJS.Timeout | null) => void;
  progressInterval?: NodeJS.Timeout | null;
};

export interface TripOptions {
  city: string;
  code: string;
  coordinates: [number, number];
  country: string;
  label: string;
  placeId: string;
  street: string;
  text: string;
  timezone: number;
  value: string;
}

export interface TripDetails {
  options: TripOptions;
  photos: string[];
}

export function isEditingAllowed(
  index: number,
  travelPointsLength: number,
): boolean {
  const isFirstIndex = index === 0;
  const isLastIndex = index === travelPointsLength - 1;

  return isFirstIndex || isLastIndex;
}

/**
 * Constant value representing the distance of high-quality route used from Google.
 */
export const HQ_DISTANCE: number = 2;

/**
 * This function updates the modelEnum of Travel Object and filter those model enums
 * which are required in the given travel. So that only those models load which are
 * being used in the travel
 * @param data PublishableTravelDataWithDecodedPath
 * @returns returns updated modelEnum
 */
const setupModelEnums = (
  data: PublishableTravelDataWithDecodedPath,
): ModelKeys[] => {
  if (data.selectedTransport === TravelMode.Transit) {
    // Extract Unique Transport Names
    const transportNames: ModelKeys[] = [];
    data.decodedPath.data.forEach((item: any) => {
      if (!transportNames.includes(item.transportName)) {
        // Change Walk to Char
        if (item.transportName === 'Walk') {
          transportNames.push(WalkModels.Char);
        } else {
          transportNames.push(item.transportName);
        }
      }
    });

    const updatedModelEnum = data.travelSegmentConfig.modelEnum.filter(
      (item: ModelKeys) => {
        // RF: Use 'Walk' as transportName in ModelEnums instead of 'Char'
        return transportNames.includes(item);
      },
    );

    return updatedModelEnum;
  } else {
    return data.travelSegmentConfig.modelEnum;
  }
};

export const setupPublishableTravelObjectWithDecodedPath = async (
  travelObj: TravelFormData,
  modelEnum: ModelKeys[],
  modelScale: number,
  animationSpeed: number,
) => {
  let decodedPath = await getRouteData(travelObj);

  let { encodedPath, ...restTravelObj } = travelObj;

  // Add Decoded Path in TravelObject
  let publishableData: PublishableTravelDataWithDecodedPath = {
    ...restTravelObj,
    decodedPath,
    travelSegmentConfig: {
      modelScale,
      modelEnum,
      animationSpeed,
    },
  };

  // Update ModelEnum in TravelObject only for Transit Mode
  if (travelObj.selectedTransport === TravelMode.Transit) {
    const updateModelEnum = setupModelEnums(publishableData);

    publishableData = {
      ...publishableData,
      travelSegmentConfig: {
        ...publishableData.travelSegmentConfig,
        modelEnum: updateModelEnum,
      },
    };
  }
  return publishableData;
};

export const setupPublishableTravelObject = async (
  travelObj: PublishableTravelDataWithDecodedPath,
): Promise<PublishableTravelData> => {
  const { path, data } = travelObj.decodedPath;
  const routeGenerator = RouteGenerator.getInstance();

  const stepsData = await Promise.all(
    data.map(async ({ path, ...rest }) => {
      const line = await routeGenerator.encodePath(path);
      return { ...rest, path: line };
    }),
  );

  const lineString = await routeGenerator.encodePath(path);

  const { decodedPath, ...restTravelObj } = travelObj;

  const publishableData: PublishableTravelData = {
    ...restTravelObj,
    encodedPath: { path: lineString, data: stepsData },
  };

  return publishableData;
};

export const appendPublishableTravelObjectModelEnum = (
  travelObj: PublishableTravelDataWithDecodedPath,
  modelEnum: ModelKeys[],
) => {
  travelObj.travelSegmentConfig.modelEnum = modelEnum;

  return travelObj;
};

export const appendPublishableTravelObjectAnimationConfig = (
  travelObj: PublishableTravelDataWithDecodedPath,
  modelScale: number,
  animationSpeed: number,
) => {
  travelObj.travelSegmentConfig.modelScale = modelScale;
  travelObj.travelSegmentConfig.animationSpeed = animationSpeed;

  return travelObj;
};

export function loadHDRITexture() {
  return new Promise<DataTexture>((resolve, reject) => {
    try {
      new RGBELoader()
        // .setDataType(UnsignedByteType)
        .setPath('./hdr/')
        .load(
          'industrial_sunset_puresky_2k.hdr',
          (loadedTexture: DataTexture) => {
            loadedTexture.mapping = EquirectangularReflectionMapping;
            loadedTexture.minFilter = LinearFilter;
            loadedTexture.magFilter = LinearFilter;
            loadedTexture.needsUpdate = true;
            resolve(loadedTexture); // Resolve the Promise with the data
          },
        );
    } catch (error) {
      reject(error);
    }
  });
}

export let hdrTexture: DataTexture;
loadHDRITexture().then((value: DataTexture) => {
  hdrTexture = value;
});

export function calculateTravelTime(
  departureDateTime: Date | null,
  arrivalDateTime: Date | null,
): string {
  let travelTime = '';
  if (departureDateTime && arrivalDateTime) {
    const diffInMilliseconds =
      arrivalDateTime.getTime() - departureDateTime.getTime();
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
    );
    travelTime = `${hours}h ${minutes}m`;
  }
  return travelTime;
}

export const lookupTimezone = (latitude: number, longitude: number) => {
  const timezone = tzlookup(latitude, longitude);
  return timezone;
};

export function formatTime(dateTime: Date | null | undefined): string {
  if (dateTime) {
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${period}`;
  }
  return '';
}

export function getAnimationConfig(
  distance: number,
  option: string,
): AnimationProps | null {
  let configArray: ConfigurationProps[] = [];

  if (option === TravelMode.Plane) {
    configArray = planeZoomConfiguration;
  } else if (option === TravelMode.Car) {
    configArray = carZoomConfiguration;
  }

  if (configArray) {
    for (const config of configArray) {
      const { minDist, maxDist } = config.distanceRange;
      if (distance >= minDist && distance <= maxDist) {
        return {
          mapCurveHeight: config.mapCurveHeight,
          mapPitch: config.mapPitch,
          mapBearing: config.mapBearing,
          mapZoom: config.mapZoom,
          modelSize: config.modelSize,
          modelGrowthPercentage: config.modelGrowthPercentage,
          curveSpeed: config.curveSpeed,
        } as AnimationProps;
      }
    }
  }
  return null;
}

export const fetchAndStoreUserID = async (dispatch: AppDispatch) => {
  try {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      console.error('Error fetching user ID:', error.message);
      return null;
    }

    if (data.session?.user) {
      const user = data.session?.user;

      dispatch(ActionsCreator.setUserID(user.id));
      dispatch(ActionsCreator.setUserEmail(user.email as string));
      dispatch(ActionsCreator.setUserName(user.user_metadata.full_name));

      if (user.user_metadata?.profile_picture)
        dispatch(
          ActionsCreator.setUserProfileImageURL(
            user.user_metadata.profile_picture,
          ),
        );

      return user.id;
    } else {
      // User is not authenticated
      console.warn('User is not authenticated');
      return null;
    }
  } catch (error: any) {
    //Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    console.error('Error fetching user ID:', error.message);
    return null;
  }
};

export async function getRouteData(
  travelData: TravelFormData,
): Promise<DecodedPathDataObject> {
  let dataLineString = [] as Position[];
  let res;
  let stepsPath: ManeuverDataProps[] = [];
  let stepsData: DecodedManeuverDataProps[] = [];

  const routeGenerator = RouteGenerator.getInstance();

  if (
    travelData.selectedTransport === TravelMode.Car ||
    travelData.selectedTransport === TravelMode.Transit ||
    travelData.selectedTransport === TravelMode.Walk ||
    travelData.selectedTransport === TravelMode.Ferry
  ) {
    // Check if encodedPath is not defined or is in the old format (a string)
    if (!travelData.encodedPath || typeof travelData.encodedPath === 'string') {
      // Generate new directions for the travel data
      const { path, data } = await routeGenerator.getDirections({
        origin: {
          coordinates: travelData.departure.location?.coordinates as Position,
          placeId: travelData.departure.location?.placeId as string,
        },
        destination: {
          coordinates: travelData.arrival.location?.coordinates as Position,
          placeId: travelData.arrival.location?.placeId as string,
        },
        travelMode: travelData.selectedTransport,
      } as DirectionsInput);

      // Assign the generated data to stepsPath and res
      stepsPath = data;
      res = path;
    } else {
      // Handle the new format where encodedPath is an object
      // Use the existing encoded path and steps data
      res = travelData.encodedPath.path || '';
      stepsPath = travelData.encodedPath.data || [];
    }

    if (stepsPath.length > 0) {
      stepsData = await Promise.all(
        stepsPath.map(async ({ path, ...rest }) => {
          const line = await routeGenerator.decodePath(path);
          return { ...rest, path: line.coordinates };
        }),
      );
    }

    if (res) {
      const lineString = await routeGenerator.decodePath(res);
      dataLineString = lineString.coordinates;
    }
  } else if (travelData.selectedTransport === TravelMode.Plane) {
    dataLineString = [
      travelData.departure.location?.coordinates as Position,
      travelData.arrival.location?.coordinates as Position,
    ];
  }

  return { path: dataLineString, data: stepsData };
}

export async function addRouteData(travelData: TravelFormData) {
  let updatedTravelPoints!: TravelFormData;
  const routeGenerator = RouteGenerator.getInstance();
  let stepsPath;

  if (
    travelData.selectedTransport === TravelMode.Car ||
    travelData.selectedTransport === TravelMode.Transit ||
    travelData.selectedTransport === TravelMode.Walk ||
    travelData.selectedTransport === TravelMode.Ferry
  ) {
    try {
      stepsPath = travelData.encodedPath;

      // routeGenerator.getDirections returns a Promise resolving to encodedPath
      if (!stepsPath.path || typeof stepsPath === 'string') {
        const { path, data } = await routeGenerator.getDirections({
          origin: {
            coordinates: travelData.departure.location?.coordinates as Position,
            placeId: travelData.departure.location?.placeId as string,
          },
          destination: {
            coordinates: travelData.arrival.location?.coordinates as Position,
            placeId: travelData.arrival.location?.placeId as string,
          },
          travelMode: travelData.selectedTransport,
        } as DirectionsInput);

        stepsPath = { path, data };
      }

      // Create a new object based on travelData with updated encodedPath
      const updatedTravelData = {
        ...travelData,
        encodedPath: stepsPath,
      };

      // Push the updatedTravelData to the updatedTravelPoints array
      updatedTravelPoints = updatedTravelData;
    } catch (error) {
      // Handle error if getting directions fails for a specific travelData
      console.error('Error getting directions:', error);
    }
  } else if (travelData.selectedTransport === TravelMode.Plane) {
    updatedTravelPoints = travelData;
  }

  return updatedTravelPoints;
}

/**
 * NOTE
 * This was created due to this import copy from 'copy-to-clipboard';
 * failing to copy when system clipboard is used
 *
 * @param text
 */
export async function copyTextToClipboard(text: string) {
  // Check if the Clipboard API is supported
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  } else {
    // Fallback for browsers that do not support Clipboard API
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // Avoid scrolling to the bottom
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Failed to copy text using fallback: ', err);
    }
    document.body.removeChild(textArea);
  }
}

/**
 * Validates the MIME type of a file against a list of valid types.
 * @param {Object} options - The options object.
 * @param {File} options.file - The file to validate.
 * @param {string[]} options.validFileTypes - An array of valid MIME types.
 * @returns {boolean} - Returns true if the file MIME type is in the valid list; false otherwise.
 */

export const validateFileMimeType = ({
  file,
  validFileTypes,
}: {
  file: File;
  validFileTypes: string[];
}) => validFileTypes.includes(file.type);

/**
 * This function uploads an image to the specified buckent name  folder in the storage
 * @param {bucketName} options.bucketName - The bucket name of the storage
 * @param {string} options.userId - The ID of the user uploading  image
 * @param {File} options.file - The file to upload
 * @returns {Promise<{ success: boolean, isNew?: boolean, fileUrl?: object, error?: string }>} - Returns an object indicating success or failure, whether the image is new or existing, and includes the image URL or an error message
 */
export const uploadFileToStorage = async ({
  bucketName,
  userId,
  file,
}: {
  bucketName: string;
  userId: string;
  file: File;
}) => {
  try {
    // Define the user's folder path in the storage
    const userFolder = `users/${userId}/`;

    // Get the image name from the file
    const fileName = file.name;

    // Check if an image with the same name already exists in the user's folder
    const { data: filesInFolder, error: filesError } = await supabase.storage
      .from(bucketName)
      .list(`${userFolder}`);

    // Handle error when checking for existing files in the folder
    if (filesError) {
      console.error('Error checking files in folder:', filesError);
      return { success: false, error: filesError.message };
    }

    console.log({ fileName, filesInFolder });

    // Check if the image is already uploaded
    // This has been observed not to be reliable, the name in supabase is different
    const isFileAlreadyUploaded = filesInFolder.some(
      (file) => file.name === fileName,
    );

    // If the image is not already uploaded, upload it
    if (!isFileAlreadyUploaded) {
      const { data: uploadData, error } = await supabase.storage
        .from(bucketName)
        .upload(`${userFolder}${file.name}`, file, { upsert: true });

      // Handle error during image upload
      if (error) {
        console.error('Error uploading image:', error);
        return { success: false, error: error.message };
      }

      // Get the public URL of the newly uploaded image
      const newFileUrl = await supabase.storage
        .from(bucketName)
        .getPublicUrl(uploadData?.path);

      return { success: true, isNew: true, fileUrl: newFileUrl };
    } else {
      // Find the existing image in the folder
      const existingFile = filesInFolder.find((file) => file.name === fileName);

      if (existingFile) {
        // Get the public URL of the existing image
        const existingFileUrl = await supabase.storage
          .from(bucketName)
          .getPublicUrl(`${userFolder}${existingFile.name}`);

        return { success: true, isNew: false, fileUrl: existingFileUrl };
      } else {
        // Handle case where the existing image is not found
        return { success: false, error: 'Existing image not found.' };
      }
    }
  } catch (error) {
    // Handle unexpected errors
    return { success: false, error: error };
  }
};

/**
 * Retrieves all files in a specified folder within the storage bucket.
 * @param {string} bucketName - The name of the storage bucket.
 * @param {string} userId - The ID of the user whose folder to retrieve files from.
 * @returns {Promise<{ success: boolean, files?: Array<object>, error?: string }>} - Returns an object indicating success or failure,
 *   and includes the array of files or an error message.
 */
export const getAllFilesInFolder = async (
  bucketName: string,
  userId: string,
) => {
  try {
    // Define the user's folder path in the storage
    const userFolder = `users/${userId}/`;

    // Get the list of files in the user's folder
    const { data: filesInFolder, error } = await supabase.storage
      .from(bucketName)
      .list(`${userFolder}`);

    // Handle error when retrieving files
    if (error) {
      console.error('Error retrieving files in folder:', error);
      return { success: false, error: error.message };
    }

    console.log({ filesInFolder });

    // Construct public URLs for each file
    const filesWithUrls = filesInFolder.map((file) => ({
      id: file.id,
      name: file.name,
      url: supabase.storage
        .from(bucketName)
        .getPublicUrl(`${userFolder}${file.name}`)?.data?.publicUrl,
    }));

    // Return the list of files successfully retrieved with URLs
    return { success: true, files: filesWithUrls };
  } catch (error) {
    // Handle unexpected errors
    console.error('Unexpected error:', error);
    return { success: false, error: error };
  }
};

/**
 * Formats a duration in seconds into a human-readable string.
 * @param seconds - The duration in seconds.
 * @returns A formatted string representing the duration in days, hours, minutes, and seconds.
 */

export const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours}:`;
  }

  if (minutes < 10) {
    formattedDuration += `${minutes}`;
  } else {
    formattedDuration += `${minutes.toString().padStart(2, '0')}`;
  }

  formattedDuration += `:${remainingSeconds.toString().padStart(2, '0')} min`;

  return formattedDuration;
};

export interface IAudioFile {
  id: string;
  name: string;
  url: string;
}

export const getAudioDuration = async (
  file: File | IAudioFile,
): Promise<number> => {
  return new Promise((resolve, reject) => {
    // Create an audio element dynamically
    const audio = document.createElement('audio');

    // Set the audio source based on whether it's a regular File or fetched AudioFile
    if ('url' in file && file.url) {
      audio.src = file.url; // For files fetched from Supabase storage
    } else {
      audio.src = URL.createObjectURL(file as File); // For regular HTML File objects
    }

    audio.onloadedmetadata = () => {
      resolve(audio.duration);
      // revoke object url
      URL.revokeObjectURL(audio.src);
      //  remove it from memory and from being interactable
      audio.remove();
    };
    audio.onerror = (error) => {
      // revoke object url
      URL.revokeObjectURL(audio.src);
      //  remove it from memory and from being interactable
      audio.remove();
      reject(error);
    };
  });
};

/**
 * Validates the MIME type of a file against a list of valid types.
 * @param {Object} options - The options object.
 * @param {File} options.file - The file to validate.
 * @param {string[]} options.validFileTypes - An array of valid MIME types.
 * @returns {boolean} - Returns true if the file MIME type is in the valid list; false otherwise.
 */

export const validateFileMimetype = ({
  file,
  validFileTypes,
}: {
  file: File;
  validFileTypes: string[];
}) => validFileTypes.includes(file.type);

/**
 * Removes a file from storage based on the provided URL.
 * @param {bucketName} options.bucketName - The bucket name of the storage
 * @param {string} options.userId - The ID of the user uploading the  image
 * @param {string} options.fileUrl - The URL of the file to delete.
 * @returns {Promise<{ success: boolean, error?: string }>} - Returns an object indicating success or failure, and includes an optional error message.
 */
export const deleteFileByUrlFromStorage = async ({
  bucketName,
  userId,
  fileUrl,
}: {
  bucketName: string;
  userId: string;
  fileUrl: string;
}) => {
  try {
    // Extract relevant information from the URL
    const urlParts = fileUrl.split('/');

    const fileName = urlParts[urlParts.length - 1]; // The last element which is the file name

    // Attempt to remove the file from storage
    const { error } = await supabase.storage
      .from(bucketName)
      .remove([`users/${userId}/${fileName}`]);

    if (error) {
      console.error('Error removing file:', error);
      return { success: false, error: error.message };
    }

    console.log(`File '${fileName}' successfully removed.`);
    return { success: true };
  } catch (error) {
    console.error('Error removing file:', error);
    return { success: false, error: error };
  }
};

/**
 * Checks if the size of the provided file exceeds 100MB.
 * @param file - The File object representing the file to check.
 * @returns {boolean} - Returns true if the file size is greater than 100MB, false otherwise.
 */
export const isFileGreaterThan100MB = (file: File, max = 100): boolean => {
  const maxSizeBytes = max * 1024 * 1024; // 100 MB in bytes
  return file.size > maxSizeBytes;
};

// Define the pulsate animation using MUI's keyframes
export const pulsateAnimation = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
                0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    box-shadow: none;
  }
`;

export const checkTransparency = (imageData: ImageData) => {
  const { data, width, height } = imageData;
  for (let i = 3; i < width * height * 4; i += 4) {
    if (data[i] < 255) {
      return true;
    }
  }
  return false;
};

/**
 * This function encodes the TourID
 * @param {number} tourID - Id of a Tour/Travel
 * @returns Returns excoded tour Id
 */
export function encodeTourID(tourID: number) {
  const encodedTourID = tourID * 9 + 100010;
  return encodedTourID;
}

/**
 * This function generate UUID
 * @returns Returns UUID
 */

export const generateUUID = () => {
  // Create an array of 16 random bytes
  const bytes = new Uint8Array(16);
  window.crypto.getRandomValues(bytes);

  // Set certain bits according to RFC 4122
  bytes[6] = (bytes[6] & 0x0f) | 0x40; // version 4
  bytes[8] = (bytes[8] & 0x3f) | 0x80; // variant 10

  // Convert bytes to hex and format as UUID
  const uuid = Array.from(bytes)
    .map((byte, index) => {
      return byte.toString(16).padStart(2, '0');
    })
    .join('')
    .replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5'); // format as UUID

  return uuid;
};

/**
 * This functions formats category
 * @returns Returns a category type.
 */
export const formatAICategory = (type: string) => {
  const categoryMap: { [key: string]: string } = {
    Airport: categories.find((category) => category.includes('Airport')) || '',
    Home: categories.find((category) => category.includes('Home')) || '',
    Hotel: categories.find((category) => category.includes('Hotel')) || '',
    Restaurant:
      categories.find((category) => category.includes('Restaurant')) || '',
    'Point of Attraction':
      categories.find((category) => category.includes('Point of Attraction')) ||
      '',
    Other: categories.find((category) => category.includes('Other')) || '',
  };

  return categoryMap[type] || '';
};

/**
 * Creates an instance of AbortController and returns the controller along with its signal.
 * @returns An object containing the AbortController instance and its associated AbortSignal.
 */

export function createAbortController() {
  const abortController = new AbortController();
  const abortSignal = abortController.signal;

  return { abortController, abortSignal };
}

/**
 * Handles the progress of an Axios request by calculating the progress percentage
 * and updating the state accordingly.
 *
 * @param {Object} params - The parameters for handling progress.
 * @param {AxiosProgressEvent} params.progressEvent - The progress event from Axios, containing details about the upload progress.
 * @param {(progress: number) => void} params.setProgress - The function to update the progress state, typically a React state setter function.
 * @param {() => void} params.clearProgressInterval - The function to clear the progress interval, used to stop any ongoing interval related to progress updates.
 * @param {NodeJS.Timeout | null} [params.progressInterval] - The interval for clearing progress, if any, to prevent unnecessary updates.
 */

export function handleProgress({
  progressEvent,
  setProgress,
  clearProgressInterval,
  progressInterval,
}: HandleProgressParams) {
  console.log({ progressEvent });

  const totalLength = progressEvent.lengthComputable && progressEvent.total;
  console.log('onUploadProgress', totalLength);

  if (totalLength !== null) {
    console.log({ totalLength });
  }

  if (progressEvent.total) {
    const progressPercentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );

    // if (progressInterval) {
    //   clearProgressInterval(progressInterval);
    // }

    // setProgress(progressPercentage > 99 ? 95 : progressPercentage);
    console.log({ download: progressPercentage });
  }
}

/**
 * Simulates a progress bar by incrementing the progress value at regular intervals.
 * Stops automatically when the progress reaches 70%.
 *
 * @param {number} initialProgress - The initial progress value to start the simulation from. Default is 0.
 * @param {(progress: number) => void} setProgress - The function to update the progress state, typically a React state setter function.
 * @param {number} [intervalTime=1000] - The interval time in milliseconds for updating the progress. Default is 1000ms (1 second).
 *
 * @returns {NodeJS.Timeout} - Returns the interval ID, which can be used to clear the interval if needed.
 */

export function simulateProgress({
  initialProgress = 0,
  setProgress,
  intervalTime = 200,
}: {
  initialProgress?: number;
  setProgress: (progress: number) => void;
  intervalTime?: number;
}): NodeJS.Timeout {
  setProgress(initialProgress); // Set initial progress
  let progressValue = initialProgress;

  const intervalId = setInterval(() => {
    if (progressValue < 80) {
      progressValue += 1; // Increment progress normally until 80%
    } else if (progressValue < 100) {
      progressValue += 1; // Increment slower from 81% to 100%
      setProgress(progressValue);

      // Slow down after reaching 80%
      clearInterval(intervalId); // Clear the current interval
      setTimeout(() => {
        const slowIntervalId = setInterval(() => {
          if (progressValue < 100) {
            progressValue += 1;
            setProgress(progressValue);
          } else {
            clearInterval(slowIntervalId); // Stop simulation at 100%
          }
        }, intervalTime * 6); // Slower interval: 4x slower than initial
      }, intervalTime); // Wait for the last tick before slowing down
    } else {
      clearInterval(intervalId); // Stop at 100%
    }

    setProgress(progressValue);
  }, intervalTime * 4); // Start at the regular interval

  return intervalId;
}

/**
 * Clears the interval specified by the given interval ID and sets it to null.
 *
 * @param {NodeJS.Timeout | null} intervalId - The ID of the interval to be cleared. If null, no action is taken.
 *
 * @returns {void}
 */
export const clearDOMInterval = (intervalId: NodeJS.Timeout | null): void => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
};

export const formatAIServerTrips = (
  trips: {
    departure: ITripDetails;
    arrival: ITripDetails;
    transportationType: string;
    departureDate: string;
    arrivalDate: string;
    departurePointType: string;
    arrivalPointType: string;
    transportationCost: number;
    transportationIndex: number;
  }[],
) => {
  let arrivals: IFormData[] = [];
  let departures: IFormData[] = [];
  let selectedTransports: TravelMode[] = [];
  let transportationCosts: number[] = [];
  let transportationIndexes: number[] = [];

  for (const trip of trips) {
    const departure = trip.departure;
    const arrival = trip.arrival;

    const arrivalTimezone = trip.arrival.options.timezone.toString();
    const departureTimezone = trip.departure.options.timezone.toString();

    const arrivalForm = {
      location: { ...arrival.options, timezone: arrivalTimezone },
      category: formatAICategory(trip?.arrivalPointType),
      images: arrival.photos,
      dateTime: dayjs
        .tz(trip.arrivalDate?.toString(), arrivalTimezone)
        .utc()
        .format(),
      timezone: arrivalTimezone,
      cost: arrival.cost,
      description: arrival.description,
    };
    const departureForm = {
      location: {
        ...departure.options,
        timezone: departureTimezone,
      },
      category: formatAICategory(trip?.departurePointType),
      images: departure.photos,
      dateTime: dayjs
        .tz(trip.departureDate?.toString(), departureTimezone)
        .utc()
        .format(),
      timezone: departureTimezone,
      cost: departure.cost,
      description: departure?.description,
    };

    const transportationMode = trip.transportationType;

    const selectedTransport =
      transportationMode === 'Walk'
        ? TravelMode.Walk
        : transportationMode === 'Drive'
        ? TravelMode.Car
        : transportationMode === 'Flight'
        ? TravelMode.Plane
        : transportationMode === 'Transit'
        ? TravelMode.Transit
        : transportationMode === 'Ferry'
        ? TravelMode.Ferry
        : TravelMode.Transit;

    arrivals.push(arrivalForm);
    departures.push(departureForm);
    selectedTransports.push(selectedTransport);
    transportationCosts.push(trip.transportationCost);
    transportationIndexes.push(trip.transportationIndex);
  }

  return {
    arrivals,
    departures,
    selectedTransports,
    transportationCosts,
    transportationIndexes,
  };
};

export async function saveAndPublishTravelPoints({
  departures,
  arrivals,
  selectedTransports,
  selectedTransportImages,
  transportationCosts,
  title,
  dayIndexes,
  description,
  quickTips,
}: ICompletedPlace) {
  try {
    // Create travel objects and add route data
    const newTravelPoints = await Promise.all(
      departures.map(async (departureFormData, i) => {
        const arrivalFormData = arrivals[i];
        const selectedTransport = selectedTransports[i] as TravelMode;
        const selectedTransportImagesArray = selectedTransportImages[i];
        const transportationCost = transportationCosts[i];
        const dayIndex = dayIndexes[i];

        let newTravelObj: TravelFormData = {
          arrival: arrivalFormData,
          departure: departureFormData,
          selectedTransport: selectedTransport,
          encodedPath: {
            data: [],
            path: '',
          },
          selectedTransportImages: selectedTransportImagesArray
            ? [selectedTransportImagesArray]
            : [],
          transportationCost,
          dayIndex,
        };

        console.log('Travel Object:', newTravelObj);

        newTravelObj = await addRouteData(newTravelObj);

        return newTravelObj;
      }),
    );
    // Add new travel history
    const userName = store.getState().MapReducers.userName;
    const userId = store.getState().MapReducers.userID;
    const userEmail = store.getState().MapReducers.userEmail;

    await addTravelToHistoryAndDispatch(newTravelPoints, userEmail, userId);

    // Publish
    const travelData = await Promise.all(
      newTravelPoints.map(async (data) => {
        const modelEnum = getTransportModelEnum(data.selectedTransport);
        return await setupPublishableTravelObjectWithDecodedPath(
          data,
          modelEnum,
          store.getState().MapReducers.modelSize,
          store.getState().MapReducers.videoLength,
        );
      }),
    );

    const publishableTravelData = await Promise.all(
      travelData.map(setupPublishableTravelObject),
    );

    if (publishableTravelData.length > 0) {
      const clonedArray = JSON.parse(JSON.stringify(publishableTravelData));
      store.dispatch(ActionsCreator.setPublishableTravel(clonedArray));

      const publishableTravelObject: PublishableTravelObject = {
        travelPoints: clonedArray,
        mapStyleIndex: 1,
        userName,
        title,
        description,
        quickTips,
      };

      const publishResponse = await ActionsCreator.publishTravelPoints(
        publishableTravelObject,
        userId,
        store.getState().MapReducers.travelHistoryTrackingID,
      );

      const hashedTourID = encodeTourID(publishResponse);
      const baseUrl = window.location.origin;
      const link = `${baseUrl}/viewtravel?tourID=${hashedTourID}`;
      store.dispatch(ActionsCreator.setLoading(false));
      window.location.href = link;
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  } finally {
    store.dispatch(ActionsCreator.setLoading(false));
    // This is done to remove th loading state for backbutton from the browser
    sessionStorage.removeItem('loading');
  }
}

export async function saveAndPublishTravelPointsFromServer({
  departures,
  arrivals,
  selectedTransports,
  selectedTransportImages,
  transportationCosts,
  title,
  dayIndexes,
  description,
  quickTips,
}: ICompletedPlace) {
  try {
    // Create travel objects and add route data
    const newTravelPoints = await Promise.all(
      departures.map(async (departureFormData, i) => {
        const arrivalFormData = arrivals[i];
        const selectedTransport = selectedTransports[i] as TravelMode;
        const selectedTransportImagesArray = selectedTransportImages[i];
        const transportationCost = transportationCosts[i];
        const dayIndex = dayIndexes[i];

        let newTravelObj: TravelFormData = {
          arrival: arrivalFormData,
          departure: departureFormData,
          selectedTransport: selectedTransport,
          encodedPath: {
            data: [],
            path: '',
          },

          selectedTransportImages: selectedTransportImagesArray
            ? [selectedTransportImagesArray]
            : [],
          transportationCost,
          dayIndex,
        };

        console.log('Travel Object:', newTravelObj);

        newTravelObj = await addRouteData(newTravelObj);

        return newTravelObj;
      }),
    );

    // Add new travel history
    const userName = sessionStorage.getItem('userName') ?? '';
    const userId = sessionStorage.getItem('userId') ?? '';
    const userEmail = sessionStorage.getItem('userEmail') ?? '';

    await addTravelToHistoryAndDispatch(newTravelPoints, userEmail, userId);

    // Publish
    const travelData = await Promise.all(
      newTravelPoints.map(async (data) => {
        const modelEnum = getTransportModelEnum(data.selectedTransport);
        return await setupPublishableTravelObjectWithDecodedPath(
          data,
          modelEnum,
          store.getState().MapReducers.modelSize,
          store.getState().MapReducers.videoLength,
        );
      }),
    );

    const publishableTravelData = await Promise.all(
      travelData.map(setupPublishableTravelObject),
    );

    if (publishableTravelData.length > 0) {
      const clonedArray = JSON.parse(JSON.stringify(publishableTravelData));
      store.dispatch(ActionsCreator.setPublishableTravel(clonedArray));

      const publishableTravelObject: PublishableTravelObject = {
        travelPoints: clonedArray,
        mapStyleIndex: 1,
        userName,
        title,
        description,
        quickTips,
      };

      const publishResponse = await ActionsCreator.publishTravelPoints(
        publishableTravelObject,
        userId,
        store.getState().MapReducers.travelHistoryTrackingID,
      );

      const hashedTourID = encodeTourID(publishResponse);
      store.dispatch(ActionsCreator.setLoading(false));
      sessionStorage.setItem('tourId', hashedTourID.toString());
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  } finally {
    store.dispatch(ActionsCreator.setLoading(false));
    // This is done to remove th loading state for backbutton from the browser
    sessionStorage.removeItem('loading');
  }
}

function getTransportModelEnum(transport: string) {
  switch (transport) {
    case TravelMode.Car:
      return store.getState().MapReducers.carModelEnum;
    case TravelMode.Plane:
      return store.getState().MapReducers.planeModelEnum;
    case TravelMode.Walk:
      return store.getState().MapReducers.walkModelEnum;
    case TravelMode.Ferry:
      return store.getState().MapReducers.ferryModelEnum;
    default:
      return store.getState().MapReducers.transitModelEnum;
  }
}

/**
 * Saves user-uploaded images and place details to storage.
 *
 * This function uploads the provided files to a storage bucket, processes the results,
 * and saves the place details (including city, coordinates, timezone, and photos) to the database.
 *
 * @param {Object} params - The details of the place and the files to upload.
 * @param {string} params.city - The name of the city where the place is located.
 * @param {LngLatLike | Position} params.coordinates - The geographic coordinates of the place.
 * @param {string} params.timezone - The timezone of the place.
 * @param {string} params.createdBy - The user ID who created the place.
 * @param {File[]} params.files - The array of files to upload.
 * @param {string} params.placeId - The unique identifier of the place.
 * @returns {Promise<void>} A promise that resolves when the place details and files are successfully uploaded and saved.
 *
 * @example
 * await saveUserUploadedImageAndPlaceDetails({
 *   city: 'New York',
 *   coordinates: [40.7128, -74.0060],
 *   timezone: 'America/New_York',
 *   createdBy: 'user123',
 *   files: [file1, file2],
 *   placeId: 'place123',
 * });
 */
export const saveUserUploadedImageAndPlaceDetails = async ({
  city,
  coordinates,
  timezone,
  createdBy,
  files,
  placeId,
}: {
  city: string;
  coordinates: LngLatLike | Position;
  timezone: string;
  createdBy: string;
  files: File[];
  placeId: string;
}) => {
  console.log('Saving to storage', {
    city,
    coordinates,
    timezone,
    createdBy,
    files,
  });

  const uploadPromises = files.map((file) => {
    if (file.type.startsWith('video/')) {
      return uploadFileToStorage({
        bucketName: 'travel-videos',
        userId: 'vizualtravel/videos:' + placeId,
        file: file,
      });
    } else {
      return uploadFileToStorage({
        bucketName: 'travel-images',
        userId: 'vizualtravel/photos:' + placeId,
        file: file,
      });
    }
  });

  // Wait for all uploads to complete
  const uploadResults = await Promise.all(uploadPromises);

  const photos: { url: string; html_attribution: string }[] = [];

  // Process each upload result
  uploadResults.forEach((result) => {
    if (result.success && result.fileUrl) {
      photos.push({ url: result.fileUrl.data.publicUrl, html_attribution: '' });
    } else {
      console.error('Failed to upload file:', result.error);
    }
  });

  // Save place details
  await addPlaceToStorage([
    { placeId, city, coordinates, timezone, createdBy, photos },
  ]);
};

/**
 * Adds place details to the storage database.
 *
 * This function upserts place details such as coordinates, timezone, city, and photos into the 'placedetails' table.
 *
 * @param {IPlaceDetailsForStorage[]} places - An array of place details to be stored.
 * @returns {Promise<any>} A promise that resolves with the result of the upsert operation.
 *
 * @example
 * await addPlaceToStorage([{
 *   placeId: 'place123',
 *   city: 'New York',
 *   coordinates: [40.7128, -74.0060],
 *   timezone: 'America/New_York',
 *   createdBy: 'user123',
 *   photos: [{ url: 'https://example.com/photo1.jpg', html_attribution: '' }]
 * }]);
 */
export const addPlaceToStorage = (places: IPlaceDetailsForStorage[]) => {
  return new Promise(async (resolve, reject) => {
    try {
      const placeEntries = places.map((place) => ({
        coordinates: place.coordinates,
        timezone: place.timezone,
        city: place.city,
        photos: place?.photos?.map((photo) => ({
          url: photo?.url ?? '',
          html_attribution: photo?.html_attribution ?? '',
        })),
        placeid: place.placeId,
        created_by: place.createdBy,
      }));

      const { data } = await supabase.from('placedetails').upsert(placeEntries);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const progressTrackerStep = [
  'Creating the Trip<br /> Visualization...',
  'Success',
  'Trip Creation Failed.',
  `Trip Creation Failed Twice. <br />Sorry! We’ll fix it ASAP.`,
];

/**
 * Formats a number as currency with the appropriate symbol.
 *
 * @param {number} amount - The amount to be formatted.
 * @param {Currencies} currency - The currency type (e.g., USD, GBP).
 * @returns {string} - The formatted currency string with symbol.
 */
export function formatCurrencyWithSymbol(
  amount: number,
  currency: Currencies,
): string {
  const formatter = new Intl.NumberFormat(getCurrencyLocale(currency), {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

/**
 * Returns the locale string based on the currency.
 *
 * @param {Currencies} currency - The currency type to determine the locale.
 * @returns {string} - The locale string for the provided currency.
 */
export function getCurrencyLocale(currency: Currencies): string {
  switch (currency) {
    case Currencies.GBP:
      return 'en-GB';
    default:
      return 'en-US'; // Fallback to US locale
  }
}

/**
 * Returns the ordinal suffix for a given day number.
 *
 * @param {number} dayNumber - The day number to convert to its ordinal representation (e.g., 1 -> 1st, 2 -> 2nd).
 * @returns {string} The day number with the appropriate ordinal suffix (e.g., "1st", "2nd", "3rd", "4th").
 *
 * @example
 * getOrdinal(1);  // "1st"
 * getOrdinal(2);  // "2nd"
 * getOrdinal(3);  // "3rd"
 * getOrdinal(4);  // "4th"
 * getOrdinal(11); // "11th"
 * getOrdinal(21); // "21st"
 * getOrdinal(102); // "102nd"
 */
export const getOrdinal = (dayNumber: number): string => {
  const remainder = dayNumber % 10;
  const suffix = ['th', 'st', 'nd', 'rd'];

  // Special cases for 11th, 12th, and 13th
  if (dayNumber % 100 >= 11 && dayNumber % 100 <= 13) {
    return `${dayNumber}th`;
  }

  return `${dayNumber}${suffix[remainder] || 'th'}`;
};
