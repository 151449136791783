import svg64 from 'svg64';

interface CustomIconProps {
  index: number;
  category: string;
}

const getIconColor = (category: string) => {
  let color = '#EE4444'; // Default color
  if (category === '✈️ Airport') {
    color = '#2B8AEE';
  } else if (category === '🏨 Hotel') {
    color = '#A87BF2';
  } else if (category === '🍽️ Restaurant') {
    color = '#FF901F';
  } else if (category === '🏠 Home') {
    color = '#39C0F9';
  } else if (category === '🎡 Point of Attraction') {
    color = '#ED6934';
  } else if (category === 'Other') {
    color = '#ED6934';
  }

  return color;
};

export const getCustomIcon = ({ index, category }: CustomIconProps) => {
  const iconColor = getIconColor(category);

  const icon = `
    <svg width="46" height="51" viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <style>
        .text-number {
          font-family: "Inter", sans-serif;
          font-size: 18px;
          fill: white;
          font-weight: 600;
          text-align:center;
        }
      </style>
      <g filter="url(#filter0_d_195_63)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23 2C12.5444 2 4 10.4759 4 20.9315C4 29.9815 10.4016 37.5483 18.903 39.4192L22.2227 44.5452C22.6168 45.1538 23.5073 45.1538 23.9014 44.5452L27.2421 39.3867C35.6712 37.462 42 29.9299 42 20.9315C42 10.4759 33.4556 2 23 2Z" fill="${iconColor}"/>
      <path d="M18.903 39.4192L19.7424 38.8756L19.5191 38.5309L19.118 38.4426L18.903 39.4192ZM22.2227 44.5452L21.3833 45.0888L21.3833 45.0888L22.2227 44.5452ZM23.9014 44.5452L24.7408 45.0888L24.7408 45.0888L23.9014 44.5452ZM27.2421 39.3867L27.0195 38.4117L26.6236 38.5022L26.4028 38.8431L27.2421 39.3867ZM5 20.9315C5 11.0329 13.092 3 23 3V1C11.9969 1 3 9.91893 3 20.9315H5ZM19.118 38.4426C11.061 36.6695 5 29.4978 5 20.9315H3C3 30.4651 9.74214 38.4271 18.6881 40.3958L19.118 38.4426ZM23.062 44.0017L19.7424 38.8756L18.0637 39.9628L21.3833 45.0888L23.062 44.0017ZM23.062 44.0016H23.062L21.3833 45.0888C22.1716 46.3059 23.9525 46.3059 24.7408 45.0888L23.062 44.0016ZM26.4028 38.8431L23.062 44.0017L24.7408 45.0888L28.0815 39.9302L26.4028 38.8431ZM41 20.9315C41 29.4489 35.008 36.5877 27.0195 38.4117L27.4648 40.3616C36.3344 38.3363 43 30.4108 43 20.9315H41ZM23 3C32.908 3 41 11.0329 41 20.9315H43C43 9.91893 34.0031 1 23 1V3Z" fill="white"/>
      </g>
      <g clip-path="url(#clip0_195_63)">
      <text class="text-number" x="50%" y="45%" text-anchor="middle" alignment-baseline="middle">${index}</text>
      </g>
      <defs>
      <filter id="filter0_d_195_63" x="0" y="0" width="46" height="51.0017" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_63"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_63" result="shape"/>
      </filter>
      <clipPath id="clip0_195_63">
      <rect width="24" height="24" fill="white" transform="translate(11 10)"/>
      </clipPath>
      </defs>
    </svg>`;
  return svg64(icon);
};
