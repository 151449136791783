import { useDispatch, useSelector } from 'react-redux';
import { LocationCity } from '~/components/ViewTravel/MobileDiscoverModal/LocationCity';
import {
  selectCostsCategories,
  selectStoryImages,
  selectTotalCosts,
  selectTravelCities,
  selectTravelData,
  selectTravelDays,
  selectTravelRawData,
} from '~/redux/selectors/tripInfoSelectors';
import { RootState } from '~/redux/store';
import './style.css';
import { useState } from 'react';

import ActionsCreator from '~/redux/actions';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';

export const TripOverview = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const tripData = useSelector(selectTravelData);
  const tripRawData = useSelector(selectTravelRawData);
  const travelDays = useSelector(selectTravelDays);
  const cities = useSelector(selectTravelCities);
  const daysCount = Object.keys(travelDays).length;
  const userName = useSelector(
    (state: RootState) => state.MapReducers.userName,
  );
  const storyImages = useSelector(selectStoryImages);
  const totalCosts = useSelector(selectTotalCosts);
  const costsCategories = useSelector(selectCostsCategories);
  const userAvatar = useSelector(
    (state: RootState) => state.MapReducers.profileImgURL,
  );

  const tripDesc = tripRawData.description || '';
  const tripTitle =
    tripRawData.title ||
    `${daysCount} Day${daysCount > 1 ? 's' : ''} in ${
      tripData[0].arrival.location?.city
    }`;

  const [isReadMore, setIsReadMore] = useState(true);

  return (
    <div className={`trip-overview-container ${isMobile ? '' : 'isDesktop'}`}>
      <div className="trip-overview-madeby">
        <div className="made-by-text">
          <h4>{tripTitle}</h4>
          <span className="trip-overview-text">{`Made by ${userName}`}</span>
        </div>
        <img src={userAvatar} alt="avatar" className="overview-avatar" />
      </div>
      <div className="city-costs">
        <div className="city-info">
          {cities.slice(0, 2).map((city) => (
            <LocationCity city={city} key={city} />
          ))}{' '}
          <span className="city-text">...</span>
        </div>
        {!!totalCosts && (
          <div className="trip-cost">
            <span className="cost-value">~{totalCosts}USD</span>/Person
          </div>
        )}
      </div>

      {/* Trip Images Preview */}
      <div className="trip-overview-images">
        {storyImages.slice(0, 5).map((image, index) => {
          const ext = image.url!.split('.').pop()?.toLocaleLowerCase();
          let content;
          if (ext === 'mp4') {
            content = (
              <div
                className="trip-overview-img"
                onClick={() =>
                  dispatch(ActionsCreator.setIsStoriesOpened(true))
                }
              >
                <video>
                  <source src={image.url} />
                </video>
              </div>
            );
          } else {
            content = (
              <img
                src={image.url}
                className="trip-overview-img"
                onClick={() =>
                  dispatch(ActionsCreator.setIsStoriesOpened(true))
                }
                alt="trip overview"
              />
            );
          }

          return (
            <div className="trip-images-wrapper" key={image.url}>
              {content}
              {index === 4 && storyImages.length > 5 && (
                <div
                  className="trip-images-overlay"
                  onClick={() =>
                    dispatch(ActionsCreator.setIsStoriesOpened(true))
                  }
                >
                  +{storyImages.length - 5}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Trip About Section */}
      {!!tripDesc && (
        <div className="trip-about">
          <h4>About Trip</h4>
          <p className="trip-overview-text">
            {isReadMore ? `${tripDesc.slice(0, 200)}...` : tripDesc}
            <span
              className="trip-overview-text orange"
              onClick={() => setIsReadMore(!isReadMore)}
              role="button"
            >
              {isReadMore ? ' Read more' : 'Read less'}
            </span>
          </p>
        </div>
      )}

      {!!totalCosts && (
        <div className="trip-estimate">
          <h4>Estimated Budget Breakdown</h4>
          {!!costsCategories.flight && (
            <p className="estimate-text">
              Flight ={' '}
              <span className="cost-value">{costsCategories.flight} USD</span>
            </p>
          )}
          {!!costsCategories.accomodation && (
            <p className="estimate-text">
              Accommodation (hotel or home) ={' '}
              <span className="cost-value">
                {costsCategories.accomodation} USD
              </span>
            </p>
          )}
          {!!costsCategories.food && (
            <p className="estimate-text">
              Food (restaurant) ={' '}
              <span className="cost-value">{costsCategories.food} USD</span>
            </p>
          )}
          {!!costsCategories.transportation && (
            <p className="estimate-text">
              Transport (transportation costs) ={' '}
              <span className="cost-value">
                {costsCategories.transportation || 0} USD
              </span>
            </p>
          )}
          {!!costsCategories.other && (
            <p className="estimate-text">
              Other (other) ={' '}
              <span className="cost-value">
                {costsCategories.other || 0} USD
              </span>
            </p>
          )}
          {!!costsCategories.activity && (
            <p className="estimate-text">
              Activity (point of attraction) ={' '}
              <span className="cost-value">{costsCategories.activity} USD</span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};
