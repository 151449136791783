// React Imports
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI Imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

// Redux Imports
import { useSelector } from '~/redux/reducers';
import { RootState, store, useDispatch } from '~redux/store';
import ActionsCreator from '~redux/actions';

// Supabase Imports
import {
  deleteTravelHistory,
  getTravelHistory,
} from '~/supabase/travelHistoryTracking';

// Utility Imports
import { TravelFormData, TravelFormHistoryData } from '~/utility/models';

// Child Component Imports
import HistoryCard from './HistoryCard';

// Style Imports
import useStyles from './styles';

/**
 * This component traverse the travelPoints array and render Travel History of a user on History Page
 * @returns {JSX.Element} History Page Component
 */
const HistoryPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);

  const [travelHistory, setTravelHistory] = useState<TravelFormHistoryData[]>(
    [],
  );

  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );

  /**
   * This useEffect is called when a component is rendered to fetch Travel History from DB
   *
   * @param {string} userID - The ID of the user for whom to fetch travel history.
   * @param {function} dispatch - The Redux dispatch function to update global state.
   */
  useEffect(() => {
    // Fetch Travel History Data from DB
    getTravelHistory(userID).then((result) => {
      if (result.error) {
        // Handle the error if needed
        console.error('Error adding to travel history:', result.error);
      } else {
        // Store Travel History in Local State in this component
        setTravelHistory(result.data as TravelFormHistoryData[]);

        // Disable Loader on after Data fetched from DB
        setLoading(false);

        // Store Travel History in Redux as Global State
        dispatch(
          ActionsCreator.saveTravelToHistory(
            result.data as TravelFormHistoryData[],
          ),
        );
      }
    });
  }, [dispatch, userID]);

  /**
   * useEffect to handle resizing of the grid container based on the main container's height.
   * This effect sets up an initial resize handling, attaches a resize event listener, and cleans up the event listener when the component unmounts.
   *
   * @param {boolean} loading - The loading state which controls the useEffect execution.
   */
  useEffect(() => {
    const handleResize = () => {
      if (mainContainerRef.current) {
        const mainContainerHeight = mainContainerRef.current.clientHeight;
        const desiredMaxHeight = `calc(87vh - ${mainContainerHeight}px - 20px)`; // Adjust the margin as needed
        if (gridContainerRef.current) {
          gridContainerRef.current.style.maxHeight = desiredMaxHeight;
        }
      }
    };

    // Initial setup
    handleResize();

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [loading]);

  useEffect(() => {
    return () => {
      mainContainerRef.current = null;
      gridContainerRef.current = null;
    };
  }, []);

  /**
   * Handle Open a Travel when opened from Travel History Page
   * @param {TravelFormData} travelHistoryItem - Travel History Item which includes id, uuid and travelPoints
   * @param {number} index - index of Travel History Item to be opened
   */
  const handleOpenTravel = (
    travelHistoryItem: TravelFormHistoryData,
    index: number,
  ) => {
    // Extract travelPoints to show on Map (StaticTravelVisualizer)
    const travelPoints = travelHistoryItem.travelPoints.map(
      (travelPoint: TravelFormData) => {
        return {
          arrival: {
            ...travelPoint.arrival,
            dateTime: travelPoint.arrival.dateTime,
          },
          departure: {
            ...travelPoint.departure,
            dateTime: travelPoint.departure.dateTime,
          },
          selectedTransport: travelPoint.selectedTransport,
          encodedPath: travelPoint.encodedPath,
          selectedTransportImages: travelPoint?.selectedTransportImages || [],
        };
      },
    );

    // Set State with Travel Points to show on Static Travel Visualizer
    dispatch(ActionsCreator.addTravelPoint(travelPoints));
    dispatch(ActionsCreator.setTravelHistoryIndex(index));
    dispatch(ActionsCreator.setTravelHistoryCurrentID(travelHistoryItem.id));
    dispatch(ActionsCreator.setPublishedTravelLink(''));
    dispatch(ActionsCreator.setTravelFormSaveState(true));

    ActionsCreator.getPulishedTravelId(travelHistoryItem.id);

    navigate('/homepage');
  };

  /**
   * Delete a Travel History Item of a User
   * @param {string} uuid - LoggedIn user id in Supabase named as uuid
   * @param {number} id - Unique ID of a Travel History data item
   */
  const handleDeleteTravel = (uuid: string, id: number) => {
    // Delete Travel History Item from DB
    deleteTravelHistory(uuid, id).then(() => {
      // Filtering Travel History
      const updatedTravelHistory = travelHistory.filter(
        (item) => item.id !== id,
      );

      // Updating Local State of Travel History
      setTravelHistory([...updatedTravelHistory]);

      // Updating Redux Global State of Travel History
      dispatch(ActionsCreator.saveTravelToHistory(updatedTravelHistory));
      const currentIndex = store.getState().MapReducers.travelHistoryTrackingID;

      if (currentIndex === id) {
        // Clear the relevant states
        dispatch(ActionsCreator.addTravelPoint([])); // Clear travel points
        dispatch(ActionsCreator.setTravelHistoryIndex(-1)); // Reset index or set to a default value
        dispatch(ActionsCreator.setTravelHistoryCurrentID(-1)); // Clear current ID
        dispatch(ActionsCreator.setPublishedTravelLink('')); // Reset published travel link
        dispatch(ActionsCreator.setTravelFormSaveState(false)); // Reset travel form save state
      }
    });
  };

  /// Check if the data is currently loading
  if (loading) {
    // If loading is true, return a CircularProgress component to indicate a loading state
    return (
      <CircularProgress
        size={40}
        thickness={4}
        style={{ position: 'absolute', top: '50%', left: '50%' }}
      />
    );
  }

  return (
    <Box className={classes.maincontainer}>
      <div className={classes.title} ref={mainContainerRef}>
        <Typography className={classes.title}>History</Typography>
        <Tabs className={classes.tabs} value={0} variant="fullWidth">
          <Tab className={classes.tab} label="Travel Itineraries" />
        </Tabs>
        <Divider variant="middle" sx={{ bgcolor: 'gray' }} />
        <Button
          style={{ fontFamily: 'Futura Md BT' }}
          className={classes.goBackButton}
          onClick={() => navigate('/home')}
        >
          {' '}
          Go Back
        </Button>
      </div>
      <Grid
        container
        ref={gridContainerRef}
        sx={{ overflow: 'auto', maxHeight: '100%', paddingBottom: '45px' }}
        spacing={0}
      >
        {!loading && travelHistory.length === 0 && (
          <Typography
            variant="h6"
            style={{ textAlign: 'center', width: '100%', marginTop: '20px' }}
          >
            No Travels Found in History
          </Typography>
        )}
        {!loading &&
          travelHistory.map((travelData, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
              <div style={{ margin: '10px' }}>
                <HistoryCard
                  travelHistoryItem={travelData}
                  handleOpenTravel={handleOpenTravel}
                  handleDeleteTravel={handleDeleteTravel}
                  index={index}
                />
              </div>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
export default HistoryPage;
