import { useSignals } from '@preact/signals-react/runtime';
import { useEffect, useState, useCallback } from 'react';
import { isFirstScreenSignal } from '~/components/P2PAI/MapPreview/BottomSheetp2p';
import { dataReadySignal } from '~/components/signals/itinerary/dataReadySignal';
import { itinerarySignal } from '~/components/signals/itinerary/itinerarySignal';
import NoDataSignal from '~/components/signals/itinerary/NoDataSignal';
import { addPauseSignal } from '~/components/signals/itinerary/pauseSignal';
import ActionsCreator from '~/redux/actions';
import { useDispatch } from '~/redux/store';
import {
  ICompletedPlace,
  IProcessedPlace,
  IRemovePoint,
  WebSocketMessage,
  WebSocketResponseMessage,
  WebsocketResponseMessageType,
} from '~/types/websocket';

export enum IStreamAction {
  start = 'start',
  pause = 'pause',
  resume = 'resume',
  cancel = 'cancel',
  regenerate = 'regenerate',
  nextData = 'nextData',
  nextDay = 'nextDay',
  titleDescription = 'titleDescription',
}

const maxAttempts = 10;
let isConnecting = false;

export const webSocketUnableToConnectError = 'WebSocket encountered an error.';

const initialWsState = {
  messages: [],
  error: null,
  paused: false,
  isCompleted: false,
  isDataUnavailable: false,
  loading: false, // Initialize loading as false
  attempts: 0,
};

export const useWebSocket = (url: string, id: string) => {
  useSignals();

  const [ws, setWs] = useState<WebSocket | null>(null);
  const [state, setState] = useState<{
    messages: IProcessedPlace[];
    error: string | null;
    paused: boolean;
    isCompleted: boolean;
    isDataUnavailable: boolean;
    loading: boolean;
    fullItinerary?: ICompletedPlace;
    attempts: number;
  }>(initialWsState);

  const [extra, setExtra] = useState<{
    title: string;
    description: string;
    quickTips: string;
  }>({
    title: '',
    description: '',
    quickTips: '',
  });

  const dispatch = useDispatch();

  const requestNextData = useCallback(
    (transportationIndex: number) => {
      if (ws) {
        console.log({ transportationIndex });

        setState((prev) => ({ ...prev, loading: true })); // Set loading to true when requesting data

        sendMessage({
          action: IStreamAction.nextData,
          payload: { id, transportationIndex },
        });
      } else {
        setState((prev) => ({ ...prev, error: 'WebSocket is not connected.' }));
      }
    },
    [ws, id],
  );

  const cleanUpWebsocket = useCallback(() => {}, []);

  const cleanUpForNextPlace = useCallback(() => {
    console.log({ full: state.fullItinerary, mess: state.messages });
    setState(initialWsState);
    setState((prev) => ({ ...prev, loading: true }));
    dataReadySignal.value = false;
  }, []);

  useEffect(() => {
    let reconnectTimeout: NodeJS.Timeout;

    const connectWebSocket = () => {
      const websocket = new WebSocket(url);

      websocket.onopen = () => {
        console.log('WebSocket connected');
        isConnecting = false;
        setWs(websocket);
        setState((prev) => ({ ...prev, error: null, attempts: 0 }));
      };

      websocket.onmessage = (event: MessageEvent) => {
        const data: WebSocketResponseMessage = JSON.parse(event.data);
        handleWebSocketMessage(data);
      };

      websocket.onclose = () => {
        console.log('WebSocket disconnected');
        isConnecting = false;
        setWs(null);
        attemptReconnection();
      };

      websocket.onerror = (err) => {
        console.error('WebSocket error', err);
        isConnecting = false;
        setState((prev) => ({ ...prev, error: webSocketUnableToConnectError }));
        websocket.close();
      };
    };

    const handleWebSocketMessage = (data: WebSocketResponseMessage) => {
      console.log({ data });

      if (data.status === WebsocketResponseMessageType.DataReady) {
        dispatch(ActionsCreator.setLoading(false));

        const itineraries = data.itineraries;
        ActionsCreator.setP2PItineraryData(itineraries);
        itinerarySignal.value = itineraries;

        dataReadySignal.value = true;
        setState((prev) => ({ ...prev, loading: false })); // Set loading to false when data is ready
      }

      if (data.status === WebsocketResponseMessageType.Paused) {
        addPauseSignal.value = true;
        setState((prev) => ({ ...prev, loading: false })); // Stop loading when paused
        ActionsCreator.setP2PItineraryData(data.itineraries);
        itinerarySignal.value = data.itineraries;
      }

      if (data.status === WebsocketResponseMessageType.Processing) {
        addPauseSignal.value = false;
        ActionsCreator.setIsP2PButtonVisible(false);
        isFirstScreenSignal.value = false;
      }

      if (data.status === WebsocketResponseMessageType.titleDescription) {
        setExtra({
          title: data.title,
          description: data.description,
          quickTips: data?.quickTips! ?? '',
        });

        return;
      }

      if (data.status === WebsocketResponseMessageType.Error) {
        setState((prev) => ({
          ...prev,
          error: data.error!,
          paused: false,
          loading: false, // Stop loading on error
        }));
        dispatch(ActionsCreator.setLoading(false));
        return;
      } else {
        console.log('Code Reached at point : ', data.status);
        if (
          data.status === WebsocketResponseMessageType.Processing &&
          !dataReadySignal.peek() &&
          !addPauseSignal.peek()
        ) {
          console.log('Code Reached at point 2 : ', data.status);
          setState((prev) => ({
            ...prev,
            messages: [...prev.messages, data.processed],
            error: '',
            loading: false, // Stop loading when message is processed
          }));
          return;
        }

        if (data.status === WebsocketResponseMessageType.Completed) {
          console.log('COMPLETED GENERATION');
          setState((prev) => ({
            ...prev,
            fullItinerary: data.completed,
            isCompleted: true,
            loading: false, // Stop loading when completed
          }));
          return;
        }

        if (data.status === WebsocketResponseMessageType.NoData) {
          console.log('No data available yet');
          NoDataSignal.value = true;
          return;
        }
      }
    };

    const attemptReconnection = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }

      if (isConnecting) {
        console.log(
          'Already attempting to reconnect or connected. No need to retry.',
        );
        return;
      }

      setState((prev) => {
        if (prev.attempts >= maxAttempts) {
          console.log(
            'Maximum reconnection attempts reached. Please try again later.',
          );
          return prev;
        }

        const nextAttempt = prev.attempts + 1;
        const jitter = Math.random() * 1000;
        const reconnectDelay = Math.min(10000, 1000 * nextAttempt + jitter);

        console.log(
          `Reconnecting in ${(reconnectDelay / 1000).toFixed(1)} seconds...`,
        );

        reconnectTimeout = setTimeout(() => {
          isConnecting = true;
          connectWebSocket();
        }, reconnectDelay);

        return { ...prev, attempts: nextAttempt };
      });
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      clearTimeout(reconnectTimeout);
    };
  }, [url, state.attempts, dispatch]);

  const sendMessage = useCallback(
    (message: WebSocketMessage) => {
      setState((prev) => ({
        ...prev,
        paused: false,
        loading: true,
        error: '',
      }));

      if (ws && ws.readyState === WebSocket.OPEN) {
        setState((prev) => ({ ...prev, error: '' }));
        ws.send(JSON.stringify(message));
      } else {
        setState((prev) => ({ ...prev, error: 'WebSocket is not connected.' }));
      }
    },
    [ws],
  );

  const pauseStream = useCallback(
    ({
      transportationIndex,
      placeIndex,
    }: {
      transportationIndex: number;
      placeIndex: number;
    }) => {
      transportationIndex = transportationIndex ? transportationIndex : 0;

      const currentPlaceIndex = state.messages.findIndex(
        (msg) => msg.placeIndex === placeIndex,
      );

      const remainingMessages = state.messages.slice(0, currentPlaceIndex + 1);

      dispatch(ActionsCreator.setLoading(false));
      setState((prev) => ({
        ...prev,
        paused: true,
        loading: false,
        messages: remainingMessages,
      }));

      sendMessage({
        action: IStreamAction.pause,
        payload: { id, transportationIndex: transportationIndex, placeIndex },
      });
    },
    [state.messages, dispatch, sendMessage, id],
  );

  const resumeStream = useCallback(() => {
    dispatch(ActionsCreator.setLoading(true));
    addPauseSignal.value = false;
    ActionsCreator.setIsP2PButtonVisible(false);
    setState((prev) => ({ ...prev, paused: false, loading: true }));
    sendMessage({ action: IStreamAction.resume, payload: { id } });
  }, [dispatch, sendMessage, id]);

  const regenerateStream = useCallback(
    ({ placeIndex, transportationIndex, reason }: IRemovePoint) => {
      dispatch(ActionsCreator.setLoading(true));
      addPauseSignal.value = false;
      ActionsCreator.setIsP2PButtonVisible(false);
      setState((prev) => ({ ...prev, paused: false, loading: true })); // Set loading true on regenerate

      const currentPlaceIndex = state.messages.findIndex(
        (msg) => msg.placeIndex === placeIndex,
      );
      const remainingMessages = state.messages.slice(0, currentPlaceIndex);
      setState((prev) => ({ ...prev, messages: remainingMessages }));

      sendMessage({
        action: IStreamAction.regenerate,
        payload: { id, placeIndex, transportationIndex, reason },
      });
    },
    [dispatch, sendMessage, state.messages, id],
  );

  const cancelStream = useCallback(() => {
    dispatch(ActionsCreator.setLoading(false));
    setState((prev) => ({ ...prev, paused: false, loading: false })); // Set loading false on cancel
    addPauseSignal.value = false;
    ActionsCreator.setIsP2PButtonVisible(false);
    dataReadySignal.value = false;
    sendMessage({ action: IStreamAction.cancel, payload: { id } });
    setState(initialWsState);
  }, [dispatch, sendMessage, id]);

  const getTitleAndDescription = () => {
    dispatch(ActionsCreator.setLoading(true));
    setState((prev) => ({ ...prev, paused: false, loading: true })); // Set loading false on cancel
    sendMessage({ action: IStreamAction.titleDescription, payload: { id } });
  };

  return {
    messages: state.messages,
    error: state.error,
    paused: state.paused,
    isDataUnavailable: state.isDataUnavailable,
    wsLoading: state.loading,
    pauseStream,
    resumeStream,
    cancelStream,
    sendMessage,
    ws,
    isCompleted: state.isCompleted,
    fullItinerary: state.fullItinerary,
    cleanUpWebsocket,
    regenerateStream,
    requestNextData,
    cleanUpForNextPlace,
    extra,
    getTitleAndDescription,
  };
};
