import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  SelectChangeEvent,
  Snackbar,
  Alert,
  Slide,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DisabledFormContainer from '../DisabledFormContainer';
import useStyles from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormData, Options } from '~/utility/models';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import tzlookup from 'tz-lookup';
import { Position } from '@turf/turf';
import {
  categories,
  maxDebounceDelay,
  debounceDelayInitial,
} from './constants';
import { setupGoogle, geocode } from './helpers';
import AutocompleteComponent from '~/components/AutoComplete';
import CategorySelect from '~/components/CategorySelect';
import {
  deleteFileByUrlFromStorage,
  isFileGreaterThan100MB,
  saveUserUploadedImageAndPlaceDetails,
  uploadFileToStorage,
  validateFileMimetype,
} from '~/utility/utils';
import { validImageTypes } from '../ImageContainer/constants';
import { store } from '~/redux/store';
import ImageContainer from '../ImageContainer';
import { TransitionProps } from '@mui/material/transitions';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Represents the props for the FormSection component.
 * @interface FormSectionProps
 * @property {FormData} formData - The form data for the current section.
 * @property {FormData} oppositeformData - The form data for the opposite section.
 * @property {Function} onFormChange - A function to handle changes in form fields.
 * @property {boolean} isDisabled - A flag indicating whether the form section is disabled.
 * @property {string} alertText - The text to display as an alert.
 * @property {string} type - The type of the form section ('departure' or 'arrival').
 */

interface FormSectionProps {
  formData: FormData;
  oppositeformData: FormData;
  onFormChange: (
    field: keyof FormData,
    value:
      | string
      | null
      | number
      | Date
      | Options
      | Dayjs
      | File[]
      | boolean
      | string[],
  ) => void;
  isDisabled: boolean;
  alertText: string;
  type: string;
  selectedDateTime?: Dayjs;
  setSelectedDateTime?: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
}

/**
 * Renders a form container for either departure or arrival point.
 * @param {Object} props - The props object.
 * @param {FormData} props.formData - The form data.
 * @param {FormData} props.oppositeformData - The form data for the opposite point (If current is Arrival Form then this data is for the Departure Form).
 * @param {Function} props.onFormChange - The function to handle form field changes.
 * @param {boolean} props.isDisabled - Flag to determine if the form is disabled.
 * @param {string} props.alertText - The text to display as an alert.
 * @param {string} props.type - The type of the form ('departure' or 'arrival').
 * @returns {JSX.Element} FormContainer component.
 */

const FormContainer = (props: FormSectionProps) => {
  const {
    onFormChange,
    formData,
    isDisabled,
    alertText,
    oppositeformData,
    selectedDateTime,
    setSelectedDateTime,
  } = props;

  const classes = useStyles();
  const [options, setOptions] = useState<object[]>([]);
  const tzArrival = useRef<string | null>('Asia/Karachi');
  const tzDeparture = useRef<string | null>('Asia/Karachi');
  const [autocompleteSelected, setAutocompleteSelected] = useState<
    boolean | null
  >(false);
  const [isOpen, setIsOpen] = useState(false);

  const [travelPointFileuploadLoader, setTravelPointFileuploadLoader] =
    useState<boolean>(false);

  const desktopScreen = useMediaQuery('(min-width:1440px)');
  const inputFieldSize = desktopScreen ? 'medium' : 'small';
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  //Render Title on the basis of the prop type
  const title = props.type === 'departure' ? 'Departure' : 'Arrival';

  // Declare a variable to hold the min date
  let minDate;

  // Declare a variable to hold the min date
  let maxDate;

  // State for input value and debounced input value
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  // Ref for tracking current debounce delay value
  const currentDebounceValueRef = useRef<number | null>(debounceDelayInitial);

  /**-------------------------------------USE EFFECTS----------------------------------------*/

  /**
   * Initializes Google Maps when the component mounts.
   * @returns {Promise<void>} A promise that resolves when Google Maps is initialized.
   */
  useEffect(() => {
    /**
     * Function to initialize Google Maps when the window loads.
     * @returns {Promise<void>} A promise that resolves when Google Maps is initialized.
     */
    async function initializeGoogleOnLoad(): Promise<void> {
      try {
        // Call setupGoogle to initialize Google Maps
        await setupGoogle();
      } catch (error) {
        console.error('Error initializing Google Maps:', error);
      }
    }

    initializeGoogleOnLoad();
  }, []);

  /**
   * Updates the autocompleteSelected flag based on the presence of text in the formData location.
   * This effect sets autocompleteSelected to true if formData.location.text is truthy, otherwise sets it to false.
   * @returns {void}
   */
  useEffect(() => {
    if (!formData.location?.text) {
      setAutocompleteSelected(false);
    } else {
      setAutocompleteSelected(true);
    }
  }, [formData.location?.text]);

  /**
   * Handles the debouncing of input value changes and triggers a search when the debounced input value changes.
   * This effect clears the previous timeout when the inputValue changes and sets a new timeout to update the debouncedInputValue.
   * @returns {void}
   */
  useEffect(() => {
    // Clear the previous timeout when inputValue changes

    if (inputValue !== debouncedInputValue) {
      setOptions([]);
      const timeoutId = setTimeout(() => {
        setDebouncedInputValue(inputValue);
        if (currentDebounceValueRef.current === 0) {
          currentDebounceValueRef.current = 1000;
        }
      }, currentDebounceValueRef.current as number);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [inputValue, debouncedInputValue]);

  /**
   * Handles the search operation based on the debounced input value.
   * This effect triggers a search operation when the debounced input value changes.
   * @param {string} debouncedInputValue - The debounced value of the input.
   * @returns {void}
   */
  useEffect(() => {
    const handleSearch = async (query: string) => {
      try {
        // Call the geocode function to retrieve newOptions based on the query
        const newOptions = await geocode(query);

        // Update the options state with the newOptions
        setOptions(newOptions);
      } catch (error) {
        // Log any errors that occur during the search operation
        console.error('Error:', error);
      }
    };

    // Check if there is a debounced input value
    if (debouncedInputValue) {
      // Trigger the search operation with the debounced input value
      handleSearch(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  /**
   * useEffect hook to handle clearing debounce values when the inputValue is empty.
   * @returns {void}
   */
  useEffect(() => {
    if (inputValue === '') {
      handleClearDebounce();
    }
  }, [inputValue]);

  useEffect(() => {
    if (formData.location === null) {
      handleClearDebounce();
    }
  }, [formData.location]);

  useEffect(() => {
    return () => {
      currentDebounceValueRef.current = null;
      tzDeparture.current = null;
      tzArrival.current = null;
    };
  }, []);

  /**-------------------------------------FUNCTIONS----------------------------------------*/

  /**
   * @function handleRemoveImage
   * Handles the removal of image that was added to the image array.
   * Updates the setImages array which contains all existing images by filtering out the image with the index
   * Removes the image from supabase storage
   * @param index - The index to remove
   * @returns {void}
   */
  const handleRemoveImage = async (index: number) => {
    const imagesLeft = formData.images.filter((_, i) => i !== index);
    onFormChange('images', imagesLeft);

    deleteFileByUrlFromStorage({
      bucketName: 'travel-images',
      userId: store.getState().MapReducers.userID, // Retrieve user ID from Redux store
      fileUrl: formData.images[index],
    });
  };

  /**
   * Handles the change of image(s) uploaded for the trip.
   * @param event - The event object representing the change event.
   * @returns void
   */

  const onFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const files = Array.from(event.target.files || []);

    if (files.length > 0) {
      // Filter files by allowed image types
      const filteredFiles = files.filter((file) => {
        // Check for invalid file type
        if (!validateFileMimetype({ file, validFileTypes: validImageTypes })) {
          setErrorMessage(
            'Invalid file type, allowed file types are jpeg and png.',
          );
          setSnackbarOpen(true);
          return false;
        }

        if (file.type.startsWith('video/')) {
          // Check for file size (max is 5MB)
          if (isFileGreaterThan100MB(file, 5)) {
            setErrorMessage('File size is too large, the max is 5MB');
            setSnackbarOpen(true);
            return false;
          }
        } else {
          // Check for file size (max is 100MB)
          if (isFileGreaterThan100MB(file)) {
            setErrorMessage('File size is too large, the max is 100MB');
            setSnackbarOpen(true);
            return false;
          }
        }

        return true;
      });

      if (filteredFiles.length > 0) {
        try {
          const maxFilesToAdd = 4 - formData.images.length; // Calculate max files to add
          const filesToAdd = filteredFiles.slice(0, maxFilesToAdd); // Limit to maxFilesToAdd

          console.log({
            maxFilesToAdd,
            filesToAdd,
          });

          if (filesToAdd.length > 0) {
            const uploadPromises = filesToAdd.map((file) => {
              if (file.type.startsWith('video/')) {
                return uploadFileToStorage({
                  bucketName: 'travel-videos',
                  userId: store.getState().MapReducers.userID,
                  file: file,
                });
              } else {
                return uploadFileToStorage({
                  bucketName: 'travel-images',
                  userId: store.getState().MapReducers.userID,
                  file: file,
                });
              }
            });

            setTravelPointFileuploadLoader(true);
            const uploadResults = await Promise.all(uploadPromises);
            const newImageUrls = formData.images.slice(); // Clone current images array

            console.log({ uploadResults });

            // Process each upload result
            uploadResults.forEach((result) => {
              if (result.success && result.fileUrl) {
                // Add the successfully uploaded file URL to the array
                newImageUrls.push(result.fileUrl.data.publicUrl);
              } else {
                console.error('Failed to upload file:', result.error);
                setErrorMessage(
                  'File upload to storage failed, please try again.',
                );
                setSnackbarOpen(true);
              }
            });

            // Ensure the new array doesn't exceed the maximum length
            onFormChange('images', newImageUrls.slice(0, 4));

            saveUserUploadedImageAndPlaceDetails({
              placeId: formData?.location?.placeId!,
              city: formData.location?.city!,
              coordinates: formData.location?.coordinates!,
              timezone: formData.timezone,
              createdBy: 'created_by_user',
              files: filesToAdd,
            });
          }
        } catch (error) {
          console.error('Error uploading files:', error);
          setErrorMessage(
            'An error occurred while uploading your file, please try again.',
          );
          setSnackbarOpen(true);
        } finally {
          setTravelPointFileuploadLoader(false);
        }
      }

      // Reset the file input value so the same file can be selected again
      event.target.value = '';
    }
  };

  /**
   * Handles the change of category for destination/arrival point.
   * @param event - The event object representing the change event.
   * @returns void
   */
  const handleCategoryChange = (event: SelectChangeEvent<string>): void => {
    const newCategory = event.target.value;
    onFormChange('category', newCategory);
  };

  /**
   * @function handleChangeAirport
   * Handles the change event when selecting an airport.
   * @param {React.SyntheticEvent<Element, Event>} event - The event object.
   * @param {Options} data - The selected airport data.
   * @returns {Promise<void>}
   */
  const handleChangeAirport = async (
    event: React.SyntheticEvent<Element, Event>,
    data: Options,
  ): Promise<void> => {
    // Increase debounce value
    if ((currentDebounceValueRef.current as number) <= maxDebounceDelay) {
      (currentDebounceValueRef.current as number) += 1000;
    }

    // Geocode the selected placeId
    const geocoder = new google.maps.Geocoder();
    const { results } = await geocoder.geocode({ placeId: data.placeId });

    // Extract country and city information from geocoded results
    const countryComponent = results[0].address_components.find((component) =>
      component.types.includes('country'),
    );
    const cityComponent = results[0].address_components.find(
      (component) =>
        component.types.includes('locality') ||
        component.types.includes('administrative_area_level_1'),
    );

    // Construct result object
    const result: Options = {
      value: data.value,
      label: data.label,
      text: data.text,
      code: countryComponent ? countryComponent.short_name : '',
      city: cityComponent ? cityComponent.long_name : '',
      country: countryComponent ? countryComponent.long_name : '',
      coordinates: [
        results[0].geometry.location.lng(),
        results[0].geometry.location.lat(),
      ],
      street: data.street,
      timezone: data.timezone,
      placeId: results[0].place_id,
    };

    // Extract coordinates and timezone from the result
    const coordinates = result.coordinates as Position;
    const timezone = tzlookup(coordinates[1], coordinates[0]);

    // Update timezone and location in the form
    if (props.type === 'departure') {
      tzDeparture.current = timezone;
    } else if (props.type === 'arrival') {
      tzArrival.current = timezone;
    }
    onFormChange('timezone', timezone);
    onFormChange('location', result);
  };

  /**
   * @function handleInputChange
   * Handles the input change event in the Autocomplete component.
   * Sets the input value if the event type is 'change'.
   * @param {React.ChangeEvent<{}>} e - The event object.
   * @param {string} newInputValue - The new input value.
   * @returns {void}
   */
  const handleInputChange = (
    e: React.ChangeEvent<{}>,
    newInputValue: string,
  ): void => {
    if (e && e.type && e.type === 'change') setInputValue(newInputValue);
  };

  /**
   * @function handleChangeDateTime
   * Handles the change event when selecting a date and time.
   * If the selected date is valid based on the type of form (arrival or departure), updates the form data.
   * If not valid, sets the default value.
   * @param {Dayjs} date - The selected date object.
   * @returns {void}
   */
  const handleChangeDateTime = (date: Dayjs | null): void => {
    if (date) {
      if (props.type === 'arrival') {
        if (date.isBefore(oppositeformData.dateTime)) {
          setDefaultValue();
        } else {
          let convertedDate = date.toISOString();
          onFormChange('dateTime', convertedDate);
        }
      }

      if (props.type === 'departure') {
        if (date.isAfter(oppositeformData.dateTime)) {
          setDefaultValue();
        } else {
          let convertedDate = date.toISOString();
          onFormChange('dateTime', convertedDate);
        }
      }
    }
  };

  /**
   * @function setDefaultValue
   * Sets the default value for the dateTime field in the form data.
   * Retrieves the dateTime value from oppositeformData, converts it to the timezone of the current form data, and updates the form data.
   * @returns {void}
   */
  const setDefaultValue = (): void => {
    const dateWithTimeZone = dayjs(oppositeformData.dateTime).tz(
      formData.timezone,
    );
    onFormChange('dateTime', dateWithTimeZone as Dayjs);
  };

  /**
   * @function handleClearDebounce
   * Clears the input value, debounced input value, and options.
   * @returns {void}
   */
  const handleClearDebounce = (): void => {
    setDebouncedInputValue('');
    setInputValue('');
    setOptions([]);
  };

  //These are required on every rerender so that we can set the existing date and time according to the timezone saved instead of current timezone according to the selected loaction
  // TODO: Convert into enum type
  if (props.type === 'departure') {
    maxDate = dayjs(oppositeformData.dateTime).tz(formData.timezone);
  } else if (props.type === 'arrival') {
    minDate = dayjs(oppositeformData.dateTime).tz(formData.timezone);
  }

  return (
    <div
      style={{
        // padding: '10px',
        paddingTop: '0',
      }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={() => setSnackbarOpen(false)} // Close the Snackbar on action
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position the Snackbar at bottom left
        TransitionComponent={Slide} // Use the Slide component for the transition
        TransitionProps={{ direction: 'right' } as TransitionProps} // Slide from right to left
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={() => setSnackbarOpen(false)}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {isDisabled ? (
        <DisabledFormContainer
          type={props.type}
          date={oppositeformData.dateTime}
          data={props.formData}
          alertText={alertText}
          handleChangeDateTime={handleChangeDateTime}
          setSelectedDateTime={setSelectedDateTime} //THIS STATE IS USED IN DISABLED TRAVEL FORM AND IS COMING FROM THE PARENT
          selectedDateTime={selectedDateTime}
        />
      ) : (
        <Grid
          container
          spacing={1}
          padding={0.5}
          className={classes.formContainer}
        >
          <Grid
            item
            xs={12}
            md={12}
            marginTop={1}
            marginBottom={1}
            padding={1}
            className={classes.formContainer}
          >
            <Typography className={classes.subHeading}>
              {title} Point
            </Typography>
            <AutocompleteComponent
              options={options}
              formData={formData}
              handleChangeAirport={handleChangeAirport}
              handleInputChange={handleInputChange}
              setInputValue={setInputValue}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              oppositeformData={oppositeformData}
              autocompleteSelected={autocompleteSelected as boolean}
              inputFieldSize={inputFieldSize}
              type={props.type}
              currentDebounceValueRef={currentDebounceValueRef}
            />
          </Grid>

          <Grid
            container
            spacing={1}
            padding={0.5}
            width={'100%'}
            style={{
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              xs={4}
              md={4}
              // marginTop={1}
              // marginBottom={1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                opacity: autocompleteSelected ? 1 : 0.2,
                transition: 'opacity 0.5s ease-in-out',
                width: '100%',
              }}
            >
              <CategorySelect
                categories={categories}
                value={formData.category}
                handleChange={handleCategoryChange}
                disabled={!autocompleteSelected}
              />
            </Grid>
            <Grid
              item
              xs={8}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                opacity: autocompleteSelected ? 1 : 0.2,
                transition: 'opacity 0.5s ease-in-out',
                width: '100%',
              }}
            >
              <Typography className={classes.subHeading}>
                {title} Date & Time{' '}
                <Typography
                  component="span"
                  sx={{
                    fontSize: {
                      xs: '10px !important',
                      md: '15px',
                    },
                  }}
                >
                  (Local time)
                </Typography>{' '}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: inputFieldSize,
                      placeholder: 'When?',
                      className: `${classes.selectBackground} ${classes.customOutlineInput} ${classes.outlinedInputStyles}`,
                      InputProps: {
                        className: classes.subHeading,
                        style: {
                          border: '1.5px solid #ECECED',
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="icons/clock.svg"
                              alt="calendar"
                              className={classes.clockImg}
                            />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  disabled={formData.location ? false : true}
                  defaultValue={dayjs(formData.dateTime).tz(formData.timezone)}
                  value={
                    formData.dateTime
                      ? dayjs(formData.dateTime).tz(formData.timezone)
                      : null
                  }
                  minDateTime={minDate as Dayjs}
                  maxDateTime={maxDate as Dayjs}
                  onChange={handleChangeDateTime}
                  timezone={formData.timezone}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* IMAGE COMPONENT */}
            <ImageContainer
              onFileChange={onFileChange}
              images={formData.images}
              isDisabled={!autocompleteSelected}
              isLoading={travelPointFileuploadLoader}
              type={props.type as 'departure' | 'arrival' | 'transport'}
              handleRemoveImage={handleRemoveImage}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default FormContainer;
