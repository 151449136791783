import { Marker } from '~/animationEngine/Marker';
import { TravelAnimation } from '../TravelAnimation';
import { State } from '../models';
import { Clock } from 'three';
import { MarkerInstances } from '~/utility/models';
import { distance, point, Position } from '@turf/turf';
import { clickedIndexSignal } from '~/components/ViewTravel/common';

/**
 * This class represents the AnimateOriginMarker state in a travel animation state machine.
 * It is responsible for setting up and animating the origin marker of the current travel segment.
 */
export class AnimateOriginMarker implements State {
  private stateMachine: TravelAnimation;
  private marker: Marker | undefined;
  private clock = new Clock();
  private markers: MarkerInstances | undefined;
  isPaused = false;

  /**
   * @constructor
   * @param stateMachine - A reference to the travel animation state machine.
   */
  constructor(stateMachine: TravelAnimation) {
    this.stateMachine = stateMachine;

    // Binding the method to `this` ensures that it retains the correct context when called as a callback or event handler.
    // This is important in JavaScript because the value of `this` depends on how a function is called.
    // Without binding, `this` might refer to the global object or `undefined` in strict mode, leading to errors.
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  /**
   * Callback function to be called when a travel segment animation finishes.
   * This function is  private, as it's used internally
   * by the animation state machine.
   *
   * @internal
   */
  private onAnimationEnd() {
    this.stateMachine.setState(this.stateMachine.states.originDelayState);
  }

  showNoTransportationPart() {
    let showNoTransportationParts = true;

    let currentPoint = this.stateMachine.getCurrentSegment();
    if (!currentPoint) return false;
    this.stateMachine.straightLineOrigin = currentPoint?.departure?.location
      ?.coordinates as Position;

    if (currentPoint.decodedPath.path.length > 0) {
      this.stateMachine.straightLineDestination =
        currentPoint.decodedPath.path[0];
    } else if (currentPoint.decodedPath.data.length > 0) {
      this.stateMachine.straightLineDestination =
        currentPoint.decodedPath.data[0].path[0];
    }

    const point1 = point(this.stateMachine.straightLineOrigin);
    const point2 = point(this.stateMachine.straightLineDestination);

    const distanceBetweenPoints = distance(point1, point2);

    if (distanceBetweenPoints > 0) {
      showNoTransportationParts = true;
    } else {
      showNoTransportationParts = false;
    }

    return showNoTransportationParts;
  }

  /**
   * This function is called when the state is entered.
   * It sets up the origin marker text and starts the animation for the selected transport type.
   * Once the animation is complete, it transitions the state machine to the AnimateTravelSegment state.
   */
  async onEnter() {
    const marker = this.stateMachine.getCurrentController()?.markers?.origin;

    const shouldShowMarker = Boolean(
      this.stateMachine.showMarker || clickedIndexSignal.peek(),
    );

    await marker?.setupText(true);

    marker?.startAnimation(
      this.stateMachine.getCurrentSegment().selectedTransport,
      this.onAnimationEnd,
    );
    this.marker = marker;
    this.markers = this.stateMachine.getCurrentController()?.markers;

    // this.stateMachine.showNoTransportationParts =
    //   this.showNoTransportationPart();
    this.stateMachine.showStraightLine = this.showNoTransportationPart();

    this.stateMachine.isNextStateDestination = false;
  }

  onUpdate() {
    this.stateMachine.map.repaint = true;
    const delta = this.clock.getDelta();
    for (const controller of this.stateMachine.animationControllers) {
      this.markers = controller?.markers;
      for (const markerInstance of Object.values(
        this.markers as MarkerInstances,
      )) {
        (markerInstance as Marker).update(delta);
      }
    }
  }

  /**
   * This function is called when the state is exited.
   */
  onExit() {
    // On State Exit
  }

  onPause() {
    this.isPaused = true;
    this.marker?.pause();
  }

  onPlay() {
    this.isPaused = false;
    this.stateMachine.map.repaint = true;
    this.marker?.play();
  }
}
