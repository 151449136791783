import React, { useEffect } from 'react';
import './style.css';
import { motion } from 'framer-motion';

const ModalComponent = ({
  children,
  onBackdropClick,
}: React.PropsWithChildren<{ onBackdropClick?: () => void }>) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, display: 'none' }}
        className="modal-component-container"
        onClick={() => {
          onBackdropClick?.();
        }}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="modal-component-view"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default ModalComponent;
