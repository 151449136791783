import { makeStyles } from '@mui/styles';
import MuiTheme from '~/styles/theme/MuiTheme';

export const useStyles = makeStyles((theme: typeof MuiTheme) => ({
  main: {
    maxWidth: '1280px',
    background: 'transparent',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    margin: '0 auto',
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  },

  textField: {
    '& .MuiOutlinedInput-root': {
      color: '#000',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
      '& fieldset': {
        borderColor: '#000',
      },
      '&:hover fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000',
      },
      '& .MuiInputBase-input': {
        color: '#000',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#000',
        opacity: 1,
      },
      '&.Mui-disabled': {
        '& .MuiInputBase-input': {
          color: '#9CA3AF',
        },
      },
    },
    '& .MuiFormHelperText-root': {
      color: '#de1616',
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    width: '100%', // Full width for small screens
    [theme.breakpoints.up('sm')]: {
      width: '60%', // 60% width for screens 769px and up
    },
    [theme.breakpoints.up('md')]: {
      width: '50%', // 50% width for larger screens (1024px and up)
    },
  },

  [theme.breakpoints.between(0, 768)]: {
    root: {
      padding: '0rem 1rem 0rem 1rem',
    },
  },
}));

export default useStyles;
