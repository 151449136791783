import maplibregl, {
  LngLatBounds,
  LngLatLike,
  Map,
  NavigationControl,
} from 'maplibre-gl';
import mapStyles from './utility/mapStyles';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import {
  Content3DLayer,
  PublishableTravelDataWithDecodedPath,
  TravelFormData,
  URLConfig,
} from '~/utility/models';
import { hdrTexture } from '~/utility/utils';
import { TravelAnimation } from '~/animationEngine/Travel/TravelAnimation';
import StaticTravelVisualizer from '~/animationEngine/StaticTravelVisualizer';
import { Position } from '@turf/turf';
import { selectedTravelDaySignal } from '~/components/ViewTravel/MobileFooter/DaysHeader/DaysHeader';
import moment, { Moment } from 'moment';
import { signal } from '@preact/signals-core';
// import FrameRateControl from './utility/frameControl';

export const isMapLoadedSignal = signal(false);

export function initializeMapLibreMap(
  map: React.MutableRefObject<Map>,
  mapContainer: React.MutableRefObject<HTMLDivElement>,
  mapStyleIndex: number,
  wrapper: React.MutableRefObject<CustomThreeJSWrapper>,
  content3DLayer: Content3DLayer,
  isViewTravel: boolean,
  isVideoPopupMap: boolean,
  fullscreenMode: boolean,
) {
  const lat = 45.92;
  const lng = 6.87;
  const isMobile = window.innerWidth <= 600;

  map.current = new Map({
    container: mapContainer.current as HTMLElement,
    attributionControl: false,
    style: (mapStyles[mapStyleIndex] as URLConfig).isURL
      ? ((mapStyles[mapStyleIndex] as URLConfig).URL as string)
      : (mapStyles[mapStyleIndex] as maplibregl.StyleSpecification),
    center: [lng, lat],
    maxPitch: 80,
    maxZoom: 18,
    minZoom: isMobile ? 0.25 : 1.25,
    interactive: isVideoPopupMap ? true : false,
    maplibreLogo: false,
  });
  map.current.cancelPendingTileRequestsWhileZooming = false;

  wrapper.current = new CustomThreeJSWrapper(
    map.current,
    map.current.getCanvas().getContext('webgl') as WebGLRenderingContext,
  );

  wrapper.current.setEnvironment(hdrTexture);

  return new Promise<void>((resolve) => {
    map.current.on('load', function () {
      if (!map.current?.getLayer('custom-threejs-layer')) {
        map.current?.addLayer(content3DLayer);
      }
      map.current?.resize();
    });

    // const frameControl = new FrameRateControl();
    // map.current.addControl(frameControl);

    if (!isViewTravel) {
      if (isVideoPopupMap && !fullscreenMode) {
        map.current.addControl(
          new NavigationControl({
            visualizePitch: true,
            showZoom: true,
            showCompass: false,
          }),
          'top-right',
        );
      }
    }
    resolve(); // Resolve the promise when the map has finished loading
  });
}

export const getMapStyle = (
  styles: (maplibregl.StyleSpecification | URLConfig)[],
  index: number,
) => {
  let style = (styles[index] as URLConfig).isURL
    ? (styles[index] as URLConfig).URL
    : (styles[index] as maplibregl.StyleSpecification);

  return style;
};

export async function initializeTravelAnimation(
  travelAnimation: React.MutableRefObject<TravelAnimation>,
  publishableAnimationTravelDataWithDecodedPath: PublishableTravelDataWithDecodedPath[],
  map: React.MutableRefObject<Map>,
  wrapper: React.MutableRefObject<CustomThreeJSWrapper>,
  onResourcesLoadingStateUpdate: (newLoadingState: boolean) => void,
  isViewTravel: boolean,
  handleAnimationEnd: () => void,
) {
  travelAnimation.current = new TravelAnimation(
    map.current!,
    wrapper.current!,
    onResourcesLoadingStateUpdate,
    isViewTravel,
    handleAnimationEnd,
  );

  await travelAnimation.current.setup(
    publishableAnimationTravelDataWithDecodedPath,
  );

  travelAnimation.current?.startAnimation();
}

export async function onConfigUpdateOfPublishableTravelData(
  isModelEnumUpdated: boolean,
  travelAnimation: React.MutableRefObject<TravelAnimation>,
  publishableAnimationTravelDataWithDecodedPath: PublishableTravelDataWithDecodedPath[],
) {
  if (travelAnimation.current) {
    await travelAnimation.current.reset(
      publishableAnimationTravelDataWithDecodedPath,
      isModelEnumUpdated,
    );

    travelAnimation.current?.startAnimation();
  }
}

function loadMyLayers(
  map: React.MutableRefObject<Map>,
  travelAnimation: React.MutableRefObject<TravelAnimation>,
  content3DLayer: Content3DLayer,
  travelVisualizer: React.MutableRefObject<StaticTravelVisualizer>,
  publishableAnimationTravelDataWithDecodedPath: PublishableTravelDataWithDecodedPath[],
  playPauseState: boolean,
  setLoading: (newLoadingState: boolean) => void,
  isVideoPopupMap?: boolean,
) {
  if (!map.current?.getLayer('custom-threejs-layer')) {
    map.current?.addLayer(content3DLayer as any);
  }
  if (travelAnimation.current) {
    travelAnimation.current.setPathLayerOnMapStyleChange();
  }

  // Redraw Layers when animation is paused and mapStyle changed
  if (travelVisualizer.current) {
    travelVisualizer.current.redrawTravelLineLayer();
  }

  setLoading(false);
}

export function toggleMapInteractivity(map: Map, enable: boolean) {
  if (enable) {
    map.dragPan.enable();
    map.scrollZoom.enable();
    map.boxZoom.enable();
    map.doubleClickZoom.enable();
    map.touchZoomRotate.enable();
  } else {
    map.dragPan.disable();
    map.scrollZoom.disable();
    map.boxZoom.disable();
    map.doubleClickZoom.disable();
    map.touchZoomRotate.disable();
  }
}

export function setStyle(
  map: React.MutableRefObject<Map>,
  mapStyleIndex: number,
  travelAnimation: React.MutableRefObject<TravelAnimation>,
  publishableAnimationTravelDataWithDecodedPath: PublishableTravelDataWithDecodedPath[],
  isVideoPopupMap: boolean,
  content3DLayer: Content3DLayer,
  playPauseState: boolean,
  travelVisualizer: React.MutableRefObject<StaticTravelVisualizer>,
  setLoading: (newLoadingState: boolean) => void,
) {
  if (!isVideoPopupMap) {
    setLoading(true);
  }

  map.current?.setStyle(getMapStyle(mapStyles, mapStyleIndex!), {
    diff: false,
  });

  map.current?.once('styledata', function () {
    const waiting = () => {
      if (!map.current?.isStyleLoaded()) {
        setTimeout(waiting, 50);
      } else {
        loadMyLayers(
          map,
          travelAnimation,
          content3DLayer,
          travelVisualizer,
          publishableAnimationTravelDataWithDecodedPath,
          playPauseState,
          setLoading,
          isVideoPopupMap,
        );
        if (
          !isVideoPopupMap &&
          publishableAnimationTravelDataWithDecodedPath.length > 0
        ) {
          onConfigUpdateOfPublishableTravelData(
            false,
            travelAnimation as React.MutableRefObject<TravelAnimation>,
            publishableAnimationTravelDataWithDecodedPath,
          );
        }
      }
    };
    waiting();
  });
}

export async function setStaticTravelVisualizer(
  publishableAnimationTravelDataWithDecodedPath: PublishableTravelDataWithDecodedPath[],
  isVideoPopupMap: boolean,
  travelVisualizer: React.MutableRefObject<StaticTravelVisualizer>,
  map: React.MutableRefObject<Map>,
  wrapper: React.MutableRefObject<CustomThreeJSWrapper>,
  handleMarkerClick?: (
    travelArray: TravelFormData[],
    index: number,
    currentMarker: string | null,
    wholeTravelArr?: PublishableTravelDataWithDecodedPath[],
    isDefaultMarker?: boolean,
  ) => void,
  date?: Moment,
) {
  if (publishableAnimationTravelDataWithDecodedPath.length > 0) {
    if (isVideoPopupMap && !travelVisualizer.current) {
      travelVisualizer.current = new StaticTravelVisualizer(
        map.current!,
        wrapper.current!,
      );
    }

    if (isVideoPopupMap) {
      // if (map.current?.isStyleLoaded()) {
      travelVisualizer.current?.visualizeTravel(
        publishableAnimationTravelDataWithDecodedPath,
        handleMarkerClick,
        date,
      );

      map.current?.once('styledata', () => {
        travelVisualizer.current?.visualizeTravel(
          publishableAnimationTravelDataWithDecodedPath,
          handleMarkerClick,
          date,
        );
        if (!isMapLoadedSignal.peek()) isMapLoadedSignal.value = true;
      });

      if (map.current) {
        calculateZoomToFitPath(
          selectedTravelDaySignal.value
            ? publishableAnimationTravelDataWithDecodedPath.filter((p) => {
                //@ts-ignore
                return (
                  moment(String(p.departure.dateTime))
                    .tz(p.departure.timezone)
                    .format('DD-MM-YYYY') ===
                  selectedTravelDaySignal.value?.format('DD-MM-YYYY')
                );
              })
            : publishableAnimationTravelDataWithDecodedPath,
          map,
        );
      }
    }
  } else {
    travelVisualizer.current?.clearTravel();
  }
}

export function calculateZoomToFitPath(
  travelData: PublishableTravelDataWithDecodedPath[],
  map: React.MutableRefObject<Map>,
) {
  // const isMobile = window.innerWidth <= 1025;
  let bounds;
  let allPoints: Position[] = [];

  const padding = window.innerWidth * 0.2;
  const paddingTop = window.innerHeight * 0.2;
  const paddingBottom = 350;

  const firstPointLngLat = travelData[0].departure.location
    ?.coordinates as LngLatLike;
  bounds = new LngLatBounds(
    firstPointLngLat as LngLatLike,
    firstPointLngLat as LngLatLike,
  );

  for (const travelSegment of travelData) {
    if (travelSegment.decodedPath.data.length > 0) {
      const paths = travelSegment.decodedPath.data;
      for (const path of paths) {
        allPoints.push(...path.path);
      }
    } else if (travelSegment.decodedPath.path.length > 0) {
      allPoints.push(...travelSegment.decodedPath.path);
    }
  }

  for (let index = 1; index < allPoints.length; index++) {
    const point = allPoints[index];
    bounds.extend(point as LngLatLike);
  }

  map.current?.fitBounds(bounds as LngLatBounds, {
    pitch: 0,
    padding: {
      top: paddingTop,
      bottom: paddingBottom,
      left: padding,
      right: padding,
    },
  });
}
