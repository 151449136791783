import { Box, Button, TextField } from "@mui/material"
import Modal from '@mui/material/Modal';
import { useEffect, useRef, useState } from "react"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { signal } from "@preact/signals-core";
import dayjs from "dayjs";
import { commonButtonsSx } from "./MapPreview/BottomSheetp2p";
import { useStyles } from "../TravelAssistant/DatePickerComponent/styles";
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import { useNavigate } from "react-router-dom";

export const selectedCitySignal = signal<string>('');

export const textFieldSx = {
  //    width: '100%' and add shadow around the input place holder should be bolder
  marginTop: '10px',
  boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)',
  outline: 'none',
  borderRadius: '10px',
  backgroundColor: 'white',
  border: 'none',
  '& .MuiInputBase-input': {
    fontWeight: 600,
    textAlign: 'center',

    fontSize: '20px',
    borderRadius: '10px',
    outline: 'none',
    border: 'none',
  },
  // placeholder
  '& .MuiInputBase-input::placeholder': {
    fontWeight: 600,
    textAlign: 'center',

    fontSize: '20px',
  },
  "& fieldset": { border: 'none' },
}

const WhereAndWhen = ({ setDateAndCity }: {
  setDateAndCity: ({ city, date, point }: { city: string, date: string, point: string }) => void
}) => {

  const [state, setState] = useState<'where' | 'when'>('where')
  const [tempCity, setTempCity] = useState<string>('')
  const [tempDate, setTempDate] = useState<Date>(new Date())
  const [tempPoint, setTempPoint] = useState<string>('')
  const [beginYourTripModalOpen, setBeginYourTripModalOpen] = useState<boolean>(false)

  const isMobile = useIsMobile();

  const setTempCityAction = (city: string) => {
    setTempCity(city)
    setState('when')
  }

  const setTempDateAction = (date: Date) => {
    setTempDate(date)
    setBeginYourTripModalOpen(true)
  }

  const submit = (tempPoint: string) => {

    setDateAndCity({ city: tempCity, date: tempDate.toISOString(), point: tempPoint })
  }

  const cancel = () => {
    // setTempCity('')
    setTempDate(new Date())
    setBeginYourTripModalOpen(false)
  }

  return (
    <>
      <CommonHeader setState={setState} />
      <div style={isMobile ? {} : { width: '30%', margin: '0 auto' }}>
        {
          state === 'where' ? <Where setCity={setTempCityAction} /> : <When setDate={setTempDateAction} />
        }
        <Modal
          open={beginYourTripModalOpen}
          onClose={() => { }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >
          <BeginYourTripCard data={{
            city: tempCity, date: tempDate.toISOString()
          }} setTempPoint={setTempPoint} submit={submit} cancel={cancel} />
        </Modal>
      </div>
    </>
  )
}

type Location = {
  lat: number;
  lng: number;
};

const BeginYourTripCard = ({ data, setTempPoint, submit, cancel }: { data: { city: string, date: any }, setTempPoint: any, submit: (tempoint: string) => void, cancel: () => void }) => {

  const [text, setText] = useState<any>(data.city + ' ' + 'Airport')
  const [searchResults, setSearchResults] = useState<Array<any>>([])
  const [edit, setEdit] = useState<boolean>(false)
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const isMobile = useIsMobile()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setSearchResults([])
      }
    }
    document.addEventListener("mousedown", handleClickOutside);



    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [])


  const handleEdit = () => {

    setEdit(true)
    inputRef.current?.focus()

  }

  return (
    <Box sx={{
      position: 'absolute',
      top: '32px',
      left: '24px',
      right: '24px',
      padding: '24px',
      background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
      borderRadius: '20px',
      boxShadow: '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      ...(!isMobile && { width: '400px', margin: '140px auto 0' }),
    }}>
      <h4><b>Begin Your Trip in</b></h4>
      <h5><b style={{ color: '#F9682E' }}>{data.city}</b><b> on </b><b style={{ color: '#F9682E' }}>{dayjs(data.date).format('MMM DD, YYYY')}</b></h5>

      <h4 className="my-3">
        <b style={{ whiteSpace: 'nowrap' }}>
          Start from this Point :
        </b>
      </h4>

      <div className="d-flex" >
        <TextField fullWidth
          ref={inputRef}
          inputProps={{
            style: {
              padding: '10px 0px 10px 10px',
              color: edit ? 'black' : 'gray'
            },
          }}
          sx={{ ...textFieldSx, boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)', }}
          // disabled={!edit}
          value={text}
          {...!edit ? { onClick: handleEdit } : { onChange: (e) => { setText(e.target.value) } }}
          placeholder="Enter Place Name" />

        <Button style={{
          textTransform: 'lowercase',
          fontWeight: 600,
          borderRadius: '18px',
          marginLeft: '15px',
          boxShadow: '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
          backgroundColor: edit ? '#F9682E' : '#FFFFFF',
          color: edit ? 'white' : '#F9682E',
        }} sx={textFieldSx}
          onClick={() => { setEdit(!edit); inputRef.current?.focus() }}
        >{edit ? 'done' : 'edit'}</Button>

        <div
          style={{
            position: 'absolute',
            left: '30px',
            right: '30px',
            marginTop: '75px',
            background: 'white',
            borderRadius: '18px',
            boxShadow: '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
            zIndex: 1000,
          }}
          ref={searchResultsRef}
        >


        </div>

      </div>

      {!edit ?
        <div className="d-flex flex-column justify-content-center align-items-center">

          <Button
            style={{
              background: '#F9682E',
              boxShadow: '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
              borderRadius: '18px',
              textTransform: 'none',
              fontWeight: 600,
              marginTop: '20px',
              color: 'white',
              width: '40%',
              padding: '16px 8px',
            }}
            onClick={() => {

              submit(text)
            }}
            disabled={!text || text.length < 1}
          >
            Yes
          </Button>
          <Button
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
              borderRadius: '18px',
              textTransform: 'none',
              fontWeight: 600,
              marginTop: '20px',
              color: '#F9682E',
              width: '40%',
              padding: '16px 8px',
            }}
            onClick={cancel}
          >
            No
          </Button>
        </div>
        :
        <h6 className="mt-3 " style={{ fontSize: '12px' }}>
          <b>
            TIP: Use your home address, nearest airport, or any other location as your starting point.
          </b>
        </h6>
      }

    </Box>
  )
}

const Where = ({ setCity }: { setCity: (city: string) => void }) => {

  const [city, setCityLocal] = useState<string>('')
  const [searchResults, setSearchResults] = useState<Array<any>>([])
  const isMobile = useIsMobile()

  return (
    <div className="d-flex justify-content-between align-items-center p-3  flex-column">
      <h3><b>Where to?</b></h3>
      <TextField fullWidth
        inputProps={{
          style: {
            padding: '10px 0px 10px 10px',
          }
        }}
        sx={textFieldSx}
        placeholder="Enter City Name" value={city} onChange={(e) => setCityLocal(e.target.value)} onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setCity(city);
          }
        }} />

      {city.length > 1 && <Button sx={commonButtonsSx} style={{ width: '162px' }} disabled={
        city.length < 1
      } onClick={() => { setCity(city) }}>Next</Button>}

    </div>
  )
}

const When = ({ setDate }: { setDate: (date: Date) => void }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <div className="d-flex justify-content-between align-items-center p-3 flex-column"
      style={{ ...(!isMobile && { marginTop: '60px' }) }}
    >
      <h3><b>When? (start date)</b></h3>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          dayOfWeekFormatter={(day, date: any) => new Intl.DateTimeFormat('en', { weekday: 'short' }).format(date)}
          sx={{
            '.MuiPickersDay-today': {
              outline: 'none',
              border: 'none',
            },
          }}
          className={`${classes.calendar} ${classes.weekdayLabel}`}
          slotProps={{
            leftArrowIcon: {
              style: { fill: '#F9682E' }
            },
            rightArrowIcon: {
              style: { fill: '#F9682E' }
            }
          }}
          onChange={(date) => { setDate(date) }}
          showDaysOutsideCurrentMonth

        // minDate={dayjs()}
        // maxDate={dayjs().add(7, 'day')}
        />
      </LocalizationProvider>

    </div>
  )
}

const CommonHeader = ({ setState }: { setState: any }) => {
  const navigate = useNavigate()
  return (
    <div
      className="d-flex justify-content-between align-items-center p-3"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 20px',
        height: 80,
        background: '#ffffff',
        maxWidth: 450,
        width: '100%',
        alignSelf: 'center',
        margin: '0 auto',
      }}
    >
      <div
        onClick={() => {
          setState((prev: string) => {
            if (prev === 'when') {
              return 'where'
            }
            navigate('/home');
            return prev
          })
        }}
        style={{
          pointerEvents: 'all',
          width: '33%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
          }}
        >
          <img src="./goBack.png" alt="Back Button" width={49.87} height={35} />
        </div>
      </div>
      <div
        onClick={() => {
          // navigate('/home');
        }}
        style={{ cursor: 'pointer', width: '33%' }}
      >
        <img
          src="./orangeLogoVizualTravel.svg"
          alt="Logo"
          width={123.56}
          height={26}
        />
      </div>
      {/*  */}
      <div style={{ display: 'flex', width: '33%' }} />
      {/* @Todo comment for now. Maybe we need in the future */}
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          className="meter"
          style={{
            width: '40px',
            height: '10px',
            marginTop: '0px',
            background: '#000',
          }}
        >
          <span style={{ width: `${25 * currentStep.peek()}%` }}></span>
        </div>
        <span
          style={{
            color: '#9CA3AF',
            marginLeft: '6px',
            fontWeight: '600',
            fontFamily: 'Poppins, Arial, sans-serif',
          }}
        >
          {`${currentStep.peek()} of 4`}
        </span>
      </div> */}
    </div>
  );
};

export default WhereAndWhen;

