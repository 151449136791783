export function isWebView() {
    const htmlEl = document.getElementsByTagName('html')[0];
    const bodyEl = document.getElementsByTagName('body')[0];

    const oldHtmlHeight = htmlEl.style.height;
    const oldBodyHeight = bodyEl.style.height;

    htmlEl.style.height = '100vh';
    bodyEl.style.height = '100%';

    const webViewMode =
      document.documentElement.clientHeight ===
      document.documentElement.scrollHeight;

    // restore height
    htmlEl.style.height = oldHtmlHeight;
    bodyEl.style.height = oldBodyHeight;

    return webViewMode;
  }