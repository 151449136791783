import { useSignalEffect } from '@preact/signals-react/runtime';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  animationStateSignal,
  canMultiTransportDeclareArrival,
  destinationStateSignal,
  endStateSignal,
  lastDepartureDateSignal,
  loadStateProvokeSignal,
} from '../common';
import { PublishableTravelData } from '~/utility/models';
import moment from 'moment-timezone';
import { uniq } from 'lodash';
import { vizualizeButtonSignal } from '../MobileFooter/DaysHeader/DaysHeader';
import { isCalendarAnimating } from '../DesktopFooter/CalendarLine';
import { resetTrip } from '../MobileFooter/BottomSheet';
import { restartDaySignal } from '~/map/ViewTravel';

type HeaderState =
  | ''
  | 'depart'
  | 'showDay'
  | 'arrival'
  | 'flyfor'
  | 'stayPoint'
  | 'idle'
  | 'firstPoint';

const useTitleState = (travelPoints: PublishableTravelData[]) => {
  const [animationState, setAnimationState] = useState('');
  const [animationStep, setAnimationStep] = useState(0);
  const [isMultiTransportDeclareArrival, setIsMultiTransportDeclareArrival] =
    useState(false);
  const [destination, setDestination] = useState<{
    label: string;
    duration?: string;
  }>();
  const [loadState, setLoadState] = useState<Date | null>(null);
  const [lastDeparture, setLastDeparture] = useState<Date | null>(null);
  const firstDayPoint = useRef(true);
  const currentStep = useRef(-1);
  const prevAnimationStep = useRef('');
  const [day, setDay] = useState<number>(1);

  const [headerState, setHeaderState] = useState<HeaderState>('showDay');
  const prevState = useRef<HeaderState>();
  const firstDepartForMulti = useRef(true);

  const canReset = useRef(false);

  useSignalEffect(() => {
    if (resetTrip.value || endStateSignal.value || restartDaySignal.value) {
    }
    if (canReset.current) {
      setAnimationState('');
      setAnimationStep(0);
      setIsMultiTransportDeclareArrival(false);
      setDestination(undefined);
      setLoadState(null);
      setDay(1);
      setLastDeparture(null);
      firstDayPoint.current = true;
      currentStep.current = -1;
      prevAnimationStep.current = '';
      setHeaderState('showDay');
      prevState.current = undefined;
      firstDepartForMulti.current = true;
      canReset.current = false;
    }
  });

  const handleSetHeaderState = (state: HeaderState) => {
    if (prevState.current !== state) {
      prevState.current = state;
      setHeaderState(state);
    }
  };

  const handleSetAnimationState = (state: string) => {
    if (prevAnimationStep.current !== state) {
      prevAnimationStep.current = state;
      setAnimationState(state);
    }
  };

  const days = useMemo(
    () =>
      uniq(
        travelPoints.map((i) =>
          moment(String(i.departure.dateTime)).format('DD-MM-YYYY'),
        ),
      ),
    [travelPoints],
  );

  useSignalEffect(() => {
    vizualizeButtonSignal.value?.day
      ? setDay(vizualizeButtonSignal.value?.day)
      : setDay(1);
  });

  useSignalEffect(() => {
    if (!canReset.current) canReset.current = true;
    handleSetAnimationState(animationStateSignal.value.state);
    animationStateSignal.value.calendarStep !== undefined &&
      setAnimationStep(animationStateSignal.value.calendarStep);
    if (animationStateSignal.value.calendarStep === -1) {
      firstDayPoint.current = true;
    }
  });

  useSignalEffect(() => {
    setDestination(destinationStateSignal.value);
  });

  useSignalEffect(() => {
    setLoadState(loadStateProvokeSignal.value);
  });

  useSignalEffect(() => {
    setLastDeparture(lastDepartureDateSignal.value);
    if (!lastDepartureDateSignal.value) {
      firstDayPoint.current = true;
    }
  });

  useEffect(() => {
    // headerState !== 'stayPoint' && setStep(animationStep);
    if (headerState === 'depart' && currentStep.current !== animationStep) {
      setDestination(undefined);
      setLoadState(null);
      currentStep.current = animationStep;
    }
  }, [headerState, animationStep]);

  useSignalEffect(() => {
    setIsMultiTransportDeclareArrival(canMultiTransportDeclareArrival.value);
  });

  const currentTransportationType =
    travelPoints[animationStep]?.selectedTransport;

  useEffect(() => {
    if (animationState === 'idle' && !lastDeparture) {
      handleSetHeaderState('showDay');
    } else if (animationState === 'idle' && firstDayPoint.current) {
      handleSetHeaderState('firstPoint');
    } else if (animationState === 'flyfor') {
      firstDayPoint.current = false;
      setTimeout(() => handleSetHeaderState(animationState), 600);
    } else if (
      animationState === 'depart' &&
      destination &&
      animationStep !== 0
    ) {
      handleSetHeaderState('stayPoint');
    } else if (animationState === 'arrival' && destination && !loadState) {
      handleSetHeaderState('stayPoint');
    } else if (animationState === 'arrival' && loadState) {
      setDay(
        days.indexOf(
          moment(String(loadState))
            .tz(travelPoints[animationStep].arrival.timezone)
            .format('DD-MM-YYYY'),
        ) + 1,
      );
      handleSetHeaderState('showDay');
      setTimeout(() => (isCalendarAnimating.value = false), 1200);
    } else {
      if (currentTransportationType === 'Transit') {
        if (animationState === 'arrival' && isMultiTransportDeclareArrival) {
          firstDepartForMulti.current = true;
          if (loadState) {
            setDay(
              days.indexOf(
                moment(String(loadState))
                  .tz(travelPoints[animationStep].arrival.timezone)
                  .format('DD-MM-YYYY'),
              ) + 1,
            );
            handleSetHeaderState('showDay');
          } else {
            handleSetHeaderState(animationState as any);
          }
        } else if (animationState === 'depart' && firstDepartForMulti.current) {
          firstDepartForMulti.current = false;
          handleSetHeaderState(animationState as any);
        }
      } else {
        handleSetHeaderState(animationState as any);
      }
    }
  }, [
    days,
    loadState,
    animationState,
    destination,
    animationStep,
    firstDayPoint,
    lastDeparture,
    currentTransportationType,
    isMultiTransportDeclareArrival,
  ]);

  return {
    headerState,
    step: animationStep < 0 ? 0 : animationStep,
    day,
    setHeaderState,
    duration: destination?.duration,
  };
};

export default useTitleState;
