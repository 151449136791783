import { ArrowBack } from '@mui/icons-material';
import { Button, Icon, TextField } from '@mui/material';
import { Fragment, useState } from 'react';
import {
  bottomSheetp2pStateSignal,
  resetAutoYesProgress,
} from './BottomSheetp2p';
import { dayCounterSignal } from '~/components/Itinerary';
import { previewMapSignal } from '.';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import './GlobalCssDrawer.css'

const commonSx = {
  boxShadow:
    '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933, 0px 1.5px 1.5px 0px #FFFFFF40 inset',
  borderRadius: '18px',
  backgroundColor: 'white',
  color: '#FE7138',
  padding: '8px 16px',
  fontSize: '16px',
  height: '64px',
  textTransform: 'none',
  fontWeight: 700,
};

const NoResponseP2P = ({
  handleClose,
}: {
  handleClose: (reason: string) => void;
}) => {
  const [state, setState] = useState<'initial' | 'other'>('initial');
  const [customReason, setCustomReason] = useState(''); // State for custom reason
  const isMobile = useIsMobile();

  return (
    <div
      onClick={(e) => { e.stopPropagation() }}
      style={{
        background: 'linear-gradient(180deg, #EEF3F7 0%, #FFFFFF 100%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        borderRadius: '18px',
        boxShadow:
          '0px 1.3px 2.21px 0px #07081705 , 0px 3.13px 5.32px 0px #07081707 , 0px 5.89px 10.02px 0px #07081709 , 0px 10.5px 17.87px 0px #0708170B , 0px 19.64px 33.42px 0px #0708170D , 0px 47px 80px 0px #07081712',
        width: isMobile ? '100%' : '40%',
        margin: '0 auto',
      }}
    >
      {state == 'initial' ? (
        <Fragment>
          <h3 className="mx-2 my-3">
            <b>What is wrong with this point ?</b>
          </h3>

          <div className="d-flex flex-column">



            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose("This place is not a good recommendation. Find me another place that, without a question, you think I would love.");
              }}
            >
              Not Interested
            </Button>


            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose('This place is too expensive. Find me a place that is more affordable.');
              }}
            >
              Too Expensive
            </Button>

            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose("This place is too far. Find me a place that is closer.");
              }}
            >
              Too Far
            </Button>

            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose("I want to go eat somewhere. Recommend me a Restaurant right now. Preferably walking distance. Try to keep it in less than 5 minute walking distance if possible.");
              }}
            >
              Food Recommendation
            </Button>

            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose("I want to do an activity. Recommend an Activity right now. Preferably walking distance but if it's really a good one, I don't mind going a bit further as long as it makes sense.");
              }}
            >
              Activity Recommendation
            </Button>

            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose("I need to go back to my hotel to get something. Please make the arrival point for this transportation index to be my hotel.");
              }}
            >
              Bring me to my Hotel
            </Button>

            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                handleClose("This place is already in my itinerary, make sure you don't recommend places that are already in my previous days itinerary. Give me a new place you haven't recommended yet.");
              }}
            >
              Already in my Itinerary
            </Button>



            <Button
              sx={commonSx}
              style={{ marginBottom: '16px' }}
              onClick={() => {
                setState('other');
              }}
            >
              Other
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="d-flex justify-content-between align-items-center w-100 pe-5">
            <Button
              variant="text"
              startIcon={<ArrowBack />}
              onClick={() => setState('initial')}
            ></Button>
            <div className="flex-1"></div>
            <h3 className="mx-2 my-3">
              <b>Other</b>
            </h3>
            <div className="flex-1"></div>
          </div>
          <TextField
            // 5 lines
            multiline
            rows={5}
            fullWidth
            variant="outlined"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            placeholder="e.g. I rather see a Museum"
            inputProps={{
              style: {
                textAlign: 'center',
                verticalAlign: 'middle',
                fontWeight: 700,
                fontSize: '16px',
              },
            }}
            style={{
              borderRadius: '18px',
              backgroundColor: 'white',
              marginBottom: '16px',
            }}
          />

          <Button
            sx={{ ...commonSx, color: 'white', backgroundColor: '#FE7138' }}
            onClick={() => {
              if (customReason.trim()) {
                setState('initial');
                setCustomReason('');

                handleClose(customReason);
              } else {
                alert('Please enter a reason');
              }
            }}
          >
            Regenerate
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default NoResponseP2P;
