import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { fetchAndStoreUserID } from '~/utility/utils';

const useIsAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const dispatch = useDispatch();

  const userID: string = useSelector(
    (state: RootState) => state.MapReducers.userID,
  );

  useEffect(() => {
    const checkAuthentication = async () => {
      const userId = await fetchAndStoreUserID(dispatch);
      setIsAuthenticated(!!userId); // Update state based on whether the user is authenticated
    };

    if (!userID) {
      checkAuthentication();
    } else {
      setIsAuthenticated(!!userID);
    }
  }, [userID]);

  return isAuthenticated;
};

export default useIsAuth;
