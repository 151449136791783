import React, { useEffect, useState } from 'react';
import { signal, effect } from '@preact/signals-core';
import useStyles from './styles';
import {
  animationIsPlaying,
  isCalendarAnimatingPromise,
  PauseAnimation,
  PlayAnimation,
  showMainHeader,
} from '../ViewTravel/common';
import { motion, MotionValue, useTransform } from 'framer-motion';
import { isOverlayVisible } from '../ViewTravel/StatsOverlay';
import { useIsMobile } from '../ViewTravel/counter/hooks/useMobile';
import { bottomSheetOverviewData } from '../ViewTravel/MobileFooter/BottomSheet';

interface ButtonProps {
  animatedY?: MotionValue<number>;
  playPauseState: boolean; // Assume playPauseState is passed as a prop
  bottom?: number; // Bottom position in pixels
  left?: number | string; // Left position in pixels
  type?: string;
}

const PlayPauseButton: React.FC<ButtonProps> = ({
  playPauseState,
  bottom,
  left,
  type,
  animatedY,
}) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(animationIsPlaying.value); // Initialize state from signal
  const isMobile = useIsMobile();
  const animatedLeft = useTransform(
    //@ts-ignore
    animatedY,
    [window.innerHeight - 150, window.innerHeight],
    [10, (window.innerWidth * (isMobile ? 1 : 0.7)) / 2 - 104 / 2],
  );
  const animatedSize = useTransform(
    //@ts-ignore
    animatedY,
    [window.innerHeight - 150, window.innerHeight],
    [52, 104],
  );

  const animatedIconWidth = useTransform(
    //@ts-ignore
    animatedY,
    [window.innerHeight - 150, window.innerHeight],
    [11, 24],
  );

  const animatedIconHeight = useTransform(
    //@ts-ignore
    animatedY,
    [window.innerHeight - 150, window.innerHeight],
    [14, 30],
  );

  const handlePlayPause = () => {
    if (isPlaying) {
      PauseAnimation();
      isOverlayVisible.value = true;
      showMainHeader.value = true;
    } else {
      PlayAnimation();
      PlayAnimation();
      isOverlayVisible.value = false;
      showMainHeader.value = false;
    }
  };

  // Effect to update the component state when the signal changes
  useEffect(() => {
    const unsubscribe = effect(() => {
      setIsPlaying(animationIsPlaying.value); // Update state when signal changes
    });
    return () => unsubscribe(); // Cleanup effect subscription
  }, []);

  useEffect(() => {
    const handleBlur = () => {
      if (isMobile) {
        PauseAnimation();
        isOverlayVisible.value = true;
      }
    };
    const handleFocus = () => {
      // You can add logic for focus here if needed
    };
    const handleVisibilityChange = async () => {
      await isCalendarAnimatingPromise();
      if (document.visibilityState === 'hidden' && isMobile) {
        PauseAnimation();
        setIsPlaying(false);
      }
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // console.log(isPlaying, 'WHY THIS');

  return (
    <motion.div
      className={`${classes.button} 'lolo' ${
        type === 'desktop' ? classes.desktopButton : classes.mobileButton
      }`}
      style={{
        bottom: `${bottom}px`,
        // left: animatedLeft,
        left: isMobile && bottomSheetOverviewData.value ? 10 : '50%',
        transform:
          isMobile && bottomSheetOverviewData.value
            ? 'none'
            : 'translateX(-50%)',
        height: animatedSize,
        width: animatedSize,
      }}
      onClick={(e) => {
        e.stopPropagation();
        handlePlayPause();
      }}
    >
      <motion.img
        src={isPlaying ? './icons/pause.svg' : './icons/play.svg'}
        alt={isPlaying ? 'Pause Animation' : 'Play Animation'}
        height={type === 'desktop' ? 36 : 14}
        width={type === 'desktop' ? 28 : 11}
        style={
          type === 'mobile'
            ? {
                width: animatedIconWidth,
                height: animatedIconHeight,
                pointerEvents: 'fill',
              }
            : undefined
        }
      />
    </motion.div>
  );
};

export default PlayPauseButton;
