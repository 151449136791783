import { point, Position, distance, BBox } from '@turf/turf';
import {
  BoxGeometry,
  CatmullRomCurve3,
  Color,
  Mesh,
  MeshBasicMaterial,
  Vector2,
  Vector3,
} from 'three';
import { MeshLineGeometry, MeshLineMaterial } from '../MeshLine';
import { Easing } from './easing';
import { CustomEase } from 'gsap/all';
import { LngLatBounds, LngLatBoundsLike, Map } from 'maplibre-gl';
import {
  projectToWorld,
  unprojectFromWorld,
} from '~/CustomThreeJsWrapper/utility/utils';
import CustomThreeJSWrapper from '~/CustomThreeJsWrapper/CustomThreeJsWrapper';
import gsap from 'gsap';
import { PreComputedIntervals } from '~/utility/models';

gsap.registerPlugin(CustomEase);

/**
 * Generates an array of 2D line data points between an origin and destination.
 * This function is useful for creating line geometries in maps or visualizations.
 *
 * @param origin An array representing a geographical position in longitude, latitude format (e.g., [longitude, latitude])
 * @param destination An array representing a geographical position in longitude, latitude format (e.g., [longitude, latitude])
 * @param numPoints The desired number of points to generate along the line
 * @returns An array of positions, where each position is an array of longitude and latitude values (e.g., [[lon1, lat1], [lon2, lat2], ...])
 */
export function generate2DLineData(
  origin: Position,
  destination: Position,
  numPoints: number,
): Position[] {
  const points: Position[] = [];

  // Extract the latitude and longitude of the origin and destination
  const lat1 = origin[1];
  const lon1 = origin[0];
  const lat2 = destination[1];
  const lon2 = destination[0];

  // Calculate the differences between destination and origin
  const latDiff = (lat2 - lat1) / numPoints;
  const lonDiff = (lon2 - lon1) / numPoints;

  // Calculate and store the points
  for (let i = 0; i <= numPoints; i++) {
    const lat = lat1 + i * latDiff;
    const lon = lon1 + i * lonDiff;
    points.push([lon, lat]);
  }

  return points;
}

export const maxSpeed = 1; // units for seconds
export const acceleration = 1; // 0.5 seconds to reach max speed (1 / 2)
export const setupPathSegments = (
  path: Position[],
): {
  time: number;
  distance: number;
  intervals: PreComputedIntervals[];
} => {
  const _stopAngle = Math.PI; // min turning angle when the speed will be set to 0
  const _skipTolerance = 0.8; // skip step if distance is too short (to avoid shaking)

  const computedPath: PreComputedIntervals[] = [];

  // path = travelSegment.decodedPath.path;
  const position = new Vector3(0, 1, 0);
  position.copy(projectToWorld(path[0]));
  let lastPosition = position.clone();
  let lastDirection = new Vector3();
  let speed = 0;

  let totalTime = 0;
  let totalDistance = 0;

  for (let index = 0; index < path.length; index++) {
    const point = path[index];
    const position = projectToWorld(point);

    const distance = lastPosition.distanceTo(position);
    if (distance < _skipTolerance && !(index < 1 || index >= path.length - 1))
      continue; // SKIP to avoid model shaking

    const direction = position.clone().sub(lastPosition).normalize();
    const angle = lastDirection.angleTo(direction);

    speed = speed * (1 - Math.min(1, angle / _stopAngle)); // decelerate

    const diffSpeed = maxSpeed - speed;
    const timeToMaxSpeed = diffSpeed / acceleration;
    const distanceToMaxSpeed =
      timeToMaxSpeed * speed + 0.5 * acceleration * timeToMaxSpeed ** 2;

    const timeAcc =
      (-speed +
        Math.sqrt(
          speed ** 2 -
            2 * acceleration * -Math.min(distanceToMaxSpeed, distance),
        )) /
      acceleration;
    const timeMaxSpeed = Math.max(0, distance - distanceToMaxSpeed) / maxSpeed;
    const time = timeMaxSpeed + timeAcc;

    totalTime += time;
    totalDistance += distance;

    computedPath.push({
      direction,
      time,
      timeToMaxSpeed,
      distance,
      start: lastPosition,
      end: position,
      startSpeed: speed,
    });

    speed = Math.min(maxSpeed, speed + time * acceleration); // acelerate

    lastPosition = position;
    lastDirection = direction;
  }

  return {
    time: totalTime,
    distance: totalDistance,
    intervals: computedPath,
  };
};

/**
 * Calculates the distance between two geographic coordinates in kilometers.
 *
 * This function uses the Haversine formula to calculate the distance between
 * two points on a sphere (the Earth). It assumes the Earth is a perfect sphere
 * with a radius of 6371 kilometers.
 *
 * @param coord1 The first geographic coordinate as a `Position` tuple (latitude, longitude)
 * @param coord2 The second geographic coordinate as a `Position` tuple (latitude, longitude)
 * @returns The distance between the two coordinates in kilometers (number)
 */
export function calculateDistanceInKilometers(
  coord1: Position,
  coord2: Position,
): number {
  // Destructure latitude and longitude from coordinates
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  const earthRadiusKm = 6371; // Earth's radius in kilometers

  // Convert degrees to radians
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  // Haversine formula intermediate calculations
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate distance in kilometers
  const distance = earthRadiusKm * c;

  return distance;
}

/**
 * Calculates the number of points required between two geographic coordinates
 * to achieve a desired point density along the path. This function is useful
 * for generating evenly spaced points along a travel route for visualization purposes.
 *
 * @param origin The starting geographic coordinates as a `Position` object.
 * @param destination The ending geographic coordinates as a `Position` object.
 * @param pointDensityInMeters The desired distance (in meters) between each point.
 * @returns The calculated number of points (integer) required to achieve the specified density.
 */
export function calculatePointsBetweenCoordinates(
  origin: Position,
  destination: Position,
  pointDensityInMeters: number,
) {
  const distanceInKilometers = calculateDistanceInKilometers(
    origin,
    destination,
  );
  const numPoints = Math.ceil(
    (distanceInKilometers * 1000) / pointDensityInMeters,
  ); // Convert distance to meters

  return numPoints; // Round up to the nearest integer
}

// 3DLine Functions

/**
 * Generates 3D line data for a travel path on a map
 *
 * This function takes an array of coordinates, the selected mode of transportation
 * (car or other), and a reference to a Three.js wrapper class as input. It then
 * processes the coordinates and generates an array of 3D vector points suitable
 * for creating a line geometry in Three.js.
 *
 * @param coordinates An array of geographic coordinates (longitude, latitude) representing the travel path
 * @param selectedTransport The mode of transportation for the travel path (e.g., "Car")
 * @param tb A reference to the CustomThreeJSWrapper class,  a custom wrapper for Three.js functionality
 * @returns An array of Vector3 objects representing the 3D line data
 */
export function generate3DLineData(
  coordinates: Position[],
  selectedTransport: string,
  tb: CustomThreeJSWrapper,
): Vector3[] {
  let arc3DPoints: number[][] = [];

  // Handle car travel with additional points for a smoother path
  if (coordinates.length === 2 && selectedTransport === 'Car') {
    const origin = coordinates[0];
    const destination = coordinates[1];
    const pointDensityInMeters = 100; // Adjust this value for desired point density

    const numPoints = calculatePointsBetweenCoordinates(
      origin,
      destination,
      pointDensityInMeters,
    );

    const interpolationFact = numPoints - 1;

    // Linear interpolation for points between origin and destination
    for (let i = 0; i < numPoints; i++) {
      const t = i / interpolationFact;
      const x = origin[0] + t * (destination[0] - origin[0]);
      const y = origin[1] + t * (destination[1] - origin[1]);
      const z = 0;

      arc3DPoints.push([x, y, z]);
    }
  } else {
    // For other transport modes or more than two coordinates, use existing coordinates without additional points
    for (let index = 0; index < coordinates.length; index++) {
      const coord = coordinates[index];
      arc3DPoints.push([coord[0], coord[1], 0]);
    }
  }

  const arc3DWorldVec3Array: Vector3[] = [];

  // Convert 2D points to 3D world coordinates using projectToWorld function (assumed to be part of tb)
  for (let i = 0; i < arc3DPoints.length; i++) {
    const dest = projectToWorld(arc3DPoints[i]);
    arc3DWorldVec3Array.push(new Vector3(dest.x, dest.y, dest.z));
  }

  return arc3DWorldVec3Array;
}

/**
 * Generates a 3D line geometry representing a travel path on a map.
 *
 * This function takes an array of coordinates, the selected mode of transportation
 * (e.g., car, plane), a reference to the Three.js wrapper class, and an optional
 * flag to color the line. It then processes the coordinates to create a 3D curve
 * and generates a MeshLine geometry for rendering on the map.
 *
 * @param coordinates An array of `Position` objects representing the path waypoints
 * @param selectedTransport The type of transportation (car, plane, etc.) for styling purposes
 * @param tb A reference to the Three.js wrapper class
 * @param color A flag indicating whether to color the line with random colors (default: false)
 * @returns An object containing the generated materials, path curve, and path geometry
 */
export function generate3DLine(
  coordinates: Position[],
  selectedTransport: string,
  tb: CustomThreeJSWrapper,
  color = false,
): {
  material: MeshLineMaterial;
  pathCurve: CatmullRomCurve3;
  pathGeometry: MeshLineGeometry;
} {
  // Generate 3D line data based on coordinates, transport type, and Three.js wrapper
  const arc3DWorldVec3Array = generate3DLineData(
    coordinates,
    selectedTransport,
    tb,
  );

  // Create a Catmull-Rom curve from the 3D line data
  const pathCurve = new CatmullRomCurve3(arc3DWorldVec3Array);

  if (color) {
    // Iterate over curve points and create colored cubes for visualization (optional)
    pathCurve.points.forEach((point, index) => {
      const geometry = new BoxGeometry(0.05, 0.05, 0.05);
      const randomColor = Math.random() * 0xffffff;
      const material = new MeshBasicMaterial({ color: randomColor });
      const cube = new Mesh(geometry, material);
      cube.position.copy(point);
      tb.add(cube);
    });
  }

  // Create a MeshLineGeometry object for the path
  let pathGeometry = new MeshLineGeometry();

  // Set the initial draw range to avoid rendering the entire line at once
  pathGeometry.setDrawRange(0, 0);

  // Set the path curve points as the geometry points
  pathGeometry.setPoints(pathCurve.points);

  // Create a MeshLineMaterial object for the line rendering
  let material;

  // Define the line color using a Color object and then convert to hex format
  var colour = new Color('#FE7138');
  var hex = colour.getHex();

  // Get the canvas element for the map
  const canvas = document.getElementsByClassName('maplibregl-canvas')[0];

  // Configure the MeshLineMaterial properties
  material = new MeshLineMaterial({
    color: hex,
    lineWidth: 5,
    resolution: new Vector2(canvas.clientWidth, canvas.clientHeight),
  });

  material.transparent = true;

  // Return an object containing the generated materials, path curve, and path geometry
  return {
    material,
    pathCurve,
    pathGeometry,
  };
}

/**
 * Updates the camera bounding box based on a provided path curve and map configuration.
 * This function adjusts the camera zoom and model scale to ensure the entire path is visible
 * while maintaining an appropriate view.
 *
 * @param tb A reference to the Three.js wrapper class (potentially unused in this function)
 * @param pathCurve A path curve object that defines the travel path
 * @param map A reference to the Maplibre map instance
 * @param UIConfig An object containing UI configuration options (e.g., map pitch, UI scale)
 * @returns An object containing the following properties:
 *   - modelMaxScale: The maximum scale for the 3D model based on the camera zoom
 *   - cameraZoom: The camera zoom level that fits the path curve within the map bounds
 */
export function updateCameraBBox(pathCurve: BBox, map: Map) {
  let bounds;

  // Handle CatmullRomCurve3 path curves
  if (pathCurve instanceof CatmullRomCurve3) {
    const firstPointLngLat = unprojectFromWorld(pathCurve.points[0]);
    bounds = new LngLatBounds(firstPointLngLat, firstPointLngLat);
    for (let index = 1; index < pathCurve.points.length; index++) {
      const point = pathCurve.points[index];
      const lnglat = unprojectFromWorld(point);
      bounds.extend(lnglat);
    }
  } else {
    // Assume bounds are already defined for other path curve types
    bounds = pathCurve;
  }

  // Get camera configuration based on the path bounds
  const cameraConfigForBounds = map.cameraForBounds(bounds as LngLatBoundsLike);

  // Extract camera zoom from the configuration or use a default value
  if (cameraConfigForBounds) {
    map.easeTo({
      center: cameraConfigForBounds.center,
      bearing: cameraConfigForBounds.bearing,
      pitch: 0,
      zoom: (cameraConfigForBounds?.zoom as number) - 0.5,
      duration: 100,
      essential: true,
    });
  }
}

// 3DArc functions

/**
 * Generates 3D data representing an arc based on a provided 2D line string.
 * This function is typically used to create a curved path for travel animations on a map.
 *
 * @param lineData An array of Position objects representing the 2D line string
 * @param arcHeightMultiplier A multiplier to control the height of the arc relative to the line distance
 * @param segments The number of segments to use for approximating the curve
 * @param tb A reference to the CustomThreeJSWrapper class ( for 3D projection)
 * @returns An array of Vector3 objects representing the 3D points of the arc
 */
export function generate3DArcData(
  lineData: Position[],
  arcHeightMultiplier: number,
  segments: number,
  tb: CustomThreeJSWrapper,
) {
  let start = lineData[0] as Position;
  let end = lineData[1] as Position;

  var from = point(start);
  var to = point(end);

  const dist = distance(from, to);

  let line2DPoints: Position[] = [];
  let lineDistance = dist;

  const steps = segments;
  line2DPoints = generate2DLineData(start, end, steps);

  let arc3DPoints: number[][] = [];
  let maxZ = arcHeightMultiplier * lineDistance;
  const halfSize = line2DPoints.length / 2;

  // Generate first half of the arc i.e from zero to highest point in the middle
  for (let index = 0; index < line2DPoints.length / 2; index++) {
    const coord = line2DPoints[index];
    const easedValue = Easing.easeInOutQuad(index, 0, maxZ, halfSize);
    arc3DPoints.push([coord[0], coord[1], easedValue]);
  }

  //Generate the second half of the arc i.e from the highest point in the middle to zero
  let reverseIndex = 1;
  for (
    let index = Math.ceil(line2DPoints.length / 2);
    index < line2DPoints.length;
    index++
  ) {
    const coord = line2DPoints[index];

    const easedValue =
      arc3DPoints[Math.floor(line2DPoints.length / 2) - reverseIndex];
    if (easedValue) arc3DPoints.push([coord[0], coord[1], easedValue[2]]);

    reverseIndex += 1;
  }

  const arc3DWorldVec3Array: Vector3[] = [];

  for (let i = 0; i < arc3DPoints.length; i++) {
    const dest = projectToWorld(arc3DPoints[i]);
    arc3DWorldVec3Array.push(new Vector3(dest.x, dest.y, dest.z));
  }

  return arc3DWorldVec3Array;
}

/**
 * Generates a 3D arc based on a provided line of positions and an arc height multiplier.
 *
 * This function creates a Catmull-Rom curve in 3D space using the given line data and extrudes it
 * upwards by the specified arc height multiplier. The resulting geometry can be used to visualize a curved path
 * on a map.
 *
 * @param lineData An array of `Position` objects representing the line's coordinates
 * @param arcHeightMultiplier A number that scales the arc's height relative to the line's length
 * @param tb A reference to the CustomThreeJSWrapper instance for creating 3D objects
 * @param segments (optional) The number of segments to use for approximating the curve. Defaults to 1000.
 * @returns An object containing the generated path's material, curve, geometry, dynamic path array, and distances array.
 */
export function generate3DArc(
  lineData: Position[],
  arcHeightMultiplier: number,
  tb: CustomThreeJSWrapper,
  segments = 1000,
): {
  material: MeshLineMaterial;
  pathCurve: CatmullRomCurve3;
  pathGeometry: MeshLineGeometry;
  dynamicPath: number[];
  distances: number[];
} {
  // Generate 3D world vector array for the arc
  const arc3DWorldVec3Array = generate3DArcData(
    lineData as Position[],
    arcHeightMultiplier,
    segments,
    tb,
  );

  // Create a Catmull-Rom curve from the 3D world vector array
  const pathCurve = new CatmullRomCurve3(arc3DWorldVec3Array);

  // Generate a MeshLineGeometry object based on the curve
  let pathGeometry = new MeshLineGeometry();
  pathGeometry.setPoints(pathCurve.getSpacedPoints(arc3DWorldVec3Array.length));

  // Set the initial draw range to zero (no line drawn yet)
  pathGeometry.setDrawRange(0, 0);

  // Create a MeshLineMaterial object with desired color and line width
  var color = new Color('#FE7138');
  var hex = color.getHex();
  const material = new MeshLineMaterial({
    color: hex,
    lineWidth: 5,
    resolution: new Vector2(window.innerWidth, window.innerHeight),
  });
  material.transparent = true;

  // Return an object containing the generated path data
  return {
    material,
    pathCurve,
    pathGeometry,
    dynamicPath: [],
    distances: [],
  };
}

/**
 * A custom easing function created using CustomEase.create
 */
const easingFunction = CustomEase.create(
  'custom',
  'M0,0 C0,0 0.07,0.607 0.089,0.659 0.102,0.695 0.12,0.786 0.129,0.82 0.141,0.871 0.175,0.884 0.2,0.9 0.22,0.950 0.275,0.955 0.334,0.977 0.349,0.988 0.419,0.995 0.498,0.997 0.499,0.999 0.622,0.9995 0.665,0.9995 0.668,0.9997 0.725,0.9997 0.755,0.9999 0.808,0.99992 0.858,0.99995 0.908,0.99998 0.9980,0.99999 1,0.999990 1,1 ',
);

/**
 * Maps an input value from one range to another range, with optional
 * support for custom easing.
 *
 * @param input The input value to be mapped
 * @param inMin The minimum value in the input range
 * @param inMax The maximum value in the input range
 * @param outMin The minimum value in the output range
 * @param outMax The maximum value in the output range
 * @returns The mapped value within the output range
 */
function mapRange(
  input: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
): number {
  // Ensure input is within the specified range
  input = Math.min(Math.max(input, inMin), inMax);

  // Calculate the input range and output range
  const inputRange: number = inMax - inMin;
  const outputRange: number = outMax - outMin;

  // Normalize the input value to a 0-1 range
  const inputScale = (input - inMin) / inputRange;

  // Apply the custom easing function to the normalized input value
  const easedInputScale = easingFunction(inputScale);

  // Map the eased input value to the output range
  return outMax - easedInputScale * outputRange + outMin;
}

/**
 * Gets a scale value based on the current map zoom level,
 * using a custom mapping function with optional easing.
 *
 * @param zoom The current map zoom level
 * @returns The calculated scale value
 */
export function getScaleFromZoom(zoom: number): number {
  // const zoom = this.map.getZoom(); // Replace with your zoom retrieval method

  const inputValue = zoom; // Input value (current zoom)
  const inputMin = 1; // Minimum zoom level for mapping
  const inputMax = 18.1; // Maximum zoom level for mapping
  const outputMin = 0.0000125; // Minimum output scale value
  const outputMax = 4; // Maximum output scale value

  const convertedValue = mapRange(
    inputValue,
    inputMin,
    inputMax,
    outputMin,
    outputMax,
  );

  return convertedValue;
}

/**
 * Generate a straight line between given origin and destinate coordinates
 * and create an array of straight line coordinates
 * @param originCoords origin coordinates
 * @param destinationCoords destination coordinates
 * @returns array of coordinates
 */
export function getStraightLineBetweenTwoPoints(
  originCoords: Position,
  destinationCoords: Position,
) {
  let from = point(originCoords);
  let to = point(destinationCoords);

  let dist = distance(from, to);

  let start = originCoords;
  let end = destinationCoords;
  let numOfPoints =
    dist < 1
      ? Math.ceil(dist * 1000)
      : dist < 10
      ? Math.ceil(dist * 100)
      : dist < 100
      ? Math.ceil(dist * 10)
      : dist;

  let coordinates = [];

  coordinates.push(start);
  for (let i = numOfPoints - 1; i > 0; i--) {
    const lnglat = [
      (start[0] * i) / numOfPoints + (end[0] * (numOfPoints - i)) / numOfPoints,
      (start[1] * i) / numOfPoints + (end[1] * (numOfPoints - i)) / numOfPoints,
    ];
    coordinates.push(lnglat);
  }

  coordinates.push(end);

  return { coordinates, distance: dist };
}

export const zoomResolver = (camZoom: number, streeZoom: number) => {
  return camZoom > streeZoom ? streeZoom - 0.3 : camZoom;
};
