import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import { useStyles } from './styles';
import { pulsateAnimation } from '~/utility/utils';
import { continueBlinkSignal } from '~/components/signals/blinkSignals';
// Array of engagement texts to be displayed randomly
const engagementText = [
  "Trip Plans should be easy to share. Every time you use our app, you're helping us make it better.",
  "If you're a travel creator and there is something that could really enhance the app for you, reach out to us.",
  "We're just getting started and we'd love your input! If you have ideas to make the experience better for you, reach out.",
];

interface ISplashScreen {
  loading: boolean;
  closeSplashScreen: () => void;
}

// SplashScreen component
export const SplashScreen: React.FC<ISplashScreen> = ({
  loading,
  closeSplashScreen,
}) => {
  const classes = useStyles();

  const [isLottieLoaded, setIsLottieLoaded] = useState(false);
  const [loadingDone, setLoadingDone] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    // Only set loadingDone to true if loading is false and isLottieLoaded is true
    if (!loading && isLottieLoaded) {
      setLoadingDone(true);
      closeSplashScreen();
    } else {
      setLoadingDone(false);
    }
  }, [isLottieLoaded, loading]);

  return (
    <>
      <Box
        className={`${classes.splashScreenWrapper} ${
          loadingDone && classes.splashScreenWrapperBackgroundChange
        }`}
      >
        {/* Loading Screen */}
        {!loadingDone && (
          <Box className={classes.splashScreen}>
            <>
              <Player autoplay loop src="./lottie-loading-new.json">
                <Controls visible={false} />
              </Player>
            </>
          </Box>
        )}

        {/* Continue Screen */}

        {isMobile ? (
          <Box
            className={`${
              loadingDone
                ? classes.splashScreenContinue
                : classes.splashScreenContinueLoading
            }`}
          >
            <ContinueScreen
              closeSplashScreen={closeSplashScreen}
              setIsLottieLoaded={setIsLottieLoaded}
            />
          </Box>
        ) : (
          <Box
            className={`${
              loadingDone
                ? classes.splashScreenContinue
                : classes.splashScreenContinueLoading
            }`}
          >
            <ContinueScreen
              closeSplashScreen={closeSplashScreen}
              setIsLottieLoaded={setIsLottieLoaded}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export const ContinueScreen = ({
  closeSplashScreen,
  setIsLottieLoaded,
}: {
  closeSplashScreen: () => void;
  setIsLottieLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Box className={classes.splashScreenContinueWrapper}>
      <Box className={classes.splashScreenContinueContainer}>
        <Box className={classes.lottieGlobeContainer}>
          <Player
            autoplay
            loop
            src="./lottieGlobe.json"
            className={classes.lottieGlobe}
            onEvent={(event) => {
              if (event === 'load') {
                setIsLottieLoaded(true);
              }
            }}
          >
            <Controls visible={false} />
          </Player>
        </Box>
        <Box className={classes.headingWrapper}>
          <Typography
            component="h2"
            sx={{
              lineHeight: '30px',
              fontWeight: '900',
              fontSize: {
                xs: '1.4rem', // fontSize for extra-small screens
                sm: '1.4em', // fontSize for small screens
                md: '1.4rem', // fontSize for medium screens (default)
                lg: '1.4rem', // fontSize for large screens
                xl: '1.6rem', // fontSize for extra-large screens
              },
              color: 'text.primary',
              mb: 1,
            }}
          >
            Help us make VizualTravel {isMobile && <br />}{' '}
            <span>better for you.</span>
          </Typography>

          <Box
            style={{
              height: '3rem', // Fixed height to prevent layout shift
              minHeight: '3rem', // Minimum height to maintain space
            }}
          >
            <Typography
              component="p"
              sx={{
                color: '#252525',
                lineHeight: {
                  xs: '20px',
                  lg: '24px',
                },
                fontWeight: '400',
                fontSize: {
                  xs: '1rem', // fontSize for extra-small screens
                  sm: '1rem', // fontSize for small screens
                  md: '1rem', // fontSize for medium screens (default)
                  lg: '1rem', // fontSize for large screens
                  xl: '1.2rem', // fontSize for extra-large screens
                },
              }}
            >
              {
                engagementText[
                  Math.floor(Math.random() * engagementText.length)
                ]
              }
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
        }}
      >
        <Button
          variant="contained"
          className={classes.continueButton}
          onClick={() => {
            closeSplashScreen();
            continueBlinkSignal.value = false;
          }}
          sx={{
            ...(continueBlinkSignal.value && {
              '&:before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
                animation: `${pulsateAnimation} 2s infinite`,
                borderRadius: '8px',
                border: '5px solid rgba(255, 255, 255, 0.75)',
                top: 0,
                left: 0,
                background: 'rgba(198, 16, 0, 0)',
                boxSizing: 'border-box',
              },
            }),
          }}
        >
          <b className={classes.continueButtonText}>Continue</b>
        </Button>
      </Box>
    </Box>
  );
};

export const PageSplashscreen = () => {
  const classes = useStyles();

  return (
    <Box className={classes.splashScreenWrapper}>
      <Box className={classes.splashScreen}>
        <>
          <svg
            width="274"
            height="56"
            viewBox="0 0 274 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M66.6121 0.047182C63.2022 0.395981 59.7979 0.748966 56.3992 1.10614C53.214 1.44098 50.0037 1.62933 46.8519 2.16509C41.5864 3.06499 38.6691 8.86203 40.8581 13.755C40.9194 13.9882 41.069 14.1884 41.2752 14.3133C41.4814 14.4383 41.728 14.4782 41.9631 14.4247C44.9558 14.0563 47.9569 13.7215 50.958 13.3741L55.0682 12.8969C55.0668 13.0743 55.0472 13.251 55.0096 13.4243C54.1725 15.3539 53.3354 17.2918 52.448 19.1921C52.2221 19.5399 51.8815 19.7975 51.4854 19.9204C47.6346 21.3797 43.7769 22.8168 39.9122 24.2315C39.2802 24.4617 39.1211 24.7798 39.0751 25.4202C38.8951 29.1119 38.6272 32.8078 37.2711 36.3027C36.79 37.413 36.1529 38.4489 35.3792 39.3792C34.9104 39.9777 34.5002 40.0865 34.0816 39.7182V56.0001C34.9052 55.9158 35.6941 55.6249 36.3753 55.1544C37.0565 54.6839 37.6079 54.0491 37.9784 53.3088C42.3873 45.4483 46.7891 37.5849 51.184 29.7188C55.6905 21.649 60.19 13.5722 64.6825 5.48845C65.6703 3.71376 66.654 1.93488 67.7129 0.047182C67.2399 0.0429964 66.9135 0.00114053 66.6121 0.047182Z"
              fill="white"
            />
            <path
              d="M1.59607 0C4.99477 0.35438 8.38929 0.71155 11.7796 1.07151C14.9607 1.41054 18.1752 1.59889 21.3228 2.13465C26.5924 3.03037 29.5098 8.83159 27.3207 13.7204C27.2594 13.9535 27.1099 14.1537 26.9037 14.2787C26.6975 14.4037 26.4508 14.4436 26.2157 14.3901C23.2189 14.0259 20.2178 13.6911 17.2209 13.3437L13.119 12.8623C13.1227 13.0411 13.1437 13.2191 13.1818 13.3939C14.0189 15.3234 14.856 17.2614 15.7392 19.1658C15.969 19.5117 16.3101 19.7687 16.7061 19.8941C20.5512 21.3535 24.409 22.7891 28.2792 24.2011C28.539 24.2644 28.7669 24.4198 28.9208 24.6384C29.0748 24.8569 29.1442 25.1239 29.1164 25.3898C29.2963 29.0815 29.5642 32.7773 30.9204 36.2723C31.3959 37.3814 32.0289 38.4161 32.7997 39.3445C33.2643 39.9431 33.6787 40.0519 34.093 39.6836V55.9613C33.2705 55.8771 32.4826 55.5866 31.8021 55.1169C31.1217 54.6472 30.5708 54.0134 30.2004 53.2742C25.8028 45.4164 21.4051 37.5643 17.0074 29.7177C12.4982 21.6255 7.99863 13.5446 3.50889 5.47475C2.52109 3.70006 1.53747 1.92119 0.478516 0C0.951488 0 1.27796 0 1.59607 0Z"
              fill="white"
            />
            <path
              d="M83.5801 40.1843C82.3702 40.2303 81.1833 39.8446 80.2316 39.096C79.261 38.2246 78.5693 37.0859 78.2434 35.8229L72.1534 16.0167H77.4273L82.8685 34.337C82.9366 34.5286 83.0371 34.707 83.1657 34.8644C83.2841 34.9775 83.4415 35.0406 83.6052 35.0406C83.7689 35.0406 83.9263 34.9775 84.0447 34.8644C84.1734 34.7063 84.2663 34.522 84.3168 34.3244L89.7329 16.0041H95.0068L88.9251 35.8103C88.5982 37.069 87.9134 38.2059 86.9537 39.0835C85.9976 39.8432 84.8003 40.2339 83.5801 40.1843Z"
              fill="white"
            />
            <path
              d="M100.778 19.6702C100.229 19.6711 99.6907 19.5267 99.2164 19.2517C98.7419 18.9907 98.3514 18.6002 98.0905 18.1257C97.8155 17.6515 97.671 17.1128 97.672 16.5645C97.6714 16.158 97.751 15.7553 97.9062 15.3795C98.0614 15.0038 98.2892 14.6623 98.5764 14.3746C98.8637 14.0869 99.2049 13.8587 99.5804 13.703C99.956 13.5473 100.359 13.4672 100.765 13.4672C101.31 13.4614 101.846 13.6063 102.314 13.8857C102.787 14.1599 103.18 14.554 103.452 15.0284C103.727 15.4983 103.871 16.0328 103.871 16.5771C103.87 17.1251 103.725 17.6633 103.452 18.1383C103.186 18.6057 102.797 18.9909 102.326 19.2517C101.857 19.528 101.322 19.6726 100.778 19.6702ZM98.2663 22.2695H103.26V39.6773H98.283L98.2663 22.2695Z"
              fill="white"
            />
            <path
              d="M123.065 39.6795H111.956C110.5 39.6795 109.412 39.3195 108.683 38.6038C108.34 38.2867 108.064 37.9041 107.871 37.4785C107.679 37.0529 107.573 36.593 107.562 36.1259C107.525 35.3802 107.694 34.6387 108.051 33.9829C108.449 33.3118 109.013 32.7541 109.688 32.3631L117.913 26.88C117.955 26.8625 117.991 26.8329 118.016 26.7949C118.041 26.7569 118.055 26.7122 118.055 26.6665C118.055 26.5744 118.026 26.5242 117.967 26.5116C117.914 26.5061 117.861 26.5061 117.808 26.5116H108.139V22.2716H118.863C120.314 22.2716 121.409 22.6302 122.148 23.3473C122.496 23.66 122.774 24.042 122.965 24.4688C123.156 24.8956 123.256 25.3576 123.258 25.8252C123.293 26.5528 123.13 27.2764 122.785 27.918C122.382 28.5998 121.82 29.1742 121.148 29.5922L112.919 35.0754C112.875 35.1073 112.839 35.1496 112.815 35.1985C112.791 35.2473 112.779 35.3013 112.781 35.3558C112.781 35.406 112.798 35.4353 112.831 35.4437C112.884 35.4518 112.938 35.4518 112.99 35.4437H123.082L123.065 39.6795Z"
              fill="white"
            />
            <path
              d="M135.442 35.3182C136.219 35.3474 136.983 35.1047 137.601 34.6318C138.193 34.1547 138.638 33.5208 138.886 32.8027C139.172 32.0081 139.313 31.1691 139.305 30.3248V22.2718H144.298V30.9234C144.321 32.5305 143.979 34.122 143.298 35.5777C142.651 36.9506 141.618 38.1054 140.326 38.9011C138.831 39.7089 137.158 40.1318 135.458 40.1318C133.759 40.1318 132.086 39.7089 130.59 38.9011C129.293 38.1 128.26 36.9357 127.619 35.5526C126.939 34.1006 126.596 32.5139 126.614 30.9108V22.2718H131.608V30.3625C131.601 31.1943 131.743 32.0206 132.026 32.8027C132.274 33.5208 132.72 34.1547 133.311 34.6318C133.92 35.1013 134.673 35.344 135.442 35.3182Z"
              fill="white"
            />
            <path
              d="M156.009 40.1369C154.43 40.1643 152.877 39.7277 151.543 38.8813C150.255 38.0487 149.206 36.8946 148.5 35.5328C147.759 34.1124 147.379 32.5309 147.395 30.9286C147.381 29.3075 147.816 27.714 148.651 26.3245C149.485 24.9035 150.681 23.7289 152.117 22.9202C153.553 22.1114 155.178 21.6975 156.825 21.7203C158.52 21.6924 160.193 22.1095 161.676 22.93C163.07 23.7048 164.233 24.8339 165.05 26.2031C165.886 27.6237 166.313 29.2473 166.285 30.8951V39.6807H161.53V33.4023L163.041 32.9838C163.06 34.263 162.758 35.5266 162.162 36.6587C161.602 37.7194 160.762 38.6066 159.734 39.2245C158.6 39.8638 157.311 40.1795 156.009 40.1369ZM156.817 35.4574C157.627 35.474 158.424 35.2622 159.119 34.8463C159.783 34.442 160.331 33.8731 160.71 33.1948C161.089 32.5165 161.286 31.7517 161.283 30.9747C161.291 30.1923 161.095 29.4213 160.715 28.7374C160.335 28.0534 159.784 27.4801 159.115 27.0737C158.421 26.6566 157.623 26.4434 156.813 26.4584C156.014 26.4461 155.228 26.6593 154.544 27.0737C153.879 27.4812 153.33 28.0525 152.949 28.7329C152.568 29.4134 152.368 30.1802 152.368 30.96C152.368 31.7399 152.568 32.5067 152.949 33.1871C153.33 33.8676 153.879 34.4388 154.544 34.8463C155.229 35.2602 156.017 35.4721 156.817 35.4574Z"
              fill="white"
            />
            <path
              d="M171.163 14.2621H176.157V39.6854H171.163V14.2621Z"
              fill="white"
            />
            <path
              d="M196.577 16.1877V18.2805H179.387V16.1877H196.577ZM186.837 16.1877H189.089V39.6773H186.837V16.1877Z"
              fill="white"
            />
            <path
              d="M201.15 39.6826H198.974V29.1684C198.948 27.9498 199.202 26.7415 199.714 25.6358C200.176 24.6486 200.915 23.8168 201.841 23.2416C202.854 22.6466 204.015 22.3493 205.189 22.3836C205.709 22.3809 206.229 22.4328 206.738 22.5384C207.225 22.6461 207.698 22.809 208.148 23.0239V25.2381C207.74 24.9839 207.295 24.792 206.83 24.6689C206.358 24.5513 205.873 24.4922 205.386 24.4931C204.809 24.4673 204.234 24.5704 203.701 24.7949C203.169 25.0193 202.694 25.3595 202.31 25.7906C201.51 26.7696 201.103 28.0107 201.167 29.273L201.15 39.6826Z"
              fill="white"
            />
            <path
              d="M218.24 40.0282C216.684 40.0522 215.152 39.6327 213.825 38.8186C212.514 38.005 211.436 36.8673 210.694 35.5152C209.952 34.1632 209.571 32.6425 209.589 31.1004C209.574 29.5379 209.982 28.0005 210.769 26.6511C211.561 25.2981 212.691 24.175 214.05 23.3929C215.408 22.6108 216.947 22.1968 218.515 22.1917C220.082 22.1867 221.624 22.5907 222.988 23.364C224.351 24.1373 225.489 25.253 226.289 26.6008C227.095 27.9618 227.509 29.5189 227.486 31.1004V39.6808H225.394V33.1387L226.327 31.8119C226.345 33.2631 225.981 34.6936 225.272 35.9598C224.588 37.1805 223.6 38.204 222.405 38.9316C221.147 39.6794 219.704 40.0593 218.24 40.0282ZM218.521 37.9563C219.734 37.9714 220.926 37.6482 221.966 37.023C222.98 36.4207 223.822 35.5673 224.41 34.5451C224.997 33.504 225.306 32.3291 225.306 31.1338C225.306 29.9386 224.997 28.7636 224.41 27.7226C223.819 26.7022 222.978 25.8493 221.966 25.2447C220.925 24.6226 219.733 24.301 218.521 24.3155C217.314 24.2988 216.126 24.6207 215.093 25.2447C214.087 25.8523 213.252 26.7048 212.665 27.7226C212.078 28.7636 211.77 29.9386 211.77 31.1338C211.77 32.3291 212.078 33.504 212.665 34.5451C213.25 35.5647 214.085 36.4177 215.093 37.023C216.125 37.6501 217.313 37.9736 218.521 37.9563Z"
              fill="white"
            />
            <path
              d="M236.38 37.9531L230.65 22.6213H232.969L238.41 37.3922C238.474 37.5694 238.584 37.7266 238.728 37.8484C238.87 37.9622 239.046 38.0242 239.228 38.0242C239.41 38.0242 239.586 37.9622 239.728 37.8484C239.863 37.7222 239.966 37.5659 240.03 37.3922L245.471 22.6213H247.79L242.039 37.9531C241.835 38.5826 241.444 39.1348 240.917 39.5352C240.429 39.8836 239.843 40.0682 239.243 40.0626C238.644 40.0621 238.06 39.878 237.569 39.5352C237.014 39.1483 236.597 38.5939 236.38 37.9531Z"
              fill="white"
            />
            <path
              d="M258.499 40.028C256.919 40.0516 255.362 39.645 253.996 38.8519C252.68 38.0875 251.594 36.9829 250.852 35.6541C250.079 34.2623 249.685 32.692 249.709 31.1002C249.695 29.5408 250.063 28.0017 250.781 26.6174C251.452 25.3017 252.464 24.1901 253.711 23.3987C255.031 22.5883 256.557 22.1742 258.106 22.2058C259.619 22.1676 261.114 22.5439 262.43 23.294C263.636 24.0099 264.622 25.0438 265.28 26.2826C265.974 27.6164 266.324 29.1027 266.297 30.6063V31.908H251.292L251.853 30.397C251.717 31.7017 251.936 33.0187 252.485 34.2101C253.01 35.3567 253.859 36.3244 254.928 36.9938C255.997 37.6632 257.238 38.005 258.499 37.9771C259.624 37.9865 260.737 37.7389 261.751 37.253C262.786 36.7409 263.634 35.9166 264.175 34.8965L265.849 35.809C265.15 37.1394 264.082 38.2396 262.773 38.9775C261.462 39.6882 259.99 40.05 258.499 40.028ZM251.924 30.1375H264.125C264.125 29.0451 263.836 27.9721 263.288 27.0276C262.779 26.1652 262.043 25.4588 261.161 24.985C260.219 24.4996 259.17 24.2565 258.11 24.2777C257.028 24.2557 255.961 24.523 255.017 25.052C254.138 25.5525 253.405 26.2739 252.891 27.1448C252.355 28.0605 252.025 29.0816 251.924 30.1375Z"
              fill="white"
            />
            <path
              d="M271.611 14.7873H273.8V39.6832H271.619L271.611 14.7873Z"
              fill="white"
            />
          </svg>
        </>
      </Box>
    </Box>
  );
};
