import { Box, Link, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { OverviewData } from '.';
import { showStoriesSignal } from '../../StoriesOverlay';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { isEmpty } from 'lodash';
import {
  selectCurrentTravelDay,
  selectTravelDays,
} from '~/redux/selectors/tripInfoSelectors';
import './style.css';
import { showOverviewMenu } from '~/components/OverviewMenu';
import { currentVariantSignal } from '../BottomSheet';

const SLIDE_SIZE = 200;

// const generateDirectionLink = (i: OverviewData) => {
//   return `https://www.google.com/maps/search/${i.name.replaceAll(
//     ' ',
//     '+',
//   )}+${i.street?.replaceAll(' ', '+')}/`;
// };

const OverviewContainer = ({ data }: { data: OverviewData }) => {
  const [imageError, setImageError] = useState(false);

  const travelDays = useSelector(selectTravelDays);
  const pickedTravelDay = useSelector(selectCurrentTravelDay);

  console.log('123 overview data', data);

  const currentDay = moment(String(data.point?.dateTime || ''))
    .tz(data.point?.timezone || '')
    .format('DD-MM-YYYY');

  const nextPointDay = moment(String(data.nextPoint?.dateTime || ''))
    .tz(data.point?.timezone || '')
    .format('DD-MM-YYYY');

  let thumbContent = <></>;
  const ext =
    data.images && data.images[0]
      ? data.images[0].split('.').pop()?.toLocaleLowerCase()
      : undefined;
  if (ext === 'mp4') {
    thumbContent = (
      <video>
        <source src={data.images[0]} />
      </video>
    );
  } else {
    thumbContent = (
      <img
        width={SLIDE_SIZE}
        height={SLIDE_SIZE}
        src={data.images[0]}
        alt={String('img')}
        loading="lazy"
        onError={() => setImageError(true)}
      />
    );
  }

  const bottomSectionStyles =
    !showOverviewMenu.peek() && currentVariantSignal.peek() === 2
      ? {
          height: `${window.innerHeight / 2}px`,
          overflowY: 'auto',
          paddingBottom: '120px',
          width: '100%',
        }
      : { width: '100%' };

  return (
    <div
      style={{
        scrollbarWidth: 'none',
        paddingBottom: '30px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        // height: window.innerHeight * 0.8 - 80,
        height: '100%',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.15 }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 0,
          marginBottom: 10,
          maxWidth: '90%',
          justifyContent: 'center',
        }}
      >
        <img
          src={data.icon}
          height={48}
          style={{ marginRight: 10 }}
          loading="lazy"
          alt=""
        />
        <Typography
          fontSize={24}
          fontFamily={'Poppins'}
          fontWeight={'700'}
          style={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {data.name}
        </Typography>
      </motion.div>
      {/* @ts-ignore */}
      <div style={bottomSectionStyles}>
        {data.description && (
          <div>
            <Typography
              fontSize={16}
              fontFamily={'Poppins'}
              fontWeight={'400'}
              lineHeight={'18.05px'}
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                color: '#BDBDBD',
                padding: '0 10px',
                textAlign: 'center',
                marginBottom: '12px',
              }}
            >
              {data.description}
            </Typography>
          </div>
        )}
        {(!!data.review || !!data.cost) && (
          <div className="cost-review-section">
            {!!data.review && (
              <div className="custom-badge text-black content-fit">
                {data.review}
                <span>⭐</span>
              </div>
            )}
            {!!data.cost && (
              <div className="custom-badge text-black content-fit">
                ~US${data.cost}
              </div>
            )}
          </div>
        )}

        <div
          style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginBottom: 20,
            // marginLeft: 10,
            // marginRight: 10,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.25 }}
          >
            <div
              style={{
                width: SLIDE_SIZE,
                height: SLIDE_SIZE,
                borderRadius: 32,
                overflow: 'hidden',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'lightgray',
                boxShadow:
                  '0px 2px 6px 0px #B8C5D033, 0px 6px 22px 0px #B8C5D04D, 0px 9px 30px 0px #C6D8E933,0px 1.5px 1.5px 0px #FFFFFF40 inset',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (data.images.length > 0)
                  showStoriesSignal.value = data.images;
              }}
            >
              {data.images.length > 0 && !imageError ? (
                <>{thumbContent}</>
              ) : (
                <img
                  src={data.icon}
                  height={SLIDE_SIZE * 0.5}
                  loading="lazy"
                  alt="icon"
                />
              )}
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.35 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: 4,
              paddingBottom: 24,
            }}
          >
            {!pickedTravelDay && currentDay && !isEmpty(travelDays) && (
              <div
                className="custom-badge mb-3"
                // style={{ flexDirection: 'column' }}
                style={{ maxWidth: '100px', width: '100%', flexWrap: 'wrap' }}
              >
                <span>
                  Day {travelDays[currentDay]}
                  {data.nextPoint && currentDay !== nextPointDay && (
                    <span> - </span>
                  )}
                </span>
                {data.nextPoint && currentDay !== nextPointDay && (
                  <>Day {travelDays[nextPointDay]}</>
                )}
              </div>
            )}
            <div
              className="custom-badge mb-3"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {data.nextPoint && !data.prevPoint && (
                <span>
                  {moment(String(data.departureDateTime || ''))
                    .tz(data.departureTimeZone || '')
                    .format('hh:mm A')}
                </span>
              )}
              {data.nextPoint && data.prevPoint && (
                <div className="dayTime-item">
                  <span>
                    {moment(String(data.prevArrivalDateTime || ''))
                      .tz(data.prevArrivalTimeZone || '')
                      .format('hh:mm A')}{' '}
                    -{' '}
                  </span>
                  <span>
                    {moment(String(data.departureDateTime || ''))
                      .tz(data.departureTimeZone || '')
                      .format('hh:mm A')}
                  </span>
                </div>
              )}

              {!data.nextPoint && data.prevPoint && (
                <span>
                  {moment(String(data.arrivalDateTime || ''))
                    .tz(data.arrivalTimeZone || '')
                    .format('hh:mm A')}{' '}
                </span>
              )}
            </div>

            {/* @TODO WE don't use duration anymore. maybe remove code below later */}
            {/* <Typography
            fontSize={22}
            fontWeight={600}
            fontFamily={'Poppins'}
            // pt={'16px'}
            textAlign={'center'}
          >
            {data.staypointDuration}
          </Typography> */}
            {/* <Typography
            fontSize={22}
            fontWeight={600}
            fontFamily={'Poppins'}
            textAlign={'center'}
          >
            {Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'USD',
            }).format(+(data.price || 0))}
          </Typography> */}
          </motion.div>
        </div>
        {data.longDescription && (
          <motion.div className="long-description">
            {data.longDescription}
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.35 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '95%',
            paddingTop: 24,
            paddingBottom: 24,
          }}
        >
          <a
            className="custom-badge"
            target="_blank"
            href={`https://google.com/maps/search/${data.name
              .split(' ')
              .join('+')}`}
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
          >
            Search on Google
          </a>
        </motion.div>
      </div>
      {/* <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.35 }}
      >
        <Typography
          fontSize={14}
          fontWeight={500}
          fontFamily={'Poppins'}
          px={6}
          pt={'16px'}
          textAlign={'center'}
        >
          {data.description}
        </Typography>
      </motion.div> */}
      {/* <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.45 }}
        style={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '90%',
        }}
      >
        <LinkButton
          icon={'./icons/directionsIcon.png'}
          text={'Direction'}
          href={generateDirectionLink(data)}
        />
        <LinkButton
          icon={'./icons/websiteIcon.png'}
          text={'Website'}
          // href={generateDirectionLink(i)}
        />
        <LinkButton
          icon={'./icons/shareIcon.png'}
          text={'Share'}
          // href={generateDirectionLink(i)}
        />
        <LinkButton
          icon={'./icons/saveImage.png'}
          text={'Save'}
          // href={generateDirectionLink(i)}
        />
      </motion.div> */}
    </div>
  );
};

const LinkButton = ({
  icon,
  text,
  href,
}: {
  icon: string;
  text: string;
  href?: string;
}) => {
  return (
    <Link href={href} underline="none" target="_blank" rel="noreferrer">
      <Box
        bgcolor="#D5E2FF"
        borderRadius={'18px'}
        border={'1px solid #C0D5FF'}
        display={'flex'}
        flexDirection={'column'}
        color={'#C0D5FF'}
        alignItems={'center'}
        justifyContent={'center'}
        height={64}
        width={64}
      >
        <img
          width={24}
          height={24}
          src={icon}
          alt={'directionsIcon'}
          loading="lazy"
        />
        <Typography
          fontSize={11}
          fontWeight={500}
          color={'#3559E9'}
          marginTop={'10px'}
          fontFamily={'Poppins'}
          textTransform={'capitalize'}
          textAlign={'center'}
        >
          {text}
        </Typography>
      </Box>
    </Link>
  );
};

export default OverviewContainer;
