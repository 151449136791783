export const validImageTypes = [
  'image/jpeg',
  'image/png',
  'image/PNG',
  'image/JPEG',
  'image/jpg',
  'video/mp4',
  'video/webm',
  'video/mpeg',
  'video/x-msvideo',
  'video/quicktime',
  'video/3gpp',
  'video/3gpp2',
  'video/ogg',
  'video/x-flv',
  'video/x-matroska',
]; // List of valid image MIME types
