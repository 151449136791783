import { uniqBy } from 'lodash';
import moment from 'moment-timezone';
import React, { memo, useState } from 'react';
import { TravelFormHistoryData } from '~/utility/models';

const aspectRatio = 269 / 214;

const HistoryItem = memo(
  ({ item, onClick }: { item: TravelFormHistoryData; onClick: () => void }) => {
    const [errors, setErrors] = useState<number[]>([]);
    const offsets = [
      { left: '0%', top: '0%' },
      { left: '55%', top: '0%' },
      { left: '0%', top: '45%' },
      { left: '55%', top: '45%' },
      { left: '35%', top: '35%' },
    ];

    const selectedProfilePicture = '/icons/croppedLogoVizualTravel.svg';

    const days = uniqBy(
      item.travelPoints.map((i) =>
        moment(String(i.departure.dateTime)).tz(i.departure.timezone),
      ),
      (i) => i.format('DD-MM-YYYY'),
    );

    const images = item.travelPoints
      .flatMap((i) => [
        ...(i?.arrival?.images || []),
        ...(i?.departure?.images || [])
      ])
      .filter(Boolean)
      .slice(0, 5) as string[];

    return (
      <div onClick={onClick}>
        <div
          style={{
            position: 'relative',
            aspectRatio: aspectRatio,
            height: '20vh',
            // overflow: 'hidden',
            borderRadius: 16,
            cursor: 'pointer',
          }}
        >
          {images.length <= 1 ? (
            <img
              src={images[0] || selectedProfilePicture}
              loading="lazy"
              style={{
                aspectRatio: aspectRatio,
                height: '20vh',
                objectFit: 'cover',
                borderRadius: 24,
              }}
              alt={`img`}
            />
          ) : (
            images.map((src, idx) => {
              return (
                <img
                  key={idx}
                  src={errors.includes(idx) ? selectedProfilePicture : src}
                  width={80}
                  height={80}
                  loading="lazy"
                  style={{
                    objectFit: 'cover',
                    backgroundColor: 'lightgray',
                    borderRadius: 24,
                    position: 'absolute',
                    left: offsets[idx].left,
                    top: offsets[idx].top,
                    transform: `rotate(${(idx % 2 === 0 ? -1 : 1) * Math.random() * 15
                      }deg) scale(${(9.5 + Math.random() * 3 - 1) / 10})`,
                  }}
                  onError={() => {
                    setErrors((v) => [...v, idx]);
                  }}
                  alt={`img ${idx}`}
                />
              );
            })
          )}
        </div>
        <div className="home-history-item-title">
          {`${days.length} Day${days.length > 1 ? 's' : ''} in ${item.travelPoints[5]?.arrival?.location?.city ||
            item.travelPoints[0]?.arrival?.location?.city ||
            item.travelPoints[0]?.arrival?.location?.country
            }`}
        </div>
        <div className="home-history-item-description">
          {item.travelPoints[5]?.arrival?.location?.city ||
            item.travelPoints[0]?.arrival?.location?.city ||
            item.travelPoints[0]?.arrival?.location?.country}
          ,{' '}
          {item.travelPoints[5]?.arrival?.location?.code ||
            item.travelPoints[0]?.arrival?.location?.code}
        </div>
        <div className="home-history-item-description">
          {`${days.length} Day${days.length > 1 ? 's' : ''} · ${days[0].format(
            'D MMM',
          )} - ${days[days.length - 1].format('D MMM, YYYY')}`}
        </div>
      </div>
    );
  },
  (prev, next) => prev.item === next.item,
);

export default HistoryItem;
