import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import Navbar from '~/components/NavbarNew';
import { useIsMobile } from '~/components/ViewTravel/counter/hooks/useMobile';
import OverviewContainer from '~/components/ViewTravel/MobileFooter/OverviewDataComponent/OverviewContainer';
import {
  selectCurrentOverviewData,
  selectIsP2POverviewVisible,
} from '~/redux/selectors/tripInfoSelectors';
import { ItineraryOverview } from '.';
import ActionsCreator from '~/redux/actions';

export const ItineraryWrapper = () => {
  const isMobile = useIsMobile();
  const isItinearyVisible = useSelector(selectIsP2POverviewVisible);
  const currentOverviewData = useSelector(selectCurrentOverviewData);

  const initialAnimation = isMobile
    ? { y: -window.innerHeight }
    : { y: -window.innerHeight, x: '-50%' };
  const animation = isMobile ? { y: 0 } : { y: 0, x: '-50%' };
  const exit = isMobile
    ? { y: -window.innerHeight }
    : { y: -window.innerHeight, x: '-50%' };

  return (
    <div
      className="overview-modal-menu"
      // style={menuStyles}
    >
      <AnimatePresence>
        {isItinearyVisible && (
          <motion.div
            transition={{
              duration: 0.25,
              bounce: false,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="overview-menu-backdrop"
            style={{ cursor: 'pointer', zIndex: 9999 }}
            onClick={() => {
              if (!isMobile) {
                // showOverviewMenu.value = false;
                ActionsCreator.setIsP2POverviewVisible(false);
              }
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isItinearyVisible && (
          <motion.div
            transition={{
              ease: 'easeInOut',
              duration: 0.4,
              type: 'spring',
              bounce: false,
            }}
            initial={initialAnimation}
            animate={animation}
            exit={exit}
            className={`overview-menu-container ${isMobile ? '' : 'desktop'}`}
          >
            <Navbar
              onBackButtonClick={() =>
                ActionsCreator.setIsP2POverviewVisible(false)
              }
              style={{ margin: '0 auto' }}
              shoudShowMap
            />
            {currentOverviewData && (
              <OverviewContainer data={currentOverviewData} />
            )}
            <ItineraryOverview />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
